import {Component, Inject} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {BookingService} from '../../services/booking.service';
import {environment} from '../../../environments/environment';
import {PaymentService} from "../payments.service";
import {BehaviorSubject} from "rxjs";
import {GoogleAnalyticsService} from "ngx-google-analytics";

@Component({
  selector: 'app-refund-deposit-on-cancel-dialog',
  templateUrl: './refund-deposit-on-cancel-dialog.component.html',
  styleUrls: ['./refund-deposit-on-cancel-dialog.component.scss']
})
export class RefundDepositOnCancelDialogComponent {
  depositOnFile: boolean;
  depositId: string | null;
  clinicName: string;
  isGroomRoom = environment.GROOM;
  isVets4Pets = environment.VETS_4_PETS;
  error$ = new BehaviorSubject<string>(null);
  loading$ = new BehaviorSubject<boolean>(false);
  complete = false;


  constructor(
    public dialogRef: MatDialogRef<RefundDepositOnCancelDialogComponent>,
    public gaService: GoogleAnalyticsService,
    @Inject(MAT_DIALOG_DATA) data, private paymentService: PaymentService) {
    this.depositOnFile = data.depositOnFile;
    this.depositId = data.depositId;
    this.clinicName = data.clinicName || "VetBooker";
  }

  async refund() {
    this.loading$.next(true);
    this.error$.next(null);

    const refunded = await this.paymentService.refundDeposit();

    this.loading$.next(false);

    if (refunded) {
      this.gaService.event(
        'Refund deposit after cancellation',
        'Deposits',
        this.clinicName
      );

      this.complete = true;
      setTimeout(() => {
        this.dialogRef.close();
      }, 3000);
    }

    if (!refunded) {
      this.error$.next('There was an error processing your refund.')
    }
  }

  close(): void {
    this.dialogRef.close();
  }
}
