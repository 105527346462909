import {Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {GoogleMap, MapMarker} from '@angular/google-maps';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {Observable, of, Subscription} from 'rxjs';
import {catchError, debounceTime, map, startWith, switchMap} from 'rxjs/operators';
import {MapSearchService} from '../map-search.service';
import {environment} from '../../../environments/environment';
import {AppStoreDialogComponent} from '../../components/shared/app-store-dialog/app-store-dialog.component';
import {CookieService} from 'ngx-cookie-service';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {APP_POPUP} from '../../../constants';
import {DeviceDetectorService} from 'ngx-device-detector';
import {MarkerItem} from '@appyvet/vetbooker-definitions/dist/map';
import {ClinicDetails} from '@appyvet/vetbooker-definitions/dist/clinic_details';
import {Locality} from '@appyvet/vetbooker-definitions/dist/v4p';

@Component({
  selector: 'app-map-search',
  templateUrl: './map-search.component.html',
  styleUrls: ['./map-search.component.scss']
})
export class MapSearchComponent implements OnInit, OnDestroy {

  @ViewChild(GoogleMap, {static: false}) map: GoogleMap;

  mapHeight: string;
  mapHeightPixels: number;

  center: google.maps.LatLngLiteral;
  options: google.maps.MapOptions = {
    disableDoubleClickZoom: true,
    maxZoom: 15,
    minZoom: 6,
    mapTypeControl: false,
  };
  markers: MarkerItem[] = [];
  selectedClinic: ClinicDetails;
  mapSearch = new UntypedFormGroup({
    search: new UntypedFormControl('')
  });
  zoom = 10;
  mapWidth: string;
  mapsAutocomplete$: Observable<Locality[]> = null;

  locationIcon = {
    url: '../assets/svg/map_icon.svg',
    scaledSize: new google.maps.Size(30, 30),
    optimized: false,
  };
  selectedIcon = {
    url: '../assets/svg/map_selected.svg',
    scaledSize: new google.maps.Size(30, 30),
    optimized: false,
  };
  locationDot = {
    path: google.maps.SymbolPath.CIRCLE,
    scale: 2,
    strokeColor: 'deeppink',
    fillColor: 'deeppink',
    fillOpacity: 1,
  };
  isGroomRoom = environment.GROOM;
  private clearedPopup: string;
  private dialogSub: Subscription;
  @Input() fullWidth: boolean;
  @Output() clinicSelected = new EventEmitter<ClinicDetails>();
  @Input() currentClinicCode: string;

  constructor(private mapService: MapSearchService, private cookieService: CookieService, private dialog: MatDialog,
              private deviceService: DeviceDetectorService) {
  }

  ngOnDestroy(): void {
    this.dialogSub?.unsubscribe();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    let heightOffset = 0;
    if (window.innerHeight > 1200) {
      heightOffset = this.isGroomRoom ? 455 : 350;
    } else if (window.innerHeight > 400) {
      heightOffset = this.isGroomRoom ? 455 : 300;
    }
    this.mapHeightPixels = window.innerHeight - heightOffset;
    this.mapHeight = this.mapHeightPixels + 'px';
    if (window.innerWidth < 600 || this.fullWidth) {
      this.mapWidth = '100%';
    } else {
      this.mapWidth = '70%';
    }
  }

  ngOnInit() {
    this.mapService.getClinics(this.currentClinicCode);
    this.onResize();
    navigator.geolocation.getCurrentPosition(position => {
      this.center = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      };
      this.zoom = 12;
    }, error => {
      this.center = {
        lat: 53.3555,
        lng: -1.8743,
      };
      this.zoom = 7;
    });
    this.mapService.clinics$.subscribe(clinics => {
      clinics.forEach(clinic => {
        this.addMarker(clinic);
      });
    });
    this.mapsAutocomplete$ = this.mapSearch.get('search').valueChanges.pipe(
      startWith(''),
      // delay emits
      debounceTime(300),
      // use switch map so as to cancel previous subscribed events, before creating new once
      switchMap(value => {
        if (value !== '') {
          // lookup from woosmap
          return this.lookup(value);
        } else {
          // if no value is present, return null
          return of(null);
        }
      })
    );
    if (!this.isGroomRoom && !this.deviceService.isDesktop()) {
      this.clearedPopup = this.cookieService.get(APP_POPUP);
      if (!this.clearedPopup || this.clearedPopup !== 'true') {
        const dialogRef = this.dialog.open(AppStoreDialogComponent);
        this.dialogSub = dialogRef.afterClosed().subscribe((() => {
          this.cookieService.set(APP_POPUP, 'true');
        }));
      }
    }
  }

  addMarker(clinic: ClinicDetails) {
    this.markers.push({
      position: {
        lat: clinic.latitude,
        lng: clinic.longitude,
      },
      label: {
        color: 'blue',
        // text: clinic.practiceName,
      },
      title: clinic.practiceName,
      clinic,
      isSelected: false,
      options: {
        icon: this.locationIcon
      },
    });
  }

  openInfo(marker: MapMarker, markerItem: MarkerItem) {
    this.markers.forEach((markerToCheck, index) => {
      const newMarker = Object.assign({}, markerToCheck);
      if (markerToCheck.isSelected) {
        newMarker.options.icon = this.map?.getZoom() >= 11 ? this.locationIcon : this.locationDot;
        newMarker.isSelected = false;
        this.markers[index] = newMarker;
      }
      if (markerToCheck.clinic.clinicCode === markerItem.clinic.clinicCode) {
        newMarker.options.icon = this.selectedIcon;
        newMarker.isSelected = true;
        this.markers[index] = newMarker;
      }
    });
    this.selectedClinic = markerItem.clinic;
  }

  searchMap() {
    if (this.mapService.localities && this.mapService.localities.localities?.length > 0) {
      this.zoom = 12;
      this.center = this.mapService.localities.localities[0].location;
      this.mapSearch.get('search').setValue(this.mapService.localities.localities[0].name);
    }
  }

  gotoCurrentLocation() {
    navigator.geolocation.getCurrentPosition(position => {
      this.zoom = 12;
      this.center = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      };
    });
  }

  getDirections() {
    window.open(
      'https://www.google.com/maps?daddr=' + this.selectedClinic.latitude + ',' + this.selectedClinic.longitude,
      '_blank');
  }

  gotoWeb() {
    let url = '';
    if (this.selectedClinic.website.indexOf('http') === -1) {
      url = 'https://' + this.selectedClinic.website;
    } else {
      url = this.selectedClinic.website;
    }
    window.open(url, '_blank');
  }

  telClinic() {
    const url = 'tel:' + this.selectedClinic.phoneNumber;
    window.open(url);
  }

  lookup(value: string): Observable<Locality[]> {
    return this.mapService.placesSearch(value.toLowerCase()).pipe(
      // map the item property of the github results as our return object
      map(results => results.localities),
      // catch errors
      catchError(_ => {
        return of(null);
      })
    );
  }

  autoCompleteSelected(locality: Locality) {
    this.zoom = 12;
    this.center = locality.location;
  }

  zoomChanged() {
    const newMarkers = [];
    this.markers.forEach(marker => {
      const newMarker = Object.assign({}, marker);
      newMarker.options.icon = this.map?.getZoom() >= 11 ? this.locationIcon : this.locationDot;
      newMarkers.push(newMarker);
    });
    this.markers = newMarkers;
  }

  selectClinic(selectedClinic: ClinicDetails) {
    this.clinicSelected.emit(selectedClinic);
  }
}
