import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject, throwError} from 'rxjs';
import * as Parse from 'parse';
import {AuthService, NewUserResponse} from '../../auth/auth.service';
import {ParseErrorHandler} from '../../parse-error-handler';
import {
  ADDITIONAL_MATCH,
  COGNITO_VERIFICATION,
  EMAIL_VERIFICATION,
  MATCHING,
  SMS_VERIFICATION
} from '../../../constants';
import {Auth} from '@aws-amplify/auth';
import {Router} from '@angular/router';
import {environment} from '../../../environments/environment';
import {NoMatchDialogComponent} from '../no-match-dialog/no-match-dialog.component';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {CreatedUserResponse, PassedUser, VerifyEmailParams} from '@appyvet/vetbooker-definitions/dist/register_user';
import {EmailRegistrationLinkParams, PasswordResetParams} from '@appyvet/vetbooker-definitions/dist/login';
import {VetBookerError} from '../../interfaces/vberror';
import {GoogleAnalyticsService} from "ngx-google-analytics";

export interface ClientDetailsForMatching {
  email: string;
  mobile: string;
  password: string;
  postcode: string;
}

@Injectable({
  providedIn: 'root'
})

export class SignupService {

  currentPage$ = new BehaviorSubject<string>(MATCHING);
  accountValidationMessages = {
    email: [
      {type: 'required', message: 'Email is required'},
      {type: 'email', message: 'Enter a valid email'}
    ],
    mobile: [
      {type: 'required', message: 'Mobile is required'},
      {type: 'pattern', message: 'Enter a valid mobile number'}
    ],
    postcode: [
      {type: 'required', message: 'Postcode is required'},
      {type: 'pattern', message: `Oops, looks like we didn't recognize that as a postcode`},
      {type: 'maxlength', message: 'Postcode must be less than 8 characters'},
      {type: 'minlength', message: 'Postcode must be at least 6 characters'},
    ],
    password: [
      {type: 'required', message: 'Password is required'},
      {
        type: 'minlength',
        message: 'Must be at least 10 characters long'
      },
      {type: 'mismatch', message: 'Passwords must match'},
      {
        type: 'pattern',
        message: 'Min 10 characters with at least 1 capital letter and 1 number'
      },
    ],
    confirmPassword: [
      {type: 'required', message: 'Confirmed Password is required'},
      {
        type: 'minlength',
        message: 'Password must be at least 10 characters long'
      },
    ],
    verificationCode: [
      {type: 'required', message: 'Must enter a verification code'},
      {type: 'pattern', message: 'Only numbers allowed'},
      {type: 'minlength', message: 'Code must be at least 6 characters long'},
      {type: 'maxlength', message: 'Code must no more than 6 characters long'},
    ],
    smsVerificationCode: [
      {type: 'required', message: 'Must enter a verification code'},
      {type: 'pattern', message: 'Only numbers allowed'},
      {type: 'minlength', message: 'Code must be at least 4 characters long'},
      {type: 'maxlength', message: 'Code must no more than 4 characters long'},
    ],
  };
  foundUser$ = new BehaviorSubject<CreatedUserResponse>(null);
  loading$ = new BehaviorSubject<boolean>(false);
  goFullWidth$ = new BehaviorSubject<boolean>(false);
  error$ = new BehaviorSubject<VetBookerError>(null);
  matchUserError$ = new BehaviorSubject<Error>(null);
  email: string;
  mobile: string;
  unmatchedClient: PassedUser;
  createdUserResponse$ = new Subject<CreatedUserResponse>();
  private passedUser: PassedUser;
  private clinicCode: string;
  private matchingClient: ClientDetailsForMatching;
  private rxId: string;

  constructor(private authService: AuthService, private gaService: GoogleAnalyticsService, private router: Router,
              private dialog: MatDialog) {
    this.authService.isLoggedIn$.subscribe(isLoggedIn => {
      if (!isLoggedIn) {
        this.resetLoginPage();
      }
    });
  }

  goToVerification(smsVerification: boolean) {
    this.goFullWidth$.next(true);
    this.currentPage$.next(smsVerification ? SMS_VERIFICATION : EMAIL_VERIFICATION);
  }

  async goToCognitoVerification() {
    const email = await this.authService.getEmail();
    if (email) {
      this.goFullWidth$.next(true);
      this.currentPage$.next(COGNITO_VERIFICATION);
    }
  }

  async goToAdditionalMatch() {
    const email = await this.authService.getEmail();
    if (email) {
      this.goFullWidth$.next(true);
      this.currentPage$.next(ADDITIONAL_MATCH);
    }
  }

  resetLoginPage() {
    this.goFullWidth$.next(false);
    this.foundUser$.next(null);
    this.email = null;
    this.mobile = null;
    this.currentPage$.next(MATCHING);
  }

  configureVerification(parsedResult: CreatedUserResponse) {
    this.email = parsedResult.email;
    this.mobile = parsedResult.mobile;
    this.foundUser$.next(parsedResult);
  }

  async socialSignIn(provider: any): Promise<any> {
    this.rxId = this.authService.rxId;
    this.clinicCode = this.authService.getClinicCode();
    // Sign out first so can ensure can select social account if refresh or prev incorrect one used
    await Auth.signOut();
    return Auth.federatedSignIn({
      provider,
      customState: `{"clinicCode":"${this.clinicCode}","rxId":"${this.rxId}","login":true}`
    });
  }

  async matchAndRegisterUser(cognitoRegistration: boolean) {
    this.loading$.next(true);
    Parse.Cloud.run('matchAndRegisterUser', {
      passedUser: this.passedUser,
      cognitoRegistration,
      device: 'web'
    }).then(result => {
      this.createdUserResponse$.next(result);
      this.loading$.next(false);
      if (!result.deactivated && result.code !== 204) {
        if (cognitoRegistration) {
          this.loading$.next(false);
          this.authService.setMobile(this.passedUser.mobile);
          if (result.smsVerification) {
            this.resendSms();
            this.goToVerification(true);
          } else {
            this.authService.linkCognitoAccount(result.sessionToken, result.vbClinicCode);
          }
        } else {
          result.email = this.passedUser.username.toLowerCase();
          result.mobile = this.passedUser.mobile;
          this.configureVerification(result);
          this.goToVerification(result.smsVerification);
          this.loading$.next(false);
        }
        return true;
      } else {
        this.loading$.next(false);
        this.unmatchedClient = this.passedUser;
        this.showNoMatchDialog(this.passedUser.postcode, this.authService.clinicName);
        this.loading$.next(false);
        if (cognitoRegistration) {
          this.goToAdditionalMatch();
        }
        return false;
      }
    }, e => {
      this.loading$.next(false);
      this.matchUserError$.next(e);
    });
  }

  async cognitoMatchUser(mobile: string, postcode: string) {
    let username = this.authService.username;
    if (!this.authService.username) {
      const user = await Auth.currentUserInfo();
      username = user.attributes.email;
      this.authService.username = username;
    }
    this.passedUser = {
      clinicCode: this.authService.clinicCode,
      username,
      mobile,
      postcode,
      password: this.authService.password,
    };
    try {
      await this.matchAndRegisterUser(true);
    } catch (e) {
      throw e;
    }
  }

  async startRegistration(matchingClient: ClientDetailsForMatching) {
    this.matchingClient = matchingClient;
    this.passedUser = {
      clinicCode: this.authService.clinicCode,
      username: matchingClient.email,
      mobile: matchingClient.mobile,
      postcode: matchingClient.postcode,
      password: matchingClient.password,
    };
    this.authService.createCognitoAccount(matchingClient.email, matchingClient.password).then(result => {
      if (result.accountRequired) {
        if (result.accountCreated) {
          this.goToCognitoVerification();
        } else {
          throwError(new Error('Unable to complete the registration, please contact support'));
        }
      } else {
        this.matchAndRegisterUser(false);
      }
    }, e => {
      this.matchUserError$.next(e);
    });
  }


  registerNewUser(): Observable<NewUserResponse> {
    if (this.foundUser$) {
      const registerUserRequest$ = new BehaviorSubject<NewUserResponse>(null);
      Parse.Cloud.run('registerUser', {
        passedUser: this.foundUser$, device: 'web'
      }).then(result => {
        const parsedResult: NewUserResponse = result as NewUserResponse;
        registerUserRequest$.next(parsedResult);
      }, ((error) => {
        registerUserRequest$.error(error);
      }));
      return registerUserRequest$;
    }
  }

  async resendSms() {
    if (environment.VETS_4_PETS) {
      try {
        let email = this.email;
        const token = await this.authService.getCognitoToken();
        if (!email) {
          const user = await Auth.currentUserInfo();
          email = user.attributes.email;
        }
        Parse.Cloud.run('resendV4PSMSVerification', {
          username: email,
          token
        }, {});
      } catch (e) {
        console.log(e);
      }
    } else {
      Parse.Cloud.run('resendSMSVerification', {
        mobile: this.mobile,
        clinicCode: this.authService.clinicCode,
      });
    }
    this.gaService.event(
      'Client Matching SMS Resend Code',
      'SMS Verification',
    );
  }

  async resendEmailVerification() {
    this.gaService.event(
      'Verify Email Resend Code',
      'Email Verification',
    );
    if (environment.VETS_4_PETS) {
      const email = await this.authService.getEmail();
      this.email = email;
      Auth.resendSignUp(email);
    } else {
      Parse.Cloud.run('resendVerification', {
        email: this.email
      });
    }
  }

  async verifyCognitoEmail(code: string) {
    this.loading$.next(true);
    const email = await this.authService.getEmail();
    this.email = email;
    try {
      await Auth.confirmSignUp(email, code);
      if (this.passedUser) {
        this.authService.setVerificationJourney();
        Auth.signIn(email, this.passedUser.password).then(() => {
          this.matchAndRegisterUser(true);
          return true;
        }, e => {
          console.log(e);
          throw e;
        });
      } else {
        this.loading$.next(false);
        this.goToAdditionalMatch();
        return true;
      }
    } catch (e) {
      this.loading$.next(false);
      throw e.message;
    }
  }

  async verifySMSWithCode(verificationCode: string) {
    if (environment.VETS_4_PETS) {
      try {
        const credentials = await Auth.currentSession();
        const user = await Auth.currentUserInfo();
        const email = user.attributes.email;
        const token = credentials.getIdToken().getJwtToken();
        return Parse.Cloud.run('verifyV4PSMS', {
          username: email,
          token,
          verificationCode, clinicCode:this.clinicCode
        }, {});
      } catch (e) {
        throw e.message;
      }
    } else {
      try {
        await Parse.Cloud.run('verifySms', {mobile: this.mobile, verificationCode, clinicCode:this.clinicCode}, {});
        this.currentPage$.next(EMAIL_VERIFICATION);
        return true;
      } catch (error) {
        throw ParseErrorHandler.handleParseError(error);
      }
    }
  }

  setEmail(email: string) {
    this.email = email;
  }

  verifyEmailWithCode(verificationCode: string, fromEmailLink?: boolean) {
    this.error$.next(null);
    this.loading$.next(true);
    const verifyEmailParams: VerifyEmailParams = {
      email: this.email || this.passedUser.email,
      verificationCode
    };
    Parse.Cloud.run('verifyEmail', verifyEmailParams, {}).then(sessionToken => {
      if (!fromEmailLink) {
        this.authService.loginPageLoading$.next(true);
        this.loading$.next(false);
        this.resetLoginPage();
      }
      this.authService.setCookiesAndGoToLanding(sessionToken,
        this.router.routerState.snapshot.root.queryParamMap.get('clinicCode'));
    }, (error: VetBookerError) => {
      this.loading$.next(false);
      this.error$.next(ParseErrorHandler.handleParseError(error));
    });
  }

  verifyEmailWithToken(token: string, newPassword: string) {
    this.error$.next(null);
    this.loading$.next(true);
    Parse.Cloud.run('verifyEmail', {
      email: this.email,
      token
    }, {}).then(sessionToken => {
      this.loading$.next(false);
      this.authService.setCookiesAndGoToLanding(sessionToken,
        this.router.routerState.snapshot.root.queryParamMap.get('clinicCode'));
    }, error => {
      this.loading$.next(false);
      this.error$.next(error);
    });
  }

  async resetPassword(token: string, newPassword: string, email?: string) {
    this.error$.next(null);
    this.loading$.next(true);
    const data: PasswordResetParams = {
      email: email || this.email,
      token,
      password: newPassword
    };
    if (environment.VETS_4_PETS) {
      try {
        await Auth.forgotPasswordSubmit(data.email, token, newPassword);
        const user = await Auth.signIn(data.email, newPassword);
        this.authService.setVerificationJourney();
        this.authService.setCookiesAndGoToLanding(user.attributes['custom:SessionToken'],
          user.attributes['custom:ClinicCode']);
        return true;
      } catch (e) {
        throw e.message;
      }
    } else {
      try {
        const sessionToken = await Parse.Cloud.run('resetPassword', data, {});
        this.loading$.next(false);
        this.authService.setCookiesAndGoToLanding(sessionToken,
          this.router.routerState.snapshot.root.queryParamMap.get('clinicCode'));
      } catch (error) {
        this.loading$.next(false);
        this.error$.next(error);
        throw error;
      }
    }
  }

  registerByEmailLink(token: string, password: string) {
    this.error$.next(null);
    this.loading$.next(true);
    const data: EmailRegistrationLinkParams = {
      email: this.email,
      token,
      password
    };
    Parse.Cloud.run('emailRegistrationLink', data, {}).then((createdUserResponse: CreatedUserResponse) => {
      this.loading$.next(false);
      this.authService.setCookiesAndGoToLanding(createdUserResponse.sessionToken, createdUserResponse.vbClinicCode);
    }, error => {
      this.loading$.next(false);
      this.error$.next(error);
    });
  }

  async logOut() {
    if (environment.VETS_4_PETS) {
      await Auth.signOut();
    }
    this.resetLoginPage();
  }

  showNoMatchDialog(postcode: string, clinicName: string) {
    this.dialog.open(NoMatchDialogComponent, {
      maxWidth: this.authService.screenWidth$.getValue() > 400 ? '80vw' : '100vw',
      data: {
        clinicName,
        clinicCode: this.authService.getClinicCode(),
        email: this.authService.getEmail(),
        mobile: this.authService.mobile,
        postcode,
        canRegister: this.authService.canRegister
      }
    });
    this.gaService.event(
      'No Match Found',
      'Client Matching',
      clinicName
    );
  }
}
