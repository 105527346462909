import {Component, HostBinding, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AuthService} from './auth/auth.service';
import {
  ActivatedRoute,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router
} from '@angular/router';
import {SessionService} from './services/session-service.service';
import {BehaviorSubject, Subscription} from 'rxjs';
import {environment} from '../environments/environment';
import {MaterialCssVariables, MaterialCssVarsService} from 'angular-material-css-vars';
import {PatientService} from './patients/patient.service';
import {MatSidenavContent} from '@angular/material/sidenav';
import {VbPatient} from '@appyvet/vetbooker-definitions/dist/patient';
import {ClientPatientDetails} from '@appyvet/vetbooker-definitions/dist/client_patient_details';
import {DomSanitizer, Title} from '@angular/platform-browser';
import {CookieService} from "ngx-cookie-service";
import {MatLegacyDialog as MatDialog} from "@angular/material/legacy-dialog";
import {SplashScreenDialogComponent} from "./components/shared/splash-screen-dialog/splash-screen-dialog.component";
import {GoogleAnalyticsService} from "ngx-google-analytics";
import { Meta } from '@angular/platform-browser';

const THEME_DARKNESS_SUFFIX = `-dark`;
const PETS = 'pets';
const SPLASH_SCREEN = 'groomingSplashScreen';

interface SideNavRoute {
  icon?: string;
  route?: string;
  title: string;
  children?: SideNavRoute[];
  category?: string;
  event?: string;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  @HostBinding('class') activeThemeCssClass: string;
  title = 'vb-ng2';
  @ViewChild(MatSidenavContent) content: MatSidenavContent;
  loaded$ = new BehaviorSubject<boolean>(false);
  routeIsLoading$ = new BehaviorSubject<boolean>(true);
  loginLoaded$ = new BehaviorSubject<boolean>(false);
  error$ = new BehaviorSubject<string>(null);
  isLoggedIn: boolean;
  userRoutes: SideNavRoute [];
  clientPatientDetails: ClientPatientDetails;
  landingLogo: string = environment.GROOM ? '/assets/petslogo@2x.png' : environment.VETS_4_PETS ? '/assets/v4p_logo.png'
    : '/assets/logo@2x.png';
  menuLogo: string = environment.GROOM ? '/assets/new_groom_logo_horizontal.svg' : environment.VETS_4_PETS
    ? '/assets/v4p_logo.png'
    : '/assets/vetbooker_horizontal.png';
  screenWidth$: BehaviorSubject<number>;
  private patientsSubscription: Subscription;
  private clientPatientDetailsSubscription: Subscription;
  private errorSubscription: Subscription;
  private authSubscription: Subscription;
  canAddPet: boolean;
  private termsAndConditionsSub: Subscription;
  termsAgreed$ = new BehaviorSubject<boolean>(true);
  showSupport = !environment.GROOM && !environment.VETS_4_PETS;
  vets4Pets = environment.VETS_4_PETS;
  defaultName = environment.VETS_4_PETS ? ' Vets4Pets' : environment.GROOM ? 'Pets Grooming' : 'VetBooker';
  isGroomRoom = environment.GROOM;
  clinicDeactivated: boolean;
  hideNavigation = false;
  private routerSub: Subscription;
  private clinicName: string;
  whiteBackground: boolean;
  private whiteSub: Subscription;
  whiteToolbar: boolean;

  constructor(
    private authService: AuthService,
    private sessionService: SessionService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private materialCssVarsService: MaterialCssVarsService,
    private patientService: PatientService,
    private gaService: GoogleAnalyticsService,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private cookieService: CookieService,
    private dialog: MatDialog,
    private meta: Meta
  ) {

    this.routerSub = this.router.events.subscribe((event) => {
      switch (true) {
        case event instanceof NavigationStart: {
          this.routeIsLoading$.next(true);
          break;
        }

        case event instanceof NavigationEnd: {
          this.routeIsLoading$.next(false);
          this.content?.getElementRef().nativeElement.scrollTo(0, 0);
          break;
        }

        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          this.routeIsLoading$.next(false);
          break;
        }

        default: {
          break;
        }
      }

      if (event instanceof NavigationEnd && event.id > 1) {
        this.gaService.pageView(event.urlAfterRedirects, this.titleService.getTitle());
      }
    });
  }

  ngOnDestroy(): void {
    this.patientsSubscription?.unsubscribe();
    this.clientPatientDetailsSubscription?.unsubscribe();
    this.errorSubscription?.unsubscribe();
    this.authSubscription?.unsubscribe();
    this.termsAndConditionsSub?.unsubscribe();
    this.routerSub?.unsubscribe();
    this.whiteSub?.unsubscribe();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.authService.screenWidth$.next(event.target.innerWidth);
    this.authService.screenHeight$.next(event.target.innerHeight);
  }

  logout() {
    this.gaService.event(
      'Logout From Menu Bar',
      'Logout',
      this.clinicName
    );
    this.authService.logout();
  }

  updateRoutes(patients?: VbPatient[]) {
    this.userRoutes.forEach(route => {
      if (route.route === PETS) {
        route.children = patients.map((patient) => {
          return {
            route: 'pets/' + patient.patientNumber, title: patient.name, category: 'Navbar', event: 'Pet' +
              ' Selected'
          };
        });
      }
    });
  }

  getBackgroundStyle() {
    return this.sanitizer.bypassSecurityTrustStyle('url(' + this.menuLogo + ') center center/contain no-repeat');
  }

  initRoutes(canBook: boolean, showProduct: boolean) {
    this.userRoutes = [
      {icon: 'home', route: 'landing', title: 'Home'},
    ];
    if (canBook) {
      this.userRoutes.push({
        icon: 'event', route: 'booking', title: 'Book an appointment', category: 'Book Appointment', event: 'Book' +
          ' Appointment From Navbar'
      });
      this.userRoutes.push({icon: 'assignment', route: 'history', title: 'Appointment history'});
    }
    if (showProduct) {
      this.userRoutes.push(
        {icon: 'shopping_basket', route: 'order', title: 'Request a product'});
    }

    this.userRoutes = this.userRoutes.concat(
      {icon: 'contact_phone', route: 'details', title: 'My details'},
      {icon: 'pets', route: PETS, title: 'Pet details', children: null},
    );

    this.userRoutes.push({icon: 'settings', route: 'settings', title: 'Settings'});

    if (environment.VETS_4_PETS) {
      this.userRoutes.push({icon: 'question_answer', route: 'faq', title: 'FAQs'});
    }

    this.userRoutes.push({
      icon: 'exit_to_app', route: 'logout', title: 'Log Out', category: 'Logout', event: 'Logout' +
        ' From Navbar'
    });
  }

  ngOnInit() {
    const content = `width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no`;
    this.meta.updateTag({ name: 'viewport', content });
    
    this.authService.screenWidth$.next(window.innerWidth);
    this.authService.screenHeight$.next(window.innerHeight);
    this.screenWidth$ = this.authService.screenWidth$;
    if (environment.GROOM) {
      this.materialCssVarsService.setPrimaryColor('#002828');
      this.materialCssVarsService.setAccentColor('#14AA5A');
      // if (!this.cookieService.get(SPLASH_SCREEN)) {
      //   this.dialog.open(SplashScreenDialogComponent,  {panelClass : 'no-padding-dialog'}).afterOpened().subscribe(() => {
      //     console.log('after opened')
      //     const expiryDate = new Date(new Date().valueOf() + (365 * 24 * 60 * 60 * 1000));
      //     this.cookieService.set(SPLASH_SCREEN, '1',expiryDate);
      //   });
      // }
    } else if (environment.VETS_4_PETS) {
      const hex = '#211554';
      this.materialCssVarsService.setPrimaryColor(hex);
      this.materialCssVarsService.setAccentColor('#e60064');
      this.materialCssVarsService.setContrastColorThresholdPrimary('100');
      this.materialCssVarsService.setVariable(MaterialCssVariables.PrimaryContrast500, '230, 0, 126');
      this.materialCssVarsService.setVariable(MaterialCssVariables.PrimaryContrast400, '230, 0, 126');
    }
    this.errorSubscription = this.sessionService.clientError$.subscribe(error => {
      this.error$.next(error);
    });
    this.whiteSub = this.authService.whiteBackground$.subscribe(isWhiteBackground => {
      this.whiteBackground = environment.GROOM ? true : isWhiteBackground;
      this.whiteToolbar = environment.GROOM || environment.VETS_4_PETS ? true : isWhiteBackground
    });
    this.authSubscription = this.authService.isLoggedIn$.subscribe((isLoggedIn) => {
      this.isLoggedIn = isLoggedIn;
      if (isLoggedIn) {
        this.termsAndConditionsSub = this.sessionService.termsAndConditions$.subscribe(result => {
          this.termsAgreed$.next(result);
        });
        this.clientPatientDetailsSubscription = this.sessionService.clientPatientDetails$.subscribe(
          clientPatientDetails => {
            if (clientPatientDetails) {
              this.clinicName = clientPatientDetails.themeSettings?.customName;
              this.hideNavigation = clientPatientDetails.clinicDeactivated;
              this.loaded$.next(true);
              this.canAddPet = clientPatientDetails.editSettings.addPatient;
              this.whiteBackground = clientPatientDetails.themeSettings?.isWhiteBackground;
              this.clientPatientDetails = clientPatientDetails;
              this.initRoutes(clientPatientDetails.resources.filter(resource => resource.isBookable).length > 0,
                clientPatientDetails.prescriptions.enableCustomPrescriptions);
              this.patientsSubscription = this.patientService.patients$.subscribe(patients => {
                if (patients) {
                  this.updateRoutes(patients);
                }
              });

              if (
                clientPatientDetails.appointmentSettings?.showPaymentPage &&
                !this.userRoutes.find((route) => route.route === 'payments')
              ) {
                // insert payments just before settings
                const index = this.userRoutes.findIndex((route) => route.route === 'settings');
                this.userRoutes.splice(index, 0, {icon: 'payment', route: 'payments', title: 'My payments'});
              }

              this.authService.clinicCode = clientPatientDetails.clinicCode;
              if (!environment.GROOM && !environment.VETS_4_PETS && clientPatientDetails.themeSettings) {
                const primaryHex = '#3F51B5';
                const accentHex = '#E91E63';
                this.materialCssVarsService.setPrimaryColor(
                  clientPatientDetails.themeSettings?.primaryColor || primaryHex);
                this.materialCssVarsService.setAccentColor(clientPatientDetails.themeSettings?.accentColor || accentHex);
                this.materialCssVarsService.setWarnColor('#b4004e');
              }
              if (!environment.GROOM && !environment.VETS_4_PETS) {
                this.menuLogo = clientPatientDetails.themeSettings?.menuLogo || this.menuLogo;
              }
            }
          }, error => {
            this.loaded$.next(true);
            if (error.code !== 499) {
              this.error$.next(error);
            }
          });
      } else {
        this.userRoutes = [];
        this.loaded$.next(true);
      }
    }, error => {
      console.log('got error on app component logging in', error);
      this.error$.next(error);
    });
  }

  itemClicked(child: SideNavRoute) {
    this.gaService.event(
      child.event || child.title,
      child.category || 'Navbar',
      this.clinicName
    );
  }
}
