<div class="padding-10">
    <div class="container">
        <h1 class="big-header"><span>My Payments</span></h1>

        <div class="row">
            <h3 class="title-header label">Deposit Status</h3>
            <div class="card-wrap">
                <mat-card>
                    <deposit-status></deposit-status>
                </mat-card>
            </div>
        </div>

        <ng-container *ngIf="(payments$ | async) && (payments$ | async).length > 0">
            <div class="row">
                <h3 class="title-header label">Payment History</h3>
                <div class="card-wrap">
                    <mat-card>
                        <payment-history-list></payment-history-list>
                    </mat-card>
                </div>
            </div>
        </ng-container>
    </div>
</div>
