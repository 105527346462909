import { format } from "date-fns";
import { Currency } from "../enums/currency.enum";

export class Payment {
    id: string;
    date: Date;
    currency: Currency;
    amount: number;
    description: string;

    getFormattedDate(formatString: string = 'dd/MM/yyyy HH:mm'): string {
        return format(this.date, formatString)
    }
    
    getFormattedAmount(): string {
        return `£${(this.amount/100).toFixed(2)}`; 
    }
}