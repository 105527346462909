import { Component, Input, OnInit } from "@angular/core";
import { differenceInMinutes } from "date-fns";
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig,
} from "@angular/material/legacy-dialog";
import { environment } from "../../../environments/environment";
import { CancelBookingDialogComponent } from "../../booker/cancel-booking-dialog/cancel-booking-dialog.component";
import { AlertDialogComponent } from "../shared/alert-dialog/alert-dialog.component";
import { SessionService } from "../../services/session-service.service";
import { ActivatedRoute, Router } from "@angular/router";
import { BookingService } from "../../services/booking.service";
import { CancellationReason } from "@appyvet/vetbooker-definitions/dist/cancellation_reason";
import { AppointmentHistoryItem } from "@appyvet/vetbooker-definitions/dist/appointments";
import { ClientPatientDetails } from "@appyvet/vetbooker-definitions/dist/client_patient_details";
import { MultiAppointmentItem } from "@appyvet/vetbooker-definitions/dist/bookings";
import { MatLegacySnackBar as MatSnackBar } from "@angular/material/legacy-snack-bar";
import { BehaviorSubject } from "rxjs";
import { VetBookerError } from "../../interfaces/vberror";
import { GoogleAnalyticsService } from "ngx-google-analytics";
import { PaymentService } from "../../payments/payments.service";
import { RefundDepositOnCancelDialogComponent } from "../../payments/refund-deposit-on-cancel-dialog/refund-deposit-on-cancel-dialog.component";

interface ActionItem {
  name: string;
  icon: string;
}

@Component({
  selector: "app-appointment-card",
  templateUrl: "./appointment-card.component.html",
  styleUrls: ["./appointment-card.component.scss"],
})
export class AppointmentCardComponent implements OnInit {
  @Input() appointmentHistoryItem: AppointmentHistoryItem;
  isGroomRoom = environment.GROOM;
  loading$ = new BehaviorSubject(false);
  actionItems = new Array<ActionItem>();
  private minMinutesToCancel: number;
  private vetBookerCancelText: string;
  private clinicName: string;
  private phoneNumber: string;
  private cancellationReasons: CancellationReason[];
  private depositStatus: { depositOnFile: boolean; depositId: string | null } =
    null;

  constructor(
    private sessionService: SessionService,
    private bookingService: BookingService,
    private gaService: GoogleAnalyticsService,
    public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private service: PaymentService,
  ) {}

  ngOnDestroy(): void {}

  ngOnInit(): void {
    this.getDepositStatus();

    this.sessionService.clientPatientDetails$.subscribe(
      (clientPatientDetails) => {
        if (clientPatientDetails) {
          this.isGroomRoom = clientPatientDetails.isGroomRoom;
          this.minMinutesToCancel =
            clientPatientDetails.appointmentSettings.minMinutesToCancel || -1;
          this.vetBookerCancelText =
            clientPatientDetails.appointmentSettings.minMinutesErrorMessage;
          this.phoneNumber = clientPatientDetails.clinicDetails?.phoneNumber;
          this.clinicName =
            clientPatientDetails.themeSettings.customName || "VetBooker";
          this.cancellationReasons = clientPatientDetails.cancellationReasons;
          if (!clientPatientDetails.appointmentSettings.hideAmend) {
            this.actionItems.push({ name: "Amend", icon: "edit" });
          }
          this.actionItems.push({ name: "Cancel", icon: "delete" });
          if (!environment.VETS_4_PETS) {
            this.actionItems.push({ name: "Directions", icon: "directions" });
          }
          // if (environment.GROOM) {
          //   this.actionItems.push({name: 'Email', icon: 'email'});
          // }
        }
      },
    );
  }

  async getDepositStatus(): Promise<void> {
    this.depositStatus = await this.service.getDepositStatus();
  }

  async getUpcomingAppointmentStatus(): Promise<boolean> {
    return await this.service.getUpcomingAppointmentStatus();
  }

  showCancelDialog(appointment: AppointmentHistoryItem) {
    this.gaService.event(
      "Cancel Appointment Started",
      "Appointment History",
      this.clinicName,
    );
    const cancelDialogConfig = new MatDialogConfig();
    cancelDialogConfig.data = {
      title: environment.GROOM ? "Need to Cancel?" : "Cancel Appointment?",
      appointment,
      isEdit: false,
      cancellationReasons: this.cancellationReasons,
      showCancellationReason: environment.GROOM || environment.VETS_4_PETS,
    };
    cancelDialogConfig.panelClass = "no-padding-dialog";
    const cancelDialog = this.dialog.open(
      CancelBookingDialogComponent,
      cancelDialogConfig,
    );
    cancelDialog.afterClosed().subscribe((result) => {
      if (result === true) {
        this.getUpcomingAppointmentStatus().then((status) => {
          if (
            !status &&
            this.depositStatus.depositOnFile &&
            this.depositStatus.depositId
          ) {
            // no upcoming apt - ask if they want a refund
            this.dialog.open(RefundDepositOnCancelDialogComponent, {
              data: {
                depositOnFile: this.depositStatus.depositOnFile,
                depositId: this.depositStatus.depositId,
                clinicName: this.clinicName,
              },
              panelClass: "no-padding-dialog",
            });
          }
        });
      }
    });
  }

  showCancelAlert(
    appointment: AppointmentHistoryItem,
    cancelText?: string,
    isAmend?: boolean,
  ) {
    this.gaService.event(
      "Cancel Appointment Too Soon",
      "Appointment History",
      this.clinicName,
    );
    const alertDialogConfig = new MatDialogConfig();
    alertDialogConfig.data = {
      title: isAmend ? "Amend Appointment?" : "Cancel Appointment?",
      confirmationText: "Ok",
      message: cancelText,
    };
    this.dialog.open(AlertDialogComponent, alertDialogConfig);
  }

  getParamsForAmend(appointmentItem: MultiAppointmentItem) {
    const clientPatientDetails: ClientPatientDetails =
      this.route.snapshot.data.clientPatientDetails;
    const passedPatientParams = [];
    appointmentItem.appointments.forEach((appt, i) => {
      passedPatientParams.push({
        patientId: appt.patient.patientNumber,
        appointmentType: appt.apptTypeId
          ? appt.apptTypeId
          : clientPatientDetails.appointmentTypes.find(
              (apptType) => apptType.displayName === appt.apptType,
            ),
        notes: appt.notes,
      });
    });
    return {
      patients: JSON.stringify(passedPatientParams),
      date: appointmentItem.clinicDateTime,
      resourceId: appointmentItem.resourceId,
      editId: appointmentItem.objectId,
    };
  }

  getWarningText(
    appointment: AppointmentHistoryItem,
    isAmend: boolean,
  ): string {
    const plural = appointment.appointmentItem.multiAppt ? "s" : "";
    return (
      "We can't wait to see " +
      this.bookingService.getPatientsAppointmentTextFromRequest(
        appointment.appointmentItem,
      ).patients +
      " for their groom" +
      plural +
      " but if you " +
      (isAmend
        ? "need to change your appointment time"
        : "can no longer make it") +
      ", please call " +
      appointment.resourceName +
      " on " +
      this.phoneNumber +
      " to cancel your" +
      " appointment. Cancellations made outside of the cancellation period may result in losing any deposit paid."
    );
  }

  checkinAppointment(appointment: AppointmentHistoryItem) {
    const checkingIn$ = this.bookingService.checkin(
      appointment.appointmentItem,
    );
    this.loading$.next(true);
    const checkinSub = checkingIn$.subscribe(
      (checkedIn) => {
        if (checkedIn) {
          this.loading$.next(false);
          checkinSub.unsubscribe();
        }
      },
      (error: VetBookerError) => {
        this.snackBar.open("Error checking in: " + error.message, null, {
          duration: 5000,
        });
        this.loading$.next(false);
        checkinSub.unsubscribe();
      },
    );
  }

  appointmentAction(
    appointment: AppointmentHistoryItem,
    actionItem: ActionItem,
  ) {
    let isCancellable: boolean =
      this.minMinutesToCancel !== -1
        ? differenceInMinutes(appointment.date, new Date()) >=
          this.minMinutesToCancel
        : true;

    if (this.isGroomRoom) {
      isCancellable = differenceInMinutes(appointment.date, new Date()) >= 2880;
    }

    if (!isCancellable && actionItem.name === "Amend") {
      if (this.isGroomRoom) {
        this.showCancelAlert(
          appointment,
          this.getWarningText(appointment, true),
          true,
        );
      } else {
        this.showCancelAlert(appointment, this.vetBookerCancelText, true);
      }
    } else if (!isCancellable && actionItem.name === "Cancel") {
      this.gaService.event(
        "Cancel Appointment Too Soon",
        "Appointment History",
        this.clinicName,
      );
      if (this.isGroomRoom) {
        this.showCancelAlert(
          appointment,
          this.getWarningText(appointment, false),
          false,
        );
      } else {
        this.showCancelAlert(appointment, this.vetBookerCancelText);
      }
    } else {
      if (actionItem.name === "Email") {
        this.gaService.event(
          "Resend Email",
          "Appointment History",
          this.clinicName,
        );
        this.bookingService.resendAppointmentEmail(appointment.appointmentItem);
      } else if (actionItem.name === "Cancel") {
        this.showCancelDialog(appointment);
      } else if (actionItem.name === "Amend") {
        this.gaService.event(
          "Amend Appointment Started",
          "Appointment History",
          this.clinicName,
        );
        this.bookingService.setEditingAppointment(appointment);
        this.router.navigate([
          "booking",
          this.getParamsForAmend(appointment.appointmentItem),
        ]);
      } else if (actionItem.name === "Directions") {
        this.gaService.event(
          "Get Directions",
          "Appointment History",
          this.clinicName,
        );
        window.open(appointment.mapLink, "_blank");
      }
    }
  }
}
