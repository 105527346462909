import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {BookingService} from "../../services/booking.service";
import {BehaviorSubject, of, switchMap} from "rxjs";
import {environment} from "../../../environments/environment";
import {StripeCardComponent, StripeService} from "ngx-stripe";
import {
  StripeCardElementOptions,
  StripeElementsOptions,
  PaymentRequestPaymentMethodEvent
} from "@stripe/stripe-js";
import {GoogleAnalyticsService} from "ngx-google-analytics";
import {AppointmentSettings} from '@appyvet/vetbooker-definitions/dist/client_patient_details';

@Component({
  selector: 'app-booking-deposit-dialog',
  templateUrl: './booking-deposit-dialog.component.html',
  styleUrls: ['./booking-deposit-dialog.component.scss']
})
export class BookingDepositDialogComponent implements OnInit {
  @ViewChild('card') card: StripeCardComponent;
  isGroomRoom = environment.GROOM;
  error$ = new BehaviorSubject<string>(null);
  loading$ = new BehaviorSubject<boolean>(true);
  paymentIntentData: { pi_secret: string, pi_id: string };

  paymentRequestOptions = {};

  cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        color: "#000000",
        fontFamily: 'Roboto, "Helvetica Neue", sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "18px",
        fontWeight: 300,
        "::placeholder": {
          color: "rgba(0,0,0,0.6)"
        },
        padding: "8px",
      },
      invalid: {
        fontFamily: 'Arial, sans-serif',
        color: "#fa755a",
        iconColor: "#fa755a"
      }
    }
  };
  elementsOptions: StripeElementsOptions = {};
  amount: number;
  aptSettings: AppointmentSettings;
  clinicName: string;

  constructor(
    public gaService: GoogleAnalyticsService,
    private stripeService: StripeService,
    private bookingService: BookingService,
    public dialogRef: MatDialogRef<BookingDepositDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.amount = data.depositAmount;

    this.elementsOptions = {
      locale: 'en',
      mode: 'payment',
      amount: data.depositAmount * 100,
      currency: 'gbp'
    };

    this.paymentRequestOptions = {
        country: 'GB',
        currency: 'gbp',
        total: {
          label: 'Deposit',
          amount: data.depositAmount * 100,
        },
        requestPayerName: false,
        requestPayerEmail: false,
    }
  }

  ngOnInit(): void {
    this.aptSettings = this.bookingService.appointmentSettings$.getValue();
    this.clinicName = this.bookingService.clinicName;

    this.gaService.event(
      'Pay deposit modal opened',
      'Deposits',
      this.aptSettings?.takeDeposit && this.aptSettings?.depositAmount ? `${this.clinicName} - £${this.aptSettings.depositAmount} deposit` : `${this.clinicName} - No deposit`
    );

    this.bookingService.getBookingDepositPaymentIntent().subscribe(data => {
      this.paymentIntentData = data;
      this.loading$.next(false);
    }, () => {
      this.error$.next(`There was an issue creating a deposit request for this booking, if this issue persists, please call the ${(this.isGroomRoom ? 'salon' : 'clinic')} directly.`);
      this.loading$.next(false);
    })
  }

  submitPayment(): void {
    this.error$.next(null);
    this.loading$.next(true);

    this.stripeService
      .confirmCardPayment(
        this.paymentIntentData.pi_secret,
        {
          payment_method: {
            card: this.card.element
          }
        }
      )
      .subscribe((result) => {
        if (result.error) {
          // Payment error.
          this.loading$.next(false);
          this.error$.next(result.error.message ?? 'There was an error processing your payment, please check your details and try again.')
        }

        if (result.paymentIntent) {
          // Success!
          this.gaService.event(
            'Deposit paid',
            'Deposits',
            this.aptSettings?.takeDeposit && this.aptSettings?.depositAmount ? `${this.clinicName} - £${this.aptSettings.depositAmount} deposit` : `${this.clinicName} - No deposit`
          );

          this.dialogRef.close({
            paid: true
          });
        }
      });
  }

  onPaymentMethod(ev: PaymentRequestPaymentMethodEvent) {
    this.stripeService.confirmCardPayment(
        this.paymentIntentData.pi_secret,
        { payment_method: ev.paymentMethod.id },
        { handleActions: false }
    ).subscribe((result) => {
      if (result.error) {
        this.loading$.next(false);
        this.error$.next(result.error.message ?? 'There was an error processing your payment, please check your details and try again.')
      }

      if (result.paymentIntent) {
        this.dialogRef.close({
          paid: true
        });
      }
    });
  }

  onNotAvailable() {
    console.log('Payment Request is not Available');
  }
}
