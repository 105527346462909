<mat-toolbar color="primary" fxLayout="row"
             fxLayoutAlign="space-between center">
  <h3 class="mat-h3">Appointment confirmation</h3>
  <button [mat-dialog-close]="false" aria-label="close" mat-button
          type="button">
    <mat-icon>close</mat-icon>
  </button>
</mat-toolbar>
<mat-dialog-content>
  <div *ngIf="!(loading$ |async)" class="padding-20" fxLayout="column">
    <p *ngIf="!isEdit" class="mat-body-1">{{bookingText}}
      <span *ngIf="isGroomRoom" class="mat-body-1">Please ensure someone over the age of 18 attends the appointment to sign the grooming contract.</span>
    </p>
    <p *ngIf="isEdit" class="mat-body-1">{{editText}}</p>
    <ng-container *ngIf="selectedResource.resourceBookingMessage">
      <div fxLayout="row">
        <p class="mat-body-1">{{selectedResource.resourceBookingMessage}}</p></div>
    </ng-container>
    <form [formGroup]="notesForm" name="booking" novalidate>
      <ng-container *ngIf="isGroomRoom">
        <p class="mat-body-1">
          To help make sure {{bookingItem.selectedPatients[0].patient.patientName}} has the best time with us please let us know any extra details about them you think will be helpful.
          For example, are they likely to be nervous, do they need any extra support, how are they around other dogs and do they hate hair dryers!?
          If it's your first time at our salon we'll always try to call you before your visit to get a really good understanding of your dog and what they are like.
        </p>
      </ng-container>
      <ng-container
        *ngFor="let patient of bookingItem.selectedPatients; index as i">
        <mat-form-field *ngIf="!patient.selectedAppointmentType.hideReason &&
         !hideAllReasons" fxFill appearance="outline">
          <mat-label><span
            *ngIf="!isGroomRoom">Short Description of the problem for {{patient.patient.patientName}}</span><span
            *ngIf="isGroomRoom">Notes for {{patient.patient.patientName}}</span>
          </mat-label>
          <textarea [formControlName]="'notes'+i"
                    [required]="!neverRequireReason &&
                    patient.selectedAppointmentType.requireReason && !patient.selectedAppointmentType.hideReason"
                    aria-label="Notes" [maxLength]="150" matInput></textarea>
          <mat-error *ngFor="let validation of accountValidationMessages.notes">
            <mat-error
              *ngIf="notesForm.get('notes'+i).hasError(validation.type) && (notesForm.get('notes'+i).dirty || notesForm.get('notes'+i).touched)"
              class="error-message">{{validation.message}}</mat-error>
          </mat-error>
          <mat-hint
            align="end">{{notesForm.get('notes' + i).value?.length || 0}}
            /150
          </mat-hint>
        </mat-form-field>
      </ng-container>
      <mat-form-field *ngIf="sms" fxFill>
        <mat-label>SMS Confirmation</mat-label>
        <mat-select [(value)]="smsNumber" class="margin-right" name="smsNumber">
          <mat-option *ngFor="let number of smsNumbers" [value]="number">
            {{ number }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </form>
    <div *ngIf="error$ | async" class="mat-body-1 accent-text-color">
      <p>{{error$ | async}}</p>
    </div>
    <ng-container *ngIf="isGroomRoom">
      <div class="scrolling-div">
        <!-- <ng-container *ngIf="takeDeposit">
          <p class="mat-body-1">
            To secure your booking, we require a {{ depositAmount | currency : 'GBP' : 'symbol-narrow' }} deposit. You can pay securely and easily after clicking BOOK below.
            Please note we will have to cancel your groom if the deposit isn't paid.
            Your deposit is only refundable if you cancel your booking at least 48 hours before your scheduled appointment time so please let us know if you need to make any changes as soon as you can.
            If you cancel within 48 hours, miss the appointment or are sufficiently late the deposit can't be refunded.
            <br><br>
            We can't wait to see you and {{bookingItem.selectedPatients[0].patient.patientName}} but please get in touch with any questions in the meantime. Thanks.
          </p>
        </ng-container> -->
        <ng-container *ngIf="!takeDeposit">
          <p class="mat-body-1">
            Please note that you will be required to pay a £15 deposit to secure your appointment when booking. Your deposit is refundable only if you cancel your booking at least 48 hours before your
            scheduled appointment time, unless you make your booking within 48 hours of your scheduled appointment time in
            which case your deposit is refundable only if you cancel your booking at least 3 hours before your scheduled
            appointment time. If you do not cancel your booking within these timescales, or if you do not attend your
            appointment, or if you are late for your appointment which means that the time allocated for your appointment
            is not sufficient for the groom to be completed, your deposit shall be non-refundable, although it may at our
            sole discretion be carried over to a rearranged appointment.
          </p>
        </ng-container>
      </div>
    </ng-container>
    <ng-container *ngIf="publicHolidayMessage">
      <div class="margin-v-20" fxLayout="row">
        <p class="accent-text-color mat-body-1" style="margin-right: 10px"><b>Note: </b>
        </p>
        <p class="mat-body-1">{{publicHolidayMessage}}</p></div>
    </ng-container>
    <div *ngIf="isGroomRoom" fxLayout="column">
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
        <mat-checkbox [(ngModel)]="terms" aria-label="Terms Checkbox" required
                      style="margin:0!important"></mat-checkbox>
        <p class="mat-caption" style="color: #34495e">
          I accept the <a (click)="showAgreement()"
                          style="text-decoration: underline; cursor:pointer">
          booking terms and conditions</a>
        </p></div>
    </div>
    <div class="padding-20" fxLayout="row" fxLayoutAlign="space-around center">
      <button [mat-dialog-close]="false" aria-label="cancel" class="mat-button"
              mat-stroked-button>Cancel
      </button>
      <button (click)="book()" *ngIf="!isGroomRoom"
              [disabled]="(loading$|async)"
              aria-label="book/amend" class="mat-button" color="primary"
              mat-stroked-button type="submit">
        <span *ngIf="isEdit">Amend</span>
        <span *ngIf="!isEdit">Book</span></button>
      <button (click)="book()" *ngIf="isGroomRoom"
              [disabled]="(isGroomRoom && (!terms)) || (loading$ | async)"
              aria-label="book/amend" class="mat-button groom-room-text"
              color="primary" mat-raised-button type="submit">
        <span *ngIf="isEdit">Amend</span>
        <span *ngIf="!isEdit">Book</span>
      </button>
    </div>
  </div>
</mat-dialog-content>
<div *ngIf="loading$ | async" fxLayout="column" fxLayoutAlign="center center"
     style="min-height: 200px;">
  <mat-progress-spinner color="primary"
                        mode="indeterminate"></mat-progress-spinner>
  <span *ngIf="isEdit" class="mat-body-2">Amending appointment</span>
  <span *ngIf="!isEdit" class="mat-body-2">Booking appointment</span>
</div>
