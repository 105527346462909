import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {PatientsRoutingModule} from './patients-routing.module';
import {PatientsComponent} from './patients/patients.component';
import {PatientDetailComponent} from './patient-detail/patient-detail.component';
import {PatientCardComponent} from './patient-card/patient-card.component';
import {ExtendedModule, FlexModule} from '@angular/flex-layout';
import {MatLegacyCardModule as MatCardModule} from '@angular/material/legacy-card';
import {DateFnsModule} from 'ngx-date-fns';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {CommonComponentsModule} from '../components/common-components/common-components.module';
import {MatDividerModule} from '@angular/material/divider';
import {PatientHistoryComponent} from './patient-history/patient-history.component';
import {MatIconModule} from '@angular/material/icon';
import {MatLegacyProgressSpinnerModule as MatProgressSpinnerModule} from '@angular/material/legacy-progress-spinner';
import {AddPatientComponent} from './add-patient/add-patient.component';
import {CarePlansComponent} from './care-plans/care-plans.component';
import {InsurancePoliciesComponent} from './insurance-policies/insurance-policies.component';
import {VaccinationsComponent} from './vaccinations/vaccinations.component';
import {RemindersComponent} from './reminders/reminders.component';
import {AddPatientPageComponent} from './add-patient-page/add-patient-page.component';
import {ReactiveFormsModule} from '@angular/forms';
import {MatLegacyFormFieldModule as MatFormFieldModule} from '@angular/material/legacy-form-field';
import {MatLegacyInputModule as MatInputModule} from '@angular/material/legacy-input';
import {SpeciesSelectorComponent} from './species-selector/species-selector.component';
import {MatLegacyAutocompleteModule as MatAutocompleteModule} from '@angular/material/legacy-autocomplete';
import {MatLegacySlideToggleModule as MatSlideToggleModule} from '@angular/material/legacy-slide-toggle';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {SexSelectorComponent} from './sex-selector/sex-selector.component';
import {MatLegacyCheckboxModule as MatCheckboxModule} from '@angular/material/legacy-checkbox';
import {ImageCropperModule} from 'ngx-image-cropper';
import {RemovePetDialogComponent} from './remove-pet-dialog/remove-pet-dialog.component';
import {MatLegacySelectModule as MatSelectModule} from '@angular/material/legacy-select';
import {MatLegacyDialogModule as MatDialogModule} from '@angular/material/legacy-dialog';
import {NeuteredSelectorComponent} from './neutered-selector/neutered-selector.component';
import {PetBioComponent} from './pet-bio/pet-bio.component';
import {FleaWormTxComponent} from './flea-worm-tx/flea-worm-tx.component';
import {PatientBreadcrumbsResolver} from './patient-breadcrumbs-resolver';
import {MatToolbarModule} from '@angular/material/toolbar';
import {NgxFileDropModule} from "ngx-file-drop";


@NgModule({
  declarations: [PatientsComponent, PatientDetailComponent, PatientCardComponent, PatientHistoryComponent,
                 AddPatientComponent, CarePlansComponent, InsurancePoliciesComponent, VaccinationsComponent,
                 RemindersComponent, AddPatientPageComponent, SpeciesSelectorComponent, SexSelectorComponent,
                 RemovePetDialogComponent,
                 NeuteredSelectorComponent,
                 PetBioComponent,
                 FleaWormTxComponent],
  exports: [
    AddPatientComponent
  ],
  imports: [
    ImageCropperModule,
    CommonModule,
    PatientsRoutingModule,
    FlexModule,
    MatCardModule,
    DateFnsModule,
    MatButtonModule,
    CommonComponentsModule,
    MatDividerModule,
    ExtendedModule,
    MatIconModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    MatSlideToggleModule,
    MatDatepickerModule,
    MatCheckboxModule,
    MatSelectModule,
    MatDialogModule,
    MatToolbarModule,
    NgxFileDropModule
  ], providers: [PatientBreadcrumbsResolver]
})
export class PatientsModule {
}
