import { Currency } from "../enums/currency.enum"

export const getCurrencySymbol = (currency: Currency): string => {
    switch (currency) {
        case Currency.GBP:
            return '£';
        case Currency.EUR:
            return '€';
        case Currency.USD || Currency.AUD:
            return '$';
    }
}