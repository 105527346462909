import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {PetFormItem} from '../../patients/add-patient-page/add-patient-page.component';
import {MatStepper} from '@angular/material/stepper';


@Component({
  selector: 'app-manage-registration-patients',
  templateUrl: './manage-registration-patients.component.html',
  styleUrls: ['./manage-registration-patients.component.scss']
})
export class ManageRegistrationPatientsComponent implements OnInit {

  @Input() pets: PetFormItem[];
  @Input() stepper: MatStepper;
  @Input() isRegistration: boolean;
  @Input() canSave: boolean;
  @Output() petUpdate = new EventEmitter<number>();
  @Output() petsChange = new EventEmitter<PetFormItem>();
  @Output() selectPet = new EventEmitter<number>();

  constructor() {
  }

  ngOnInit(): void {
  }

  removePet(petToRemove: PetFormItem, i: number) {
    if (!petToRemove.patient) {
      this.pets.splice(i, 1);
    } else {
      this.pets.forEach((pet, index) => {
        if (pet.patient.name === petToRemove.patient.name) {
          this.pets.splice(index, 1);

        }
      });
    }
  }

  addPet() {
    this.pets.push({patient: null, form: new UntypedFormGroup({}), id: (this.pets.length + 1).toString()});
    this.petUpdate.emit(this.pets.length - 1);
  }

  editPet(id: number) {
    this.selectPet.emit(id);
  }
}
