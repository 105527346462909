import {Component, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators} from '@angular/forms';
import {ProductService} from '../product.service';
import {ActivatedRoute} from '@angular/router';
import {PatientService} from '../../patients/patient.service';
import {BehaviorSubject} from 'rxjs';
import {MedicationItem, ProductTypeItem} from '@appyvet/vetbooker-definitions/dist/prescriptions';
import {VbPatient} from '@appyvet/vetbooker-definitions/dist/patient';
import {Resource} from '@appyvet/vetbooker-definitions/dist/resource';
import {GoogleAnalyticsService} from "ngx-google-analytics";

@Component({
  selector: 'app-product-order',
  templateUrl: './product-order.component.html',
  styleUrls: ['./product-order.component.scss']
})
export class ProductOrderComponent implements OnInit {
  loading$ = new BehaviorSubject<boolean>(false);
  success$ = new BehaviorSubject<boolean>(false);
  error$ = new BehaviorSubject<string>(null);
  patients: VbPatient[];
  passedPatient: VbPatient;
  passedMedication: MedicationItem;
  prescriptionCollectionText: string;
  additionalText: string;
  noteText: string;
  resources: Resource[];
  selectedResource: Resource;
  productTypes: ProductTypeItem[];
  selectedType: ProductTypeItem;
  showType = true;
  private clinicName: string;
  productForm = new UntypedFormGroup({
    itemName: new UntypedFormControl('',
      [Validators.required, Validators.maxLength(100)]),
    number: new UntypedFormControl('', [Validators.maxLength(6)]),
    strength: new UntypedFormControl('', [Validators.maxLength(10)]),
    notes: new UntypedFormControl('', [Validators.maxLength(150)]),
  });
  orderValidationMessages = {
    number: [
      {type: 'required', message: 'You must enter a number of items'},
      {type: 'pattern', message: 'Oops, this doesn\'t look like a valid number'},
    ],
    name: [
      {type: 'required', message: 'You must enter a name for the product'},
    ],
  };

  constructor(private patientService: PatientService, private productService: ProductService,
              private activatedRoute: ActivatedRoute, private gaService: GoogleAnalyticsService) {
  }

  ngOnInit(): void {
    this.patientService.patients$.subscribe(patients => {
      if (patients) {
        this.clinicName = this.patientService.clinicName;
        this.patients = patients;
        this.activatedRoute.queryParams.subscribe(params => {
          if (params['patientNumber']) {
            this.passedPatient = patients.find(patient => patient.patientNumber === params['patientNumber']);
            if (!params['itemName']) {
              this.productService.setSelectedPatient(this.passedPatient);
            }
          }
          if (params['itemName']) {
            this.productService.setActiveMedicationRequest(this.passedPatient, this.passedMedication, false);
            this.passedMedication = {itemName: params['itemName']};
          }
          const strengthValidators: ValidatorFn[] = [Validators.maxLength(10)];
          if (this.passedMedication) {
            this.productForm.get('itemName').setValue(this.passedMedication.itemName);
          }
          this.productForm.get('strength').setValidators(strengthValidators);
        });
      }
    });
    this.productService.prescriptionSettings$.subscribe(prescriptionSettings => {
      this.prescriptionCollectionText = prescriptionSettings.collectionText;
      this.additionalText = prescriptionSettings.additionalText;
      this.noteText = prescriptionSettings.noteText;
    });
    this.productService.resources$.subscribe(resources => {
      if (resources) {
        this.resources = resources.filter(resource => resource.prescriptions);
        if (this.resources.length === 1) {
          this.selectedResource = this.resources[0];
        }
      }
    });
    this.productTypes = this.productService.productTypes;
    this.showType = this.productService.showType;
  }

  selectPatient(selectedPatient: VbPatient) {
    this.patients.forEach(patient => {
      patient.isSelected = selectedPatient.patientNumber === patient.patientNumber;
    });
    this.productService.setSelectedPatient(selectedPatient);
  }

  async order() {
    this.gaService.event(
      'Prescription Request Sent',
      'Prescriptions',
      this.clinicName
    );
    this.loading$.next(true);
    this.productService.orderProduct$(this.selectedResource, this.productForm.get('number').value,
      this.productForm.get('strength')?.value, this.productForm.get('itemName').value,
      this.productForm.get('notes').value, this.selectedType ? this.selectedType.name : 'Reorder').subscribe(result => {
      if (result) {
        this.loading$.next(false);
        this.success$.next(true);
      }
    }, e => {
      this.gaService.event(
        'Prescription Request Error',
        'Prescriptions',
        this.clinicName
      );
      this.loading$.next(false);
      this.error$.next(e);
    });
  }

  clearPatients() {
    this.patients.forEach(patient => {
      patient.isSelected = false;
    });
  }

  resetOrder() {
    this.success$.next(false);
    this.selectedType = null;
    this.clearPatients();
    this.productForm.reset();
    this.productService.clearActiveMedicationRequest();
  }
}
