import {Component, OnDestroy, OnInit} from '@angular/core';
import {AlertDialogComponent} from '../../../components/shared/alert-dialog/alert-dialog.component';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ClientDetailsForMatching, SignupService} from '../signup.service';
import {BehaviorSubject, Subscription} from 'rxjs';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {PasswordMatchErrorMatcher} from '../../../components/password-matching-validator';
import {ActivatedRoute} from '@angular/router';
import {environment} from '../../../../environments/environment';
import {TermsDialogComponent} from '../../../components/shared/terms-dialog/terms-dialog.component';
import {ParseErrorHandler} from '../../../parse-error-handler';
import {LoginClinicDetails} from '@appyvet/vetbooker-definitions/dist/clinic_details';
import {GoogleAnalyticsService} from "ngx-google-analytics";

@Component({
  selector: 'app-matching',
  templateUrl: './matching.component.html',
  styleUrls: ['./matching.component.scss']
})
export class MatchingComponent implements OnInit, OnDestroy {
  matchingForm: UntypedFormGroup;
  accountValidationMessages = this.signupService.accountValidationMessages;
  loading$ = this.signupService.loading$;
  matcher = new PasswordMatchErrorMatcher();
  private clinicName: string;
  clinicDetails$ = new BehaviorSubject<LoginClinicDetails>(null);
  private clinicCode: string;
  consent: boolean;
  private passwordRegexp: RegExp = new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{10,}$');
  termsLink: string = environment.VETS_4_PETS ? '' : 'https://';
  showSocialSignIn = environment.VETS_4_PETS;
  private matchingClient: ClientDetailsForMatching;
  private screenWidth: number;
  private routeSub: Subscription;
  private createdUserSub: Subscription;
  private matchUserErrorSub: Subscription;

  constructor(private signupService: SignupService, private dialog: MatDialog, private activatedRoute: ActivatedRoute,
              private gaService: GoogleAnalyticsService) {

  }

  checkPasswords(group: UntypedFormGroup) { // here we have the 'passwords' group
    const pass = group.get('password').value;
    const confirmPass = group.get('confirmPassword').value;
    return pass === confirmPass ? null : {mismatch: true};
  }

  goToPassword() {
    this.signupService.currentPage$.next('password');
  }

  ngOnInit(): void {
    this.screenWidth = window.innerWidth;
    window.onresize = () => {
      this.screenWidth = window.innerWidth;
    };
    const passwordValidators = [Validators.required];
    passwordValidators.push(Validators.pattern(this.passwordRegexp));
    this.matchingForm = new UntypedFormGroup({
        email: new UntypedFormControl(null, [
          Validators.required,
          Validators.email
        ]),
        mobile: new UntypedFormControl(null),
        postcode: new UntypedFormControl(null),
        password: new UntypedFormControl(null, passwordValidators),
        confirmPassword: new UntypedFormControl(null, passwordValidators),
      },
      {validators: this.checkPasswords});
    this.routeSub = this.activatedRoute.data.subscribe((data) => {
      const clinicDetails: LoginClinicDetails = data.clinicDetails;
      if (clinicDetails) {
        this.clinicDetails$.next(clinicDetails);
        this.clinicCode = clinicDetails.clinicCode;
        this.clinicName = clinicDetails.clinicName;
        if (clinicDetails.smsRegistrationAllowed) {
          this.matchingForm.get('mobile')
            .setValidators([Validators.required, Validators.pattern(clinicDetails.country.mobileRegex)]);
        }
        if (clinicDetails.requirePostcode) {
          this.matchingForm.get('postcode').setValidators([Validators.required,
                                                           Validators.pattern(clinicDetails.country.alphanumericRegex),
                                                           Validators.minLength(6), Validators.maxLength(8)]);
        }
      }
    });
    this.matchUserErrorSub = this.signupService.matchUserError$.subscribe(error => {
      if (error) {
        const parsedError = ParseErrorHandler.handleParseError(error);
        this.dialog.open(AlertDialogComponent, {
          maxWidth: this.screenWidth > 400 ? '80vw' : '100vw',
          data: {
            title: 'Error Registering',
            message: parsedError.message,
            confirmationText: 'Got it'
          }
        });
        this.loading$.next(false);
      }
    });
  }

  startRegistration() {
    this.loading$.next(true);
    const matchingClient: ClientDetailsForMatching = {
      email: this.matchingForm.get('email').value,
      mobile: this.matchingForm.get('mobile').value,
      postcode: this.matchingForm.get('postcode').value,
      password: this.matchingForm.get('password').value,
    };
    this.matchingClient = matchingClient;
    this.signupService.startRegistration(matchingClient);
    this.gaService.event(
      'Client Matching Search',
      'Client Matching',
      this.clinicName
    );
  }

  showTerms() {
    this.dialog.open(TermsDialogComponent);
  }

  ngOnDestroy(): void {
    this.routeSub?.unsubscribe();
    this.createdUserSub?.unsubscribe();
    this.matchUserErrorSub?.unsubscribe();
  }
}
