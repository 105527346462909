import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {EMPTY, Observable, of} from 'rxjs';
import {Injectable} from '@angular/core';
import {catchError, mergeMap, take} from 'rxjs/operators';
import {LoginService} from '../services/login-service.service';
import {environment} from '../../environments/environment';
import {AuthService} from '../auth/auth.service';
import {CookieService} from 'ngx-cookie-service';
import {LoginClinicDetails} from '@appyvet/vetbooker-definitions/dist/clinic_details';

@Injectable({
  providedIn: 'root',
})
export class LoginResolver implements Resolve<LoginClinicDetails> {
  constructor(private loginService: LoginService, private router: Router, private authService: AuthService,
              private cookieService: CookieService) {

  }

  resolve(route: ActivatedRouteSnapshot,
          state: RouterStateSnapshot): Observable<LoginClinicDetails> | Observable<never> {
    // If has a fragment, is an old version link, so treat accordingly. Must be an email link
    if (route.fragment) {
      let url = '/userlogin'; // default if for some reason splitting fragment fails
      const splitFragment = route.fragment.split('?');
      url = splitFragment[0];
      this.router.navigateByUrl(url);
    }
    const clinicCode = environment.CLINIC_CODE || route.queryParamMap.get('clinicCode');
    let rxId = route.queryParamMap.get('rxId');
    // In this instance, this is a return from Cognito login
    if (route.queryParams.error_description) {
      this.authService.setError(route.queryParams.error_description);
    }
    // This is from cognito
    if (route.queryParams.state) {
      // Cognito returns the state with a prefix code, then hyphen, then the sent state as a string
      const passedParams = route.queryParams.state.split('-')[1];
      // Cognito sometimes sends back backslashes in parsed JSON response, so needg to remove those
      const cleanParams = passedParams.replace(/\\/g, '');
      const parsedParams = JSON.parse(cleanParams);
      rxId = parsedParams.rxId;
      this.authService.rxId = rxId;
      this.authService.setOriginatingClinicCode(parsedParams.clinicCode);
    }
    if (!clinicCode && !rxId) {
      this.router.navigateByUrl('selector', {
        state: {
          from: 'login',
          error: 'No clinic code',
          errorCode: 411
        }
      });
      return of(null);
    }
    if (rxId) {
      this.loginService.getClinicDetails(rxId, true);
    } else {
      this.loginService.getClinicDetails(clinicCode, false);
    }
    return this.loginService.clinicDetails$.pipe(catchError((error) => {
      if(error.message.indexOf('499')){
        const message  = JSON.parse(error.message);
        this.authService.closedMessage$.next(message.msg);
        this.router.navigateByUrl('closed');
      } else {
        console.log('Clear cookies');
        this.cookieService.deleteAll();
        // this.router.navigateByUrl('selector');
      }
      throw error;
    }), take(1), mergeMap(loginDetails => {
      if (loginDetails) {
        this.authService.clinicCode = loginDetails.clinicCode;
        return of(loginDetails);
      } else {
        this.router.navigateByUrl('error', {
          state: {
            from: 'register',
            error: 'Unable to get clinic details',
            errorCode: 411
          }
        });
        return EMPTY;
      }
    }));
  }
}
