import {Component, OnInit} from '@angular/core';
import {SessionService} from '../../services/session-service.service';
import {BehaviorSubject} from 'rxjs';
import {ClientService} from '../client.service';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig} from '@angular/material/legacy-dialog';
import {AlertDialogComponent} from '../../components/shared/alert-dialog/alert-dialog.component';
import {ConfirmationDialogComponent} from '../../components/shared/confirmation-dialog/confirmation-dialog.component';
import {ErrorDialogComponent} from '../../components/shared/error-dialog/error-dialog.component';
import {AuthService} from '../../auth/auth.service';
import {VbContact} from '@appyvet/vetbooker-definitions/dist/contacts_model';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';
import {GoogleAnalyticsService} from "ngx-google-analytics";

@Component({
  selector: 'app-contact-entries',
  templateUrl: './contact-entries.component.html',
  styleUrls: ['./contact-entries.component.scss', '../client-details/client-details.component.scss']
})
export class ContactEntriesComponent implements OnInit {
  canEdit: boolean;
  canDelete: boolean;
  canSetPreferred: boolean;
  isGroomRoom: boolean;
  contacts$ = new BehaviorSubject<VbContact[]>([]);
  addingContact: boolean;
  newContact: VbContact;
  updateContactForm: UntypedFormGroup;
  private phoneNumbers: number;
  private emailAddresses: number;
  reminderConsentEnabled: boolean;
  phoneRegexp: string;
  mobileRegexp: string;
  reminderConsentText: string;
  contactValidationMessages = this.clientService.CONTACT_VALIDATION_MESSAGES;
  preferredLoading: boolean;
  contactUpdating: boolean;
  private clinicName: string;
  canAddContact: boolean;

  constructor(private sessionService: SessionService, private clientService: ClientService, private dialog: MatDialog,
              private authService: AuthService, private gaService: GoogleAnalyticsService, private snackBar: MatSnackBar) {
  }

  ngOnInit(): void {
    this.sessionService.clientPatientDetails$.subscribe(clientPatientDetails => {
      this.canEdit = clientPatientDetails.editSettings.editContact;
      this.canDelete = clientPatientDetails.editSettings.deleteContact;
      this.canAddContact = clientPatientDetails.editSettings.canAddContact;
      this.canSetPreferred = clientPatientDetails.editSettings.updatePreferredContact;
      this.isGroomRoom = clientPatientDetails.isGroomRoom;
      this.phoneNumbers = clientPatientDetails.contacts.filter(contact => contact.typeCode !== 1).length;
      this.emailAddresses = clientPatientDetails.contacts.filter(contact => contact.typeCode === 1).length;
      this.reminderConsentEnabled = clientPatientDetails.editSettings.reminderConsentEnabled;
      this.reminderConsentText = clientPatientDetails.editSettings.reminderConsentIntro;
      this.clinicName = clientPatientDetails.themeSettings.customName;
    });
    this.clientService.contacts$.subscribe(contacts => {
      this.contacts$.next(contacts.sort((a, b) => Number(b.isPreferred) - Number(a.isPreferred)));
    });
  }

  canDeleteContact(contact: VbContact) {
    if (!this.canDelete || contact.isPreferred) {
      return false;
    }
    if (contact.typeCode === 1 && this.emailAddresses > 1 && ((this.isGroomRoom && contact.displayName.toLowerCase() !== 'online booking email') || (!this.isGroomRoom && contact.displayName !== 'Email'))) {
      return true;
    } else {
      return contact.typeCode !== 1 && contact.displayName !== 'Home' && contact.displayName !== 'Mobile' && this.phoneNumbers > 1;
    }
  }

  deleteContact(contact: VbContact) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: 'Delete contact?',
      message: 'Are you sure you want to delete this contact information? Once confirmed, you will not be able to undo this action.'
    };
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.clientService.deleteContact$(contact).subscribe(() => {
          this.gaService.event(
        'Delete Contact',
            'Client Details',
            this.clinicName
          );
        }, error => {
          this.gaService.event(
            'Delete Contact Error',
            'Client Details',
            this.clinicName
          );
          const errorDialogConfig = new MatDialogConfig();
          errorDialogConfig.data = {
            title: 'Error deleting contact',
            error
          };
          this.dialog.open(ErrorDialogComponent, errorDialogConfig);
        });
      }
    });
  }

  async setPreferred(contact: VbContact): Promise<void> {
    this.preferredLoading = true;
    const updateContact = Object.assign({}, contact);
    updateContact.isPreferred = true;
    try {
      await this.clientService.setPreferredContact(updateContact);
      this.preferredLoading = false;
    } catch (e) {
      this.snackBar.open('Error saving preferred contact, please try again', null, {duration: 5000});
      this.preferredLoading = false;
    }
  }

  finishUpdatingContact(contact: VbContact, isUsernameChange?: boolean): void {
    this.contactUpdating = true;
    this.clientService.updateContact$(contact).subscribe(result => {
      if (result) {
        this.cancelUpdatingContact(contact);
        if (isUsernameChange) {
          this.authService.logout();
        }
      }
    }, error => {
      this.contactUpdating = false;
      const errorDialogConfig = new MatDialogConfig();
      errorDialogConfig.data = {
        title: 'Error updating contact',
        error
      };
      this.dialog.open(ErrorDialogComponent, errorDialogConfig);
    });
  }

  updateContact(contact: VbContact): void {
    contact.number = this.updateContactForm.get('number').value;
    contact.optIn = this.updateContactForm.get('reminder')?.value || false;
    if (contact.displayName.toLowerCase().indexOf('online') !== -1 && this.isGroomRoom) {
      this.gaService.event(
        'Edit Online Booking Email',
        'Client Details',
        this.clinicName
      );
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        title: 'Change online booking email',
        message: 'To confirm you are changing your online booking email address to ' + contact.number +
          '. Upon clicking save, you will be logged out of your account and will be sent a verification email.' +
          ' You will need to verify your new email address before you can log back in.',
        confirmText: 'Yes, change address',
        cancelText: 'No, take me back'
      };
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.finishUpdatingContact(contact, true);
        }
      });
    } else {
      this.gaService.event(
        'Edit Contact',
        'Client Details',
        this.clinicName
      );
      this.finishUpdatingContact(contact);
    }
  }

  cancelUpdatingContact(contact: VbContact): void {
    this.contactUpdating = false;
    contact.originalData = null;
  }

  startAddContact(): void {
    this.addingContact = true;
  }

  startUpdatingContact(contact: VbContact): void {
    let existingModificationInProgress = false;
    this.contacts$.getValue().forEach(currentContact => {
      if (currentContact.originalData) {
        existingModificationInProgress = true;
      }
    });
    if (existingModificationInProgress) {
      const alertDialogConfig = new MatDialogConfig();
      alertDialogConfig.data = {
        confirmationText: 'Got it!',
        message: 'There is an existing contact entry being modified. Please save or cancel that one first, before trying to modify another.'
      };
      this.dialog.open(AlertDialogComponent, alertDialogConfig);
    } else {
      contact.originalData = Object.assign({}, contact);
      this.updateContactForm = new UntypedFormGroup({
        number: new UntypedFormControl(contact.number, [
          Validators.required, this.clientService.getNumberValidator(contact.originalData.typeCode)
        ]), name: new UntypedFormControl(contact.displayName, [
          Validators.required,
        ])
      });
      if (this.reminderConsentEnabled) {
        this.updateContactForm.addControl('reminder',
          new UntypedFormControl(contact.typeCode === 1 ? contact.allowEmail : contact.allowAMM));
      }
    }
  }

  isEditableName(contact: VbContact) {
    return contact.displayName.toLowerCase().indexOf(
      'online') !== -1 && contact.displayName.toLowerCase() !== 'home' && contact.displayName.toLowerCase() !== 'mobile';
  }
}
