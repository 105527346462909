<mat-toolbar color="primary" fxLayout="row"
             fxLayoutAlign="space-between center">
  <h3 class="mat-h3">Deposit refund</h3>
  <button [mat-dialog-close]="false" aria-label="close" mat-button
          type="button">
    <mat-icon>close</mat-icon>
  </button>
</mat-toolbar>
<mat-dialog-content>
<div class="padding-10">
  <ng-container *ngIf="loading$ | async">
    <div fxLayout="column" fxLayoutAlign="center center"
         style="padding:10px;">
      <mat-progress-spinner color="primary" diameter="40" mode="indeterminate"></mat-progress-spinner>
    </div>
  </ng-container>
  <ng-container *ngIf="!(loading$ | async) && !complete">
    <div fxLayout="column">
      <p class="mat-body-1">Looks like you don't have any confirmed bookings with us. Would you like us to refund your deposit back to the original card/account you used for the original payment?</p>
      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-around center" fxLayoutGap.xs="10px">
        <button (click)="close()" class="full-width-xs"
                aria-label="no cancel"
                mat-stroked-button>
          No
        </button>
        <button (click)="refund()"
                class="full-width-xs" aria-label="yes cancel" color="primary"
                mat-raised-button>
          Yes
        </button>
      </div>
      <ng-container *ngIf="error$ | async">
        <div class="mat-body-1 accent-text-color error">{{ error$ | async }}</div>
      </ng-container>
    </div>
  </ng-container>
  <ng-container *ngIf="!(loading$ | async) && complete">
    <div fxLayout="column">
      <p class="mat-body-1">That's all sorted. Your deposit should be refunded in the next 3-7 days. Thanks</p>
    </div>
  </ng-container>
</div>
</mat-dialog-content>
