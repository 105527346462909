import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import * as Parse from 'parse';
import {environment} from '../../environments/environment';
import {Router} from '@angular/router';import { from } from 'rxjs';
import {flatMap, map, tap} from 'rxjs/operators';
import {CookieService} from 'ngx-cookie-service';
import {addHours, addMonths} from 'date-fns';
import {Auth} from '@aws-amplify/auth';
import {CognitoUser} from 'amazon-cognito-identity-js';
import {Hub} from '@aws-amplify/core';
import {BnNgIdleService} from 'bn-ng-idle';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {ClinicCodeMismatchDialogComponent} from '../login/clinic-code-mismatch-dialog/clinic-code-mismatch-dialog.component';
import {AVAILABILITY_COOKIE, LOGIN_ACTION, TRANSFER_ACTION} from '../../constants';
import {LoginClinicDetails} from '@appyvet/vetbooker-definitions/dist/clinic_details';
import {LoginParams, LoginResult} from '@appyvet/vetbooker-definitions/dist/login';
import {CognitoSignupResult} from '@appyvet/vetbooker-definitions/dist/register_user';

export interface NewUserResponse {
  clientNumber?: string;
  sessionToken?: string;
  clinicCode?: number;
  patientCount?: number;
  success?: string;
}

interface UserAttributes {
  sub: string;
  email: string;
  email_verified: string;
  name: string;
  updated_at: string;
  'custom:SessionToken': string;
  'custom:ClinicCode': string;
  'custom:IsWebRegistration': string;
}

interface CognitoUserExt extends CognitoUser {
  attributes: UserAttributes;
}

export interface CognitoAccountCreationResult {
  accountRequired: boolean;
  accountCreated: boolean;
}

@Injectable({
  providedIn: 'root'
})


export class AuthService {
  private error = new Subject<string>();
  isLoggedIn$ = new BehaviorSubject<boolean>(false);
  closedMessage$ = new BehaviorSubject<string>(null);
  loginPageLoading$ = new BehaviorSubject<boolean>(false);
  loginError$ = this.error.asObservable();
  clinicCode: string;
  screenWidth$ = new BehaviorSubject<number>(1280);
  screenHeight$ = new BehaviorSubject<number>(1024);
  password: string;
  username: string;
  expiryDate = environment.VETS_4_PETS ? addHours(new Date(), 24) : addMonths(new Date(), 12);
  private user: any;
  rxId: string;
  private sessionToken: string;
  private saveSessionToken = true;
  cognitoResult$ = new Subject<CognitoSignupResult>();
  mobile: string;
  isNewRegistration$ = new BehaviorSubject<boolean>(false);
  private smsVerificationJourney: boolean;
  originatingClinicCode: string;
  clinicName: string;
  clinicMismatch: boolean;
  whiteBackground$ = new BehaviorSubject<boolean>(false);
  canRegister = false;
  canBook = false;

  constructor(private router: Router, private cookieService: CookieService, private bnIdle: BnNgIdleService,
              private dialog: MatDialog) {
    this.isLoggedIn().then(result => {
      this.isLoggedIn$.next(result);
    });
    if (environment.VETS_4_PETS) {
      this.saveSessionToken = false;
      this.expiryDate = addHours(new Date(), 24);
      this.bnIdle.startWatching(86400).subscribe((isTimedOut: boolean) => {
        if (isTimedOut) {
          this.logout(true);
        }
      });
      Hub.listen('auth', (data) => {
        switch (data.payload.event) {
          case 'signIn':
            Auth.currentUserInfo().then((res: CognitoUserExt) => {
              if (res.attributes['custom:SessionToken']) {
                console.log('clinic code', res.attributes['custom:ClinicCode'], this.originatingClinicCode);
                if (this.originatingClinicCode && res.attributes['custom:ClinicCode'] !== this.originatingClinicCode) {
                  this.openClinicMismatchDialog(res.attributes['custom:SessionToken'],
                    res.attributes['custom:ClinicCode']);
                } else {
                  this.setCookiesAndGoToLanding(res.attributes['custom:SessionToken'],
                    res.attributes['custom:ClinicCode']);
                }
              } else {
                Parse.Cloud.run('checkUnverifiedLogin', {username: res.attributes.email}).then(result => {
                  if (!this.mobile && result.mobile) {
                    this.mobile = result.mobile;
                  }
                  this.cognitoResult$.next(result);
                  this.username = res.attributes.email;
                  // 2 ways can reach this point. This is an unmatched user, either reached via a login
                  // after abandoned signup, or during signup itself. During signup, already have
                  // mobile number so will move to SMS match. If login after abandoned journey, need
                  // to navigate to additional match screen to capture mobile/postcode
                  if (!this.smsVerificationJourney) {
                    this.router.navigate(['/login'],
                      {
                        queryParams: {
                          rxId: this.rxId,
                          clinicCode: this.getClinicCode(),
                          additionalMatch: this.mobile ? null : true
                        }
                      });
                  }
                });
              }
            }, error => {
              console.log('current user error', error);
            });
            break;
          case 'signIn_failure':
            if (data.payload.data.code === 'UserNotConfirmedException') {
              this.router.navigate(['/login'],
                {queryParams: {rxId: this.rxId, clinicCode: this.clinicCode, unverified: true}});
            }
            break;
          default:
            break;
        }
      });
    }
  }

  async getEmail(): Promise<string> {
    let email = this.username;
    if (!email) {
      if (environment.VETS_4_PETS) {
        const user = await Auth.currentUserInfo();
        if (!user) {
          return;
        }
        email = user.attributes.email;
        this.username = email;
      }
    }
    return email;
  }

  setMobile(mobile: string) {
    this.mobile = mobile;
  }

  getClinicCode(): string {
    if (this.clinicCode) {
      return this.clinicCode;
    } else {
      return this.cookieService.get('clinicCode');
    }
  }

  resetClinicCode(navigate: boolean) {
    this.cookieService.delete('clinicCode');
    this.clinicCode = null;
    if (navigate) {
      this.router.navigate(['/selector']);
    }
  }

  setError(error: string) {
    this.error.next(error);
  }

  async isLoggedIn(): Promise<boolean> {
    if (environment.VETS_4_PETS) {
      const user = await Auth.currentUserInfo();
      return user && !!this.getSessionToken();
    } else {
      return !!this.getSessionToken();
    }
  }

  openClinicMismatchDialog(sessionToken: string, clinicCode: string) {
    const dialogRef = this.dialog.open(ClinicCodeMismatchDialogComponent);
    dialogRef.afterClosed().subscribe(result => {
      this.clinicMismatch = true;
      if (result === TRANSFER_ACTION) {
        this.setCookiesAndGoToLanding(sessionToken, clinicCode, '/settings');
      } else if (result === LOGIN_ACTION) {
        this.setCookiesAndGoToLanding(sessionToken, clinicCode);
        this.cognitoResult$.next({verified: true, matched: true});
      } else {
        this.logout();
      }
    });
  }

  async signInWithCognito(username: string, password: string, clinicDetails: LoginClinicDetails) {
    this.username = username;
    this.password = password;
    this.cognitoResult$ = new Subject();
    try {
      const result = await Auth.signIn(username, password);
    } catch (e) {
      this.cognitoResult$.error(e);
    }
  }

  async getCognitoToken() {
    const credentials = await Auth.currentSession();
    return credentials?.getIdToken()?.getJwtToken();
  }

  async createCognitoAccount(username: string, password: string): Promise<CognitoAccountCreationResult> {
    if (environment.VETS_4_PETS) {
      this.username = username;
      this.password = password;
      try {
        const result = await Auth.signUp({
          password,
          username,
        });
        return {accountCreated: !!result.userSub, accountRequired: true};
      } catch (e) {
        throw e;
      }
    } else {
      return {accountRequired: false, accountCreated: false};
    }
  }

  setCookiesAndGoToLanding(sessionToken: string, clinicCode: string, url?: string) {
    this.cookieService.set('sessionToken', sessionToken, this.expiryDate);
    this.sessionToken = sessionToken;
    if (clinicCode) {
      this.cookieService.set('clinicCode', clinicCode, this.expiryDate);
    }
    if (!url) {
      url = '/landing';
      if (this.cookieService.check(AVAILABILITY_COOKIE)) {
        url = '/booking';
      }
    }
    this.router.navigate([url],
      {queryParams: {clinicCode: this.router.routerState.snapshot.root.queryParamMap.get('clinicCode')}});
    this.isLoggedIn$.next(true);
  }

  login(username: string, pass: string, verificationCode?: string): Observable<boolean> {
    const loginDone$ = new Subject<boolean>();
    this.error.next('');
    this.username = username;
    const loginParams: LoginParams = {
      username: username.toLowerCase(),
      password: pass,
      verificationCode,
      device: 'web'
    };
    Parse.Cloud.run('login', loginParams, {sessionToken: null}).then((loginResult: LoginResult) => {
      const currentClinicCode = this.router.routerState.snapshot.root.queryParamMap.get('clinicCode');
      if (currentClinicCode !== loginResult.clinicCode && environment.GROOM) {
        this.openClinicMismatchDialog(loginResult.sessionToken, loginResult.clinicCode);
      } else {
        let url;
        if (this.cookieService.check(AVAILABILITY_COOKIE)) {
          url = '/booking';
        }
        this.setCookiesAndGoToLanding(loginResult.sessionToken, currentClinicCode, url);
      }
    }, ((error) => {
      loginDone$.error(error);
    }));
    return loginDone$;
  }

  resetPassword(email: string): Observable<boolean> {
    const resetPasswordDone$ = new Subject<boolean>();
    this.error.next('');
    this.username = email;
    if (environment.VETS_4_PETS) {
      Auth.forgotPassword(email).then(() => {
        resetPasswordDone$.next(true);
        this.router.navigateByUrl('resetPassword');
      }).catch(err => {
        resetPasswordDone$.error(err);
      });
    } else {
      Parse.Cloud.run('requestPasswordReset', {
        email,
        device: 'web'
      }).then(() => {
        resetPasswordDone$.next(true);
      }, ((error: Parse.Error) => {
        resetPasswordDone$.error(error);
      }));
    }
    return resetPasswordDone$;
  }

  async logout(sessionExpired?: boolean, noNavigate?: boolean) {
    this.clinicCode = this.cookieService.get('clinicCode');
    this.sessionToken = null;
    // Challenges deleting cookie with library, so set to expired date and empty as per
    // https://github.com/stevermeister/ngx-cookie-service/issues/93
    this.cookieService.set('sessionToken', '', new Date('Thu, 01 Jan 1970 00:00:01 GMT'), '/');
    const clinicCode = this.router.routerState.snapshot.root.queryParamMap.get('clinicCode') || this.clinicCode;
    const queryParams: any = {clinicCode};
    if (sessionExpired) {
      queryParams.sessionTokenExpired = true;
    }
    if (environment.VETS_4_PETS) {
      await Auth.signOut();
    }
    queryParams.signedOut = true;
    this.loginPageLoading$.next(false);
    this.isLoggedIn$.next(false);
    if (!noNavigate) {
      await this.router.navigate(['/login'], {queryParams});
    }
  }

  linkCognitoAccount(sessionToken: string, clinicCode: string) {
    from(Auth.currentAuthenticatedUser()).pipe(tap(user => {
      this.user = user;
    }), flatMap(() => {
      return from(Auth.updateUserAttributes(this.user, {
        'custom:SessionToken': sessionToken,
        'custom:ClinicCode': clinicCode,
        // "custom:IsWebRegistration": "true",
      })).pipe(map(a => {
        return true;
      }));
    })).subscribe((result) => {
      if (result) {
        this.isNewRegistration$.next(true);
        this.setCookiesAndGoToLanding(sessionToken, clinicCode);
      }
    });
  }

  setVerificationJourney() {
    this.smsVerificationJourney = true;
  }

  setSessionToken(token: string) {
    this.sessionToken = token;
  }

  getSessionToken(): string {
    const isLoggedIn = this.cookieService.get('sessionToken') || this.sessionToken;
    // if (!isLoggedIn) {
    //   this.logout(true);
    // }
    return isLoggedIn;
  }

  setClinicName(clinicName: string) {
    this.clinicName = clinicName;
  }

  setClinicCode(clinicCode: string) {
    this.cookieService.set('clinicCode', clinicCode, this.expiryDate);
  }

  setOriginatingClinicCode(clinicCode: string) {
    this.originatingClinicCode = clinicCode;
  }

  setWhiteBackground(isWhiteBackground: boolean) {
    this.whiteBackground$.next(isWhiteBackground);
  }

  setCanBook(acceptingBookings: boolean) {
    this.canBook = acceptingBookings;
  }

  setCanRegister(acceptingRegistrations: boolean) {
    this.canRegister = acceptingRegistrations;
  }
}
