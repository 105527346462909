import {Component, OnDestroy, OnInit} from '@angular/core';
import {SignupService} from '../signup.service';
import {BehaviorSubject, Subscription} from 'rxjs';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {LoginClinicDetails} from '@appyvet/vetbooker-definitions/dist/clinic_details';
import {CreatedUserResponse} from '@appyvet/vetbooker-definitions/dist/register_user';


@Component({
  selector: 'app-cognito-verification',
  templateUrl: './cognito-verification.component.html',
  styleUrls: ['./cognito-verification.component.scss']
})
export class CognitoVerificationComponent implements OnInit, OnDestroy {

  verificationForm: UntypedFormGroup;
  accountValidationMessages = this.signupService.accountValidationMessages;
  resentEmail = false;
  isGroomRoom: boolean;
  clinicDetails$ = new BehaviorSubject<LoginClinicDetails>(null);
  currentUser$ = new BehaviorSubject<CreatedUserResponse>(null);
  loading$ = new BehaviorSubject<boolean>(false);
  error$ = new BehaviorSubject<string>(null);
  private routeSub: Subscription;
  private loadingSub: Subscription;

  constructor(private signupService: SignupService, private activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.currentUser$ = this.signupService.foundUser$;
    this.verificationForm = new UntypedFormGroup({
      emailCode: new UntypedFormControl(null, [
        Validators.required,
        Validators.pattern('^[0-9]*$'),
        Validators.minLength(6),
        Validators.maxLength(6),
      ]),
    });
    this.routeSub = this.activatedRoute.data
      .subscribe((data: { clinicDetails: LoginClinicDetails }) => {
        this.clinicDetails$.next(data.clinicDetails);
      });
    this.loadingSub = this.signupService.loading$.subscribe(loading => {
      this.loading$.next(loading);
    });
  }

  requestNewEmailCode() {
    this.resentEmail = true;
    this.signupService.resendEmailVerification();
  }

  async verifyEmail() {
    try {
      await this.signupService.verifyCognitoEmail(this.verificationForm.get('emailCode').value.toString());
    } catch (e) {
      this.error$.next(e);
    }
  }

  ngOnDestroy(): void {
    this.routeSub?.unsubscribe();
    this.loadingSub?.unsubscribe();
  }
}
