import {Component, OnDestroy, OnInit} from '@angular/core';
import {PatientService} from '../../../patients/patient.service';
import {Subscription} from 'rxjs';
import {VbExtendedPatient} from '@appyvet/vetbooker-definitions/dist/patient';
import {SessionService} from "../../../services/session-service.service";
import {GoogleAnalyticsService} from "ngx-google-analytics";

@Component({
  selector: 'app-pets-carousel',
  templateUrl: './pets-carousel.component.html',
  styleUrls: ['./pets-carousel.component.scss']
})
export class PetsCarouselComponent implements OnInit, OnDestroy {
  patients: VbExtendedPatient[];
  private patientsSub: Subscription;
  private clinicName: string;
  isGroomRoom: boolean;

  constructor(private patientService: PatientService, private gaService: GoogleAnalyticsService, private sessionService: SessionService) {
  }

  ngOnInit(): void {
    this.sessionService.clientPatientDetails$.subscribe(clientPatientDetails => {
      this.isGroomRoom = clientPatientDetails.isGroomRoom;
    });
    this.clinicName = this.patientService.clinicName;
    this.patientsSub = this.patientService.patients$.subscribe(patients => {
      this.patients = patients;
    });
  }

  ngOnDestroy(): void {
    this.patientsSub?.unsubscribe();
  }

  petSelected() {
    this.gaService.event(
      'Pet Selected',
      'Landing',
      this.clinicName
    );
  }
}
