import { currencyAdapter } from "../adapters/currency.adapter";
import { PaymentDto } from "../interfaces/dto/payment.dto";
import { Payment } from "../models/payment.model";

export const paymentFactory = (dto: PaymentDto) => {
    const payment = new Payment();
    payment.id = dto.id;
    payment.date = new Date(dto.date);
    payment.currency = currencyAdapter(dto.currency);
    payment.amount = dto.amount;
    payment.description = dto.description;
    return payment;
}