import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PatternValidator, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-change-email',
  templateUrl: './change-email.component.html',
  styleUrls: ['./change-email.component.scss']
})
export class ChangeEmailComponent implements OnInit {
  emailForm: UntypedFormGroup;
  emailValidationMessages = {
    email: [
      {type: 'required', message: 'Email is required'},
      {type: 'email', message: 'Oops, we don\'t recognise that as a valid email address'},
    ],
  };
  @Input() email: string;
  @Input() loading: boolean;
  @Output() emailSave = new EventEmitter<string>();

  constructor() {
  }

  ngOnInit(): void {
    this.emailForm = new UntypedFormGroup({email: new UntypedFormControl(this.email, [Validators.email, Validators.required,Validators.pattern(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/)])});
  }

  saveEmail() {
    this.emailSave.emit(this.emailForm.get('email').value);
  }
}
