import { Currency } from "../enums/currency.enum";

export const currencyAdapter = (currency: string): Currency => {
    switch (currency) {
        case 'GBP':
            return Currency.GBP;
        case 'USD':
            return Currency.USD;
        case 'EUR':
            return Currency.EUR;
        case 'AUD':
            return Currency.AUD;
    }
}