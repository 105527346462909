import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {environment} from '../../../environments/environment';
import {Router} from '@angular/router';
import {LoginService} from '../../services/login-service.service';
import {BehaviorSubject, Subscription} from 'rxjs';
import {DomSanitizer} from '@angular/platform-browser';
import {AuthService} from '../../auth/auth.service';
import {AlertDialogComponent} from '../../components/shared/alert-dialog/alert-dialog.component';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {ClinicDetails} from '@appyvet/vetbooker-definitions/dist/clinic_details';

@Component({
  selector: 'app-clinic-selector',
  templateUrl: './clinic-selector.component.html',
  styleUrls: ['./clinic-selector.component.scss']
})
export class ClinicSelectorComponent implements OnInit, OnDestroy {
  isGroomRoom = environment.GROOM;
  isVets4Pets = environment.VETS_4_PETS;
  error$ = new BehaviorSubject<string>(null);
  loading$ = new BehaviorSubject<boolean>(false);
  @ViewChild('clinicCodeInput') clinicCodeInput: ElementRef;
  private errorSubscriber: Subscription;
  landingLogo: string = environment.GROOM ? '/assets/petslogo@2x.png' : environment.VETS_4_PETS
                                                                        ? '/assets/v4p_logo_white.png'
                                                                        : '/assets/vetbooker_horizontal.png';

  constructor(private loginService: LoginService, private router: Router, private sanitizer: DomSanitizer,
              private authService: AuthService, private dialog: MatDialog) {
  }

  getBackgroundStyle() {
    if (!environment.GROOM) {
      if (environment.VETS_4_PETS) {
        return this.sanitizer.bypassSecurityTrustStyle('url(' + this.landingLogo + ') center center/contain no-repeat');
      }
      return this.sanitizer.bypassSecurityTrustStyle('url(' + this.landingLogo + ') center center/cover no-repeat');
    } else {
      return null;
    }
  }

  onNext(): void {
    this.router.navigate(['/login'], {queryParams: {clinicCode: this.clinicCodeInput.nativeElement.value}});
    this.loading$.next(true);
  }

  ngOnInit(): void {
    this.errorSubscriber = this.loginService.clinicCodeError$.subscribe(err => {
      if (err) {
        this.loading$.next(false);
        if(err.message?.indexOf('499')){
          const message  = JSON.parse(err.message);
          this.dialog.open(AlertDialogComponent, {data: {html: message.msg}});
          this.error$.next('Unable to select this clinic');
        } else {
          this.error$.next(err.message);
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.errorSubscriber?.unsubscribe();
  }

  clinicSelected(selectedClinic: ClinicDetails) {
    this.authService.setClinicCode(selectedClinic.clinicCode);
    if (selectedClinic.tempClosure) {
      this.authService.closedMessage$.next(selectedClinic.tempClosureMessage);
      this.router.navigateByUrl('closed');
    } else {
      this.router.navigate(['/login'], {queryParams: {rxId: selectedClinic.rxClinicCode}});
      this.loading$.next(true);
    }
  }
}
