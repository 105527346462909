<div class="padding-10" fxLayout="column" fxLayoutGap="20px" fxLayoutGap.xs="10px">
  <h1 class="section-header">History for {{patient.name}}</h1>
  <div fxFlexFill fxLayout="row" fxLayoutAlign="center center">
    <button (click)="skip(-10)" [disabled]="page === 1" mat-icon-button>
      <mat-icon>chevron_left</mat-icon>
    </button>
    <button (click)="skip(10)" [disabled]="history?.length <10" mat-icon-button>
      <mat-icon>chevron_right</mat-icon>
    </button>
  </div>
  <div *ngFor="let visit of history" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center"
       fxLayoutGap.lt-xl="20px">
    <h3 class="title-header">{{getDate(visit.date)}}</h3>
    <mat-card class="detail-card" fxLayout="column">
      <mat-card-content fxLayout="column">
        <p class="visit-title">Visit at {{visit.location}} with {{visit.vet}}</p>
        <ng-container *ngIf="visit.history">
          <div fxLayout="column" fxLayoutAlign="space-between start">
            <p class="section-text">Notes</p>
            <p [innerHTML]="visit.history"></p>
            <mat-divider inset="true" style="margin-bottom: 10px" vertical="false"></mat-divider>
          </div>
        </ng-container>
        <ng-container *ngFor="let procedure of visit.procedures">
          <div fxLayout="column" fxLayoutAlign="space-between start" style="width:100%; margin-top: 20px">
            <div fxLayout="row" fxLayoutAlign="space-between start" style="width:100%">
              <p style="font-weight: bold" class="content-text">{{procedure.name}}<span class="content-text" *ngIf="showPrices && (!procedure.items || procedure.items.length===0)">
               - {{getPrice(procedure.totalPrice)}}</span><span *ngIf="procedure.items?.length>0"> items:</span></p>

            </div>
            <div *ngIf="procedure.items && procedure.items.length>0" fxLayout="column"
                 style="width:100%;" class="margin-left">
<!--              <p class="content-text no-margin" style="font-weight: bold">Items:</p>-->
              <ng-container *ngFor="let item of procedure.items">
                <div fxLayout="row" fxLayout.xs="column">
                  <div fxLayout="column" style="width: 100%">
                    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center" fxLayoutAlign.xs="start start" style="width:100%">
                      <p class="content-text no-margin">{{item.itemNumber ? item.itemNumber : procedure.itemNumber}}
                        x {{item.itemName}} <span *ngIf="item.injectable">by Injection</span>
                        <ng-container *ngIf="showPrices">
                          <span> - {{getPrice(item.price)}}</span>
                        </ng-container>
                      </p>
                      <div class="margin-bottom-xs full-width-xs"
                           fxLayout="row" fxLayoutAlign="start start" fxLayoutAlign.xs="center center"
                           fxLayoutGap="10px">
                        <button (click)="showLabelDialog(item)"
                                *ngIf="item.itemLabel && item.itemLabel.length>0"
                                color="primary" mat-button>Label
                        </button>
                        <button
                          *ngIf="item.type===1 && !item.injectable && historyPrescriptionsActive && item.itemNumber>0"
                          [queryParams]="{patientNumber:patient.patientNumber, itemName:item.itemName}"
                          [routerLink]="'/order'" color="accent" mat-button>
                          Reorder
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </ng-container>
      </mat-card-content>
    </mat-card>
  </div>
  <div fxFlexFill fxLayout="row" fxLayoutAlign="center center">
    <button (click)="skip(-10)" [disabled]="page === 1" mat-icon-button>
      <mat-icon>chevron_left</mat-icon>
    </button>
    <button (click)="skip(10)" [disabled]="history?.length <10" mat-icon-button>
      <mat-icon>chevron_right</mat-icon>
    </button>
  </div>
</div>
