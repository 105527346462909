import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {ClinicSelectorRoutingModule} from './clinic-selector-routing.module';
import {MapSearchComponent} from './map-search/map-search.component';

import {GoogleMap, GoogleMapsModule} from '@angular/google-maps';
import {ReactiveFormsModule} from '@angular/forms';
import {MatLegacyFormFieldModule as MatFormFieldModule} from '@angular/material/legacy-form-field';
import {MatLegacyAutocompleteModule as MatAutocompleteModule} from '@angular/material/legacy-autocomplete';
import {MatIconModule} from '@angular/material/icon';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MatLegacyInputModule as MatInputModule} from '@angular/material/legacy-input';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {ClinicSelectorComponent} from './clinic-selector/clinic-selector.component';
import {MatLegacyCardModule as MatCardModule} from '@angular/material/legacy-card';
import {MatLegacyProgressSpinnerModule as MatProgressSpinnerModule} from '@angular/material/legacy-progress-spinner';
import {CommonComponentsModule} from '../components/common-components/common-components.module';
import {GroomSelectorComponent} from './groom-selector/groom-selector.component';
import {MatStepperModule} from '@angular/material/stepper';
import {MatExpansionModule} from '@angular/material/expansion';


@NgModule({
  declarations: [MapSearchComponent, ClinicSelectorComponent, GroomSelectorComponent],
  imports: [
    CommonModule,
    GoogleMapsModule,
    ClinicSelectorRoutingModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatIconModule,
    FlexLayoutModule,
    MatInputModule,
    MatButtonModule,
    MatCardModule,
    MatProgressSpinnerModule,
    CommonComponentsModule,
    MatStepperModule,
    MatExpansionModule
  ],
  providers: [GoogleMap],
  exports: [
    MapSearchComponent,
    GroomSelectorComponent
  ]
})
export class ClinicSelectorModule {
}
