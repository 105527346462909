import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import * as Parse from 'parse';import { from } from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {AuthService} from '../auth/auth.service';
import {ParseErrorHandler} from '../parse-error-handler';
import {RegistrationParams} from '@appyvet/vetbooker-definitions/dist/client';
import {CreatedUserResponse} from '@appyvet/vetbooker-definitions/dist/register_user';
import {GetRegistrationSettingsParams, RegistrationSettings} from '@appyvet/vetbooker-definitions/dist/registrations';
@Injectable({
  providedIn: 'root'
})
export class RegistrationService {

  registrationSettings$ = new Subject<RegistrationSettings>();
  private user: {
    'attributes': {
      'email': string;
      'email_verified': boolean;
      'sub': string;
    },
    'id': string;
    'username': string;
  };

  constructor(private authService: AuthService) {
  }

  getRegistrationSettings(clinicCode: string): Observable<RegistrationSettings> {
    if (!clinicCode) {
      this.registrationSettings$.error('No clinic code passed');
    }
    const params: GetRegistrationSettingsParams = {
      clinicCode
    };
    Parse.Cloud.run('getRegistrationSettings', params).then((result: RegistrationSettings) => {
      this.registrationSettings$.next(result);
    }, error => {
      this.registrationSettings$.error(ParseErrorHandler.handleParseError(error));
    });
    return this.registrationSettings$;
  }

  registerAtClinic$(registrationItem: RegistrationParams): Observable<CreatedUserResponse> {
    if (this.user) {
      registrationItem.clientDetails.username = this.user.attributes.sub;
    }
    return from(Parse.Cloud.run('registerAtClinic', registrationItem))
      .pipe(map((result: CreatedUserResponse) => {
        if (result) {
          // Note - currently not taking account of clients who reach here without a cognito account if V4P
          if (environment.VETS_4_PETS) {
            this.authService.linkCognitoAccount(result.sessionToken, result.vbClinicCode);
          } else {
            return result;
          }
        }
      }));
  }
}
