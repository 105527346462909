import {RouterModule, Routes} from '@angular/router';
import {LoggedInGuard} from './auth/logged-in.guard';
import {LandingComponent} from './landing/landing.component';
import {BookerComponent} from './booker/booker.component';
import {BookingHistoryComponent} from './booking-history/booking-history.component';
import {NgModule} from '@angular/core';
import {ClientDetailsComponent} from './client-details/client-details/client-details.component';
import {ProductOrderComponent} from './product-order/product-order/product-order.component';
import {ErrorComponent} from './error/error.component';
import {SettingsComponent} from './settings/settings/settings.component';
import {FaqComponent} from './faq/faq.component';
import {LoggedInResolver} from './auth/logged-in-resolver';
import {TermsComponent} from './components/common-components/terms/terms.component';
import {LogoutComponent} from './logout/logout.component';
import {ClosedComponent} from './closed/closed.component';
import {PrivacyPolicyComponent} from "./privacy-policy/privacy-policy.component";
import {CookiePolicyComponent} from "./cookie-policy/cookie-policy.component";
import { PaymentsPageComponent } from './payments/payments-page/payments-page.component';

export const appRoutes: Routes = [
  {
    path: '', redirectTo: 'landing', pathMatch: 'full',
    data: {breadcrumbs: 'Home'},
  },
  {
    path: 'landing',
    component: LandingComponent,
    canActivate: [LoggedInGuard],
    data: {breadcrumbs: 'Home'},
    resolve: {clientPatientDetails: LoggedInResolver}
  },
  {
    path: 'order',
    component: ProductOrderComponent,
    canActivate: [LoggedInGuard],
    data: {breadcrumbs: 'Order'},
    resolve: {clientPatientDetails: LoggedInResolver}
  },
  {
    path: 'details',
    component: ClientDetailsComponent,
    canActivate: [LoggedInGuard],
    data: {breadcrumbs: 'Client Details'},
    resolve: {clientPatientDetails: LoggedInResolver}
  },
  // Need resolver on terms as well, in case refresh on terms screen.
  {
    path: 'terms', component: TermsComponent, resolve: {clientPatientDetails: LoggedInResolver},
    data: {breadcrumbs: 'Terms'},
    canActivate: [LoggedInGuard],
  },
  {
    path: 'logout', component: LogoutComponent, canActivate: [LoggedInGuard],
  },
  {
    path: 'booking',
    component: BookerComponent,
    canActivate: [LoggedInGuard],
    data: {breadcrumbs: 'Booking'},
    resolve: {clientPatientDetails: LoggedInResolver}
  },
  // This redirect is for old VB links to appts where link fragment was to "bookingHistory"
  {
    path: 'bookingHistory', redirectTo: 'history',
    data: {breadcrumbs: 'Appointment History'},
    resolve: {clientPatientDetails: LoggedInResolver}
  },
  {
    path: 'history',
    component: BookingHistoryComponent,
    canActivate: [LoggedInGuard],
    data: {breadcrumbs: 'Appointment History'},
    resolve: {clientPatientDetails: LoggedInResolver}
  },
  {
    path: 'payments', component: PaymentsPageComponent, canActivate: [LoggedInGuard],
    data: {breadcrumbs: 'My Payments'},
    resolve: {clientPatientDetails: LoggedInResolver}
  },
  {
    path: 'settings', component: SettingsComponent, canActivate: [LoggedInGuard],
    data: {breadcrumbs: 'Settings'},
    resolve: {clientPatientDetails: LoggedInResolver}
  },
  {
    path: 'faq', component: FaqComponent, canActivate: [LoggedInGuard],
    data: {breadcrumbs: 'FAQ'},
    resolve: {clientPatientDetails: LoggedInResolver}
  },
  {path: 'cookie-policy', component: CookiePolicyComponent,},
  {path: 'privacy-policy', component: PrivacyPolicyComponent,},
  {path: 'error', component: ErrorComponent},
  {path: 'closed', component: ClosedComponent},

  {path: '**', redirectTo: 'landing'},
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {})
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {
}
