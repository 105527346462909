import {Component, OnDestroy, OnInit} from '@angular/core';
import { PaymentService } from '../payments.service';
import {BehaviorSubject} from 'rxjs';
import {GoogleAnalyticsService} from "ngx-google-analytics";
import {environment} from "../../../environments/environment";
import {SessionService} from "../../services/session-service.service";

@Component({
  selector: 'deposit-status',
  templateUrl: './deposit-status.component.html',
  styleUrls: ['./deposit-status.component.scss']
})
export class DepositStatusComponent implements OnInit, OnDestroy {
  loading$ = new BehaviorSubject<boolean>(true);
  message$ = new BehaviorSubject<string | null>(null);
  depositStatus: { depositOnFile: boolean, depositId: string | null, depositAmount: number } | null = null;
  hasUpcomingAppointments = true;
  clinicName: string;

  constructor(private service: PaymentService, private sessionService: SessionService, public gaService: GoogleAnalyticsService,) {
    this.sessionService.clientPatientDetails$.subscribe(clientPatientDetails => {
      if (clientPatientDetails) {
        this.clinicName = clientPatientDetails.themeSettings.customName || 'VetBooker';
      }
    });
  }

  ngOnInit(): void {
    this.getDepositStatus();
    this.getUpcomingAppointmentStatus();
  }

  ngOnDestroy(): void {
  }

  async getDepositStatus(): Promise<void> {
    this.depositStatus = await this.service.getDepositStatus();
  }

  async getUpcomingAppointmentStatus(): Promise<void> {
    this.hasUpcomingAppointments = await this.service.getUpcomingAppointmentStatus();
    this.loading$.next(false);
  }

  async refundDeposit(): Promise<void> {
    this.message$.next(null);

    if (this.hasUpcomingAppointments) {
      this.message$.next('Sorry, we can\'t refund this deposit at the moment as you have a confirmed booking. You can cancel the booking and try again. If you are having problems you can call the salon. Thanks');
      return;
    }

    if (!this.depositStatus.depositId) {
      this.message$.next('Sorry, we can\'t refund this deposit as the payment wasn\'t made online. Please call the salon for more help during opening hours. Thanks');
      return;
    }

    this.loading$.next(true);
    const refunded = await this.service.refundDeposit();
    this.loading$.next(false);

    if (refunded) {
      this.gaService.event(
        'Refund deposit',
        'Deposits',
        this.clinicName
      );

      this.getDepositStatus();
      this.message$.next('Your deposit has been refunded.')
    }

    if (!refunded) {
      this.message$.next('There was an error processing your refund.')
    }
  }
}
