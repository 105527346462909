import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ProductTypeSelectorComponent} from './product-type-selector/product-type-selector.component';
import {FlexModule} from "@angular/flex-layout";
import {MatIconModule} from "@angular/material/icon";
import {ProductOrderComponent} from "./product-order/product-order.component";
import {MatLegacyCardModule as MatCardModule} from "@angular/material/legacy-card";
import {ReactiveFormsModule} from "@angular/forms";
import {MatLegacyFormFieldModule as MatFormFieldModule} from "@angular/material/legacy-form-field";
import {MatLegacyInputModule as MatInputModule} from "@angular/material/legacy-input";
import {CommonComponentsModule} from "../components/common-components/common-components.module";
import {MatLegacyButtonModule as MatButtonModule} from "@angular/material/legacy-button";
import {MatLegacyProgressSpinnerModule as MatProgressSpinnerModule} from "@angular/material/legacy-progress-spinner";


@NgModule({
  declarations: [ProductTypeSelectorComponent, ProductOrderComponent],
  imports: [
    CommonModule,
    FlexModule,
    MatIconModule,
    MatCardModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    CommonComponentsModule,
    MatButtonModule,
    MatProgressSpinnerModule
  ]
})
export class ProductOrderModule {
}
