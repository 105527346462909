<div fxLayout="column" style="min-width: 200px">
  <div fxLayout="row" fxLayoutAlign="space-between center" mat-dialog-title>
    <h1 class="mat-h1">TERMS AND CONDITIONS</h1>
    <button mat-icon-button color="primary" mat-dialog-close="true">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div mat-dialog-content>
    <p class="mat-body-2">This agreement sets out the terms and conditions in relation to your use of the services you can access on this site.</p>
    <p class="mat-body-2">Please read these terms carefully before you start to use the Website or register with us. By registering with us you indicate that you have read and understood and accept these terms, the Terms of Use, our Privacy and Cookies Policy link and that you agree to be bound, without limitation or qualification to all of those terms.</p>

    <p class="mat-body-2">Background</p>
    <ol type="A" class="mat-body-2">
      <li>We provide a portal for you to make direct appointments, prescription requests and new client registrations directly with your veterinary practice using our online platform www.vetbooker.com.</li>
      <li>At no time will a contract come into existence as between you and Us for the provision of services offered by our Service Providers. Any contract for goods and/or services formed will be solely between you and the Service Provider.</li>
    </ol>
    <p class="mat-body-2" style="font-weight: bold">1<span class="padding-left">1. Definitions and interpretation</span>
    </p>

    <p class="mat-body-2">1.1 In this Agreement, unless the context requires otherwise:</p>

    <div class="padding-left">
      <p class="mat-body-2">Force Majeure means an event or sequence of events beyond reasonable control preventing You or Us delaying from performing any obligations under this Agreement;</p>
      <p class="mat-body-2">Registration means your registration with us to allow you to access the Platform provided by us;</p>
      <p class="mat-body-2">Service Provider means the services of a veterinary practice;</p>
      <p class="mat-body-2">Site means the use of this site to obtain access to a Service Provider;</p>
      <p class="mat-body-2">Platform means the services provided using this Website;</p>
      <p class="mat-body-2">You means an individual consumer (i.e. you are not acting in the course of a trade or profession);
      </p>
      <p class="mat-body-2">We/Us/Our means Digital Practice trading as vetbooker.com, a company registered in England and Wales whose registered office is Wey Court West, Union Road, Farnham, England, GU9 7PT number 09156461. You can contact us in writing, or by email to support@vethelpdirect.com or by telephone on +44(0)2382 250 102.</p>
      <p class="mat-body-2">Website means www.vetbooker.com </p>
    </div>

    <p class="mat-body-2" style="font-weight: bold">2<span class="padding-left">About this Website and Platform</span>
    </p>

    <p class="mat-body-2">2.1 This website is operated by Digital Practice trading as vetbooker.com.</p>

    <p class="mat-body-2">2.2 By registering to use the Platform you can make
      direct appointments, prescription requests and submit new client
      registrations directly with your veterinary practice. </p>

    <p class="mat-body-2">2.3 We reserve the right to withdraw or amend the
      service provided on this Platform without notice. We shall not be liable
      if for any reason our Platform is not available at any time or for any
      period. From time to time, we may restrict access to some parts of the
      Platform, or the entire Website, if required. We aim to update the Website
      and Platform regularly and may change the content at any time. We may
      therefore have to suspend access to the Website or Platform, or close it
      if necessary. </p>

    <p class="mat-body-2">2.4 You are responsible for making all arrangements
      necessary for you to have access to the Platform and for ensuring that all
      persons who access the Website and Platform through your internet
      connection are aware of these Terms and that they comply with them. In
      order to use the Platform properly, you must have the necessary hardware,
      software and reliable internet access, none of which is our responsibility
      and is your entire responsibility. </p>

    <p class="mat-body-2">2.5 We cannot guarantee that the services on the
      Website will be uninterrupted or will achieve particular results, or the
      integrity or security of data contained therein, except to use its
      reasonable endeavor to rectify faults if they do occur. We operate the
      Website and our Platform in line with these Terms</p>


    <p class="mat-body-2" style="font-weight: bold">3<span class="padding-left">Information on the Website.
</span></p>

    <p class="mat-body-2">3.1 We use our best endeavors to keep the information
      that we place on the Website as accurate as possible but we are under no
      obligation to update the Site.</p>

    <p class="mat-body-2">3.2 We have no responsibly for information on the
      Website given by Service Providers and we have no liability or
      responsibility as to the accuracy, reliability or completeness of any
      information on this Website. </p>

    <p class="mat-body-2">3.3 We do verify the accuracy or truth or completeness
      of, and shall not be liable for and accept no responsibility for any
      information presented including, but not limited to, information presented
      by Service Providers, liability in connection with any connections made
      directly with Service Providers, or any circumstances that may arise from
      your use of the Website or this Platform. </p>

    <p class="mat-body-2" style="font-weight: bold">4<span class="padding-left">Links to Service Providers or other websites</span>
    </p>

    <p class="mat-body-2">4.1 Any links provided on the Website to other
      websites are not intended to provide an endorsement by us and we will have
      no liability or responsibility for the content contained therein or those
      websites whatsoever. </p>


    <p class="mat-body-2" style="font-weight: bold">5<span class="padding-left">Limit of relationship</span>
    </p>

    <p class="mat-body-2">5.1 We are not liable for any information given on the
      Website or directly to you by a Service Provider or for any service (or
      lack thereof) provided by a Service Provider. </p>

    <p class="mat-body-2">5.2 We do not provide, and are not in the business of
      providing veterinary services. </p>

    <p class="mat-body-2">5.3 By using this Website and/or registering to use the
      Platform, no veterinary/patient relationship is created between you and
      us. </p>

    <p class="mat-body-2">5.4 We make no representations or guarantees as to the
      professional qualifications of the Service Provider, their expertise,
      quality of work or any advice that is given to you by the Service
      Provider. We simply introduce you to a Service Provider, or provide direct
      access to a Service Provider using our Platform. We do not recommend or
      endorse of any the Service Providers. </p>

    <p class="mat-body-2">5.5 We have no control over the available of any
      particular Service Provider, their appointment schedule or the services
      that they provide and will not be liable for any act or omission of any
      kind of any Service Provider. </p>
    <p class="mat-body-2">5.6 All of our Service Providers have supplied us with
      an undertaking that they are registered as a veterinary service provider
      with the Royal College of Veterinary Surgeons but we do not verify that
      information and therefore we give no guarantee or warranty in that regard.
      You are solely responsible for your choice of Service Provider and we
      recommend that you make your own enquiries. </p>


    <p class="mat-body-2" style="font-weight: bold">6<span class="padding-left">Termination</span>
    </p>

    <p class="mat-body-2">6.1 We may suspend or permanently terminate your use
      of the Platform or Website if you breach any of the provisions of the
      terms set out in this agreement.

    </p>

    <p class="mat-body-2">6.2 You may terminate this Agreement at any time by
      contacting us using our contact details on our Contact Page and we will
      delete your registration details and all details that we hold on you from
      our database. </p>


    <p class="mat-body-2" style="font-weight: bold">7<span class="padding-left">Limit of liability</span>
    </p>

    <p class="mat-body-2">7.1 To the extent permitted by law, we have no
      liability whatsoever relating to the information on this Website, or the
      service provided by Service Providers. This does not affect your statutory
      rights under English Law. We do not limit or exclude our liability for
      death or personal injury caused by our negligence, fraudulent
      misrepresentation or any other liability which we cannot exclude by
      law. </p>

    <p class="mat-body-2">7.2 We shall not be liable for losses or damages
      (whether direct, indirect or consequential) whatsoever, whether in
      contract, tort (including negligence), or otherwise arising from this
      Website or the use of the Platform, or from any interruption or delay in
      accessing this Website or the Platform. </p>

    <p class="mat-body-2">7.3 By agreeing to these terms, you agree to fully
      indemnify, defend and hold Us harmless our officers, directors, employees,
      contractors, agents, suppliers, service providers, licensors and third
      party partners from and against all third party claims, costs, losses
      and/or liabilities relating or arising in connection with any illegal use
      of the Website or Platform, any breach of your representations or
      warranties or any violation of these terms by you or anyone to whom you
      allow access to the Website or the Platform using your internet connection
      and or registration details.</p>

    <p class="mat-body-2" style="font-weight: bold">8<span class="padding-left">Your Information</span>
    </p>

    <p class="mat-body-2">8.1 We process information about you in accordance
      with our Privacy and Cookies Policy</p>


    <p class="mat-body-2" style="font-weight: bold">9<span class="padding-left">Registration</span>
    </p>

    <p class="mat-body-2">9.1 By registering to use the Platform, you agree
      that: </p>
    <div class="padding-left">
      <p class="mat-body-2">9.1.1 you have read, understood and agree to all of
        the terms set out in this Agreement (and all other Terms referred to as
        if they were set out in full within this Agreement); </p>

      <p class="mat-body-2">9.1.2 you are over the age of 18 and that you have
        the legal capacity to enter into and be bound by these terms; </p>

      <p class="mat-body-2">9.1.3 you understand that we are only acting as a
        platform and that the contract for services to be provided by the
        Service Provider is only between you and the Service Provider;

      </p>

      <p class="mat-body-2">9.1.4 That an order of medication does not
        constitute a definitive order but is a request that is submitted to the
        Service Provider delivered who in turn will fulfil or deny the request
        as appropriate. </p>
      <p class="mat-body-2">9.1.5 the information that you provide to us is
        accurate; </p>
      <p class="mat-body-2">9.1.6 you will keep any username and password that
        we supply to you private and shall not allow another person to use those
        details to request services from a Service Provider via the Website</p>
    </div>
    <p class="mat-body-2">9.2 These terms do not affect your statutory
      rights. </p>
    <p class="mat-body-2">9.3 We reserve the right to refuse to accept your
      registration or to suspend or deny access to the Platform if you breach of
      the terms set out in this Agreement. </p>
    <p class="mat-body-2">9.4 There is no fee to register to use the
      Platform. </p>
    <p class="mat-body-2">9.5 Please note we will not groom any dog that is of a breed or type listed as a banned breed and/or requiring a certificate exemption under any dangerous dogs legislation applicable in the country where the relevant salon is located.</p>
    <p class="mat-body-2" style="font-weight: bold">10<span
      class="padding-left">Complaints</span></p>

    <p class="mat-body-2">10.1 We hope that you will be happy with the service
      provided within our Website and the Platform however, if you do have any
      complaints regarding the service we provide then please contact us using
      our contact page</p>


    <p class="mat-body-2">10.2 If you have any complaints in regards to the
      services provided by a Service Provider, and then please follow their
      internal complaints procedure. If you are unhappy with the outcome then
      please contact us link however, we do not make any warranties or
      representations as to what steps we will take in regards to such
      complaints. </p>


    <p class="mat-body-2" style="font-weight: bold">11<span
      class="padding-left">Force Majeure</span></p>

    <p class="mat-body-2">11.1 We will not be liable if we are prevented or
      delayed in performing our obligations under these terms due to Force
      Majeure. </p>


    <p class="mat-body-2" style="font-weight: bold">12<span
      class="padding-left">General</span></p>

    <p class="mat-body-2">12.1 Severance </p>
    <div class="padding-left">
      <p class="mat-body-2">If any provision of this Agreement (or part of any
        provision) is or becomes illegal, invalid or unenforceable, the
        legality, validity and enforceability of any other provision of this
        Agreement will not be affected. </p>
    </div>
    <p class="mat-body-2">12.2 Rights of third parties</p>

    <div class="padding-left">
      <p class="mat-body-2">No person other than You or Us a Party will have any
        right to enforce any of this Agreement’s provisions. </p>
    </div>


    <p class="mat-body-2" style="font-weight: bold">13<span
      class="padding-left">Governing law and jurisdiction</span></p>
    <p class="mat-body-2">13.1 This Agreement and any dispute or claim arising
      out of, or in connection with, it, its subject matter or formation
      (including non-contractual disputes or claims) will be governed by, and
      construed in accordance with, the laws of England and Wales.

    </p>

    <p class="mat-body-2">13.2 The Parties irrevocably agree that the courts of
      England and Wales will have exclusive jurisdiction to settle any dispute
      or claim arising out of, or in connection with, this Agreement, its
      subject matter or formation (including non-contractual disputes or
      claims).

    </p>
  </div>
</div>
