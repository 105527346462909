<form [formGroup]="addPetForm">
  <div class="content-text" fxLayout="column">
    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center"
         style="width:100%">
      <h2 *ngIf="!isRegistration"
          [ngClass]="isRegistration ?  'reg-header' : 'title-header'"
          fxFlex="15%" fxFlex.xs="100%">Name</h2>
      <mat-form-field appearance="outline" class="full-width-form-item">
        <mat-label>Pet Name</mat-label>
        <input formControlName="name" matInput required>
        <mat-error *ngFor="let validation of patientValidationMessages.name">
          <mat-error
            *ngIf="addPetForm.get('name').hasError(validation.type) && (addPetForm.get('name').dirty || addPetForm.get('name').touched)"
            class="error-message">{{validation.message}}</mat-error>
        </mat-error>
      </mat-form-field>
    </div>
    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center"
         fxLayoutAlign.xs="start start" style="width:100%">
      <div fxLayout="column" fxFlex="20%" fxFlex.xs="100%">
        <h2 [ngClass]="[isRegistration ?  'reg-header' : 'title-header',addPetForm.get('sex').touched && !selectedSex ?
      'error-text' : '']">Sex *</h2>
        <ng-container *ngIf=" addPetForm.get('sex').touched &&
      !selectedSex"><p class="error-text" style="font-size: 12px">Please
          confirm the sex of your pet</p>
        </ng-container>
      </div>
      <app-sex-selector [selectedSex]="selectedSex"
                        (selectedSexChange)="sexChanged($event)"
                        fxFlex></app-sex-selector>
    </div>
    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center"
         fxLayoutAlign.xs="start start" style="width:100%">
      <div fxLayout="column" fxFlex="20%" fxFlex.xs="100%">
        <h2 [ngClass]="[isRegistration ?  'reg-header' : 'title-header',
      addPetForm.get('neutered').touched && !neuteredDirty ?
      'error-text' : '']">Neutered *</h2>
        <ng-container *ngIf=" addPetForm.get('neutered').touched &&
      !neuteredDirty"><p class="error-text" style="font-size: 12px">Neutered
          status is
          required</p>
        </ng-container>
      </div>
      <app-neutered-selector [neutered]="neutered" [dirty]="!isNewPet ||
      neuteredDirty" (neuteredChange)="neuteredChanged($event)"
                             style="width:100%"></app-neutered-selector>
    </div>
    <ng-container *ngIf="isNewPet">
      <ng-container *ngIf="species.length === 1 && !isGroomRoom">
        <p class="content-text">At this time, you can only
          register {{species[0].name.toLowerCase()}}s. Please contact us if you
          want to register pets of another species</p>
      </ng-container>
      <ng-container *ngIf="species.length >1">
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center"
             style="width:100%">
          <h2 [ngClass]="isRegistration ?  'reg-header' : 'title-header' "
              fxFlex="20%" fxFlex.xs="100%">Species</h2>
          <app-species-selector
            (selectedSpeciesChange)="selectedSpeciesChanged($event)"
            [selectedSpecies]="selectedSpecies" [species]="species" fxFlex="100"
            style="width:100%"></app-species-selector>
        </div>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="isGroomRoom">
      <p class="content-text">Please select your dog’s breed from the dropdown list
        below. If your breed isn’t listed, please select the next
        closest breed available e.g. if your dog is German
        Shepherd crossed with a Labrador, select German
        Shepherd X from the list.</p>
    </ng-container>
    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center"
         style="width:100%">
      <h2 *ngIf="!isRegistration"
          [ngClass]="isRegistration ?  'reg-header' : 'title-header'"
          fxFlex="15%" fxFlex.xs="100%">Breed</h2>
      <mat-form-field appearance="outline" class="full-width-form-item">
        <mat-label>{{!selectedSpecies ? 'Breed - Must select a species first' : 'Start Typing Breed'}}</mat-label>
        <input [matAutocomplete]="breedAuto" formControlName="breed" matInput
               required type="text">
        <mat-autocomplete #breedAuto="matAutocomplete"
                          (optionSelected)="breedSelected($event.option.value)"
                          [displayWith]="displayFn">
          <mat-option *ngFor="let option of filteredBreeds | async"
                      [value]="option">{{option.name}}</mat-option>
        </mat-autocomplete>
        <mat-error *ngFor="let validation of patientValidationMessages.breed">
          <mat-error
            *ngIf="addPetForm.get('breed').hasError(validation.type) && (addPetForm.get('breed').dirty || addPetForm.get('breed').touched)"
            class="error-message">{{validation.message}}</mat-error>
        </mat-error>
      </mat-form-field>
    </div>
    <ng-container *ngIf="showColors && filteredColors | async as colors">
      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center"
           style="width:100%">
        <h2 *ngIf="!isRegistration"
            [ngClass]="isRegistration ?  'reg-header' : 'title-header'"
            fxFlex="15%" fxFlex.xs="100%">Colour</h2>
        <ng-container *ngIf="breedColors.length>10; else elseBlock">
        <mat-form-field appearance="outline" class="full-width-form-item">
          <mat-label>{{!selectedBreed ? 'Colour - Must select a breed first' :
            'Start Typing Colour'}}</mat-label>
          <input [matAutocomplete]="colorAuto" formControlName="color" matInput
                 type="text">
          <mat-autocomplete #colorAuto="matAutocomplete"[displayWith]="displayFn">
            <mat-option *ngFor="let option of colors"
                        [value]="option">{{option.name}}</mat-option>
          </mat-autocomplete>
          <mat-error *ngFor="let validation of patientValidationMessages.color">
            <mat-error
              *ngIf="addPetForm.get('color').hasError(validation.type) && (addPetForm.get('color').dirty || addPetForm.get('color').touched)"
              class="error-message">{{validation.message}}</mat-error>
          </mat-error>
        </mat-form-field>
        </ng-container>
        <ng-template #elseBlock>
          <mat-form-field appearance="outline" class="full-width-form-item">
            <mat-label>{{!selectedBreed ? 'Colour - Must select a breed first' :
              'Select Colour'}}</mat-label>
          <mat-select formControlName="color" [disabled]="!selectedBreed">
            <mat-option *ngFor="let option of colors"
                        [value]="option">{{option.name}}</mat-option>
          </mat-select>
          </mat-form-field>
        </ng-template>
      </div>
    </ng-container>
    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center"
         style="width:100%">
      <h2 *ngIf="!isRegistration"
          [ngClass]="isRegistration ?  'reg-header' : 'title-header'"
          fxFlex="15%"
          fxFlex.xs="100%">{{knownBirthdate ? 'Date of birth' : 'Age'}}</h2>
      <div class="full-width-form-item" fxLayout="row" fxLayout.xs="column"
           fxLayoutAlign="start center" fxLayoutGap.gt-xs="20px">
        <ng-container *ngIf="knownBirthdate; else unknownBirthday">
          <mat-form-field appearance="outline" class="full-width-xs"
                          color="primary">
            <mat-label>Date of Birth</mat-label>
            <input [matDatepicker]="dobDatePicker" [max]="today" [min]="minDob"
                   formControlName="dateOfBirth" matInput>
            <mat-datepicker-toggle [for]="dobDatePicker"
                                   matSuffix></mat-datepicker-toggle>
            <mat-datepicker #dobDatePicker></mat-datepicker>
            <mat-error
              *ngFor="let validation of patientValidationMessages.dateOfBirth">
              <mat-error
                *ngIf="addPetForm.get('dateOfBirth').hasError(validation.type) && (addPetForm.get('dateOfBirth').dirty || addPetForm.get('dateOfBirth').touched)"
                class="error-message">{{validation.message}}</mat-error>
            </mat-error>
          </mat-form-field>
        </ng-container>
        <ng-template #unknownBirthday>
          <mat-form-field appearance="outline">
            <mat-label>Years</mat-label>
            <input formControlName="years" matInput type="number">
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Months</mat-label>
            <input formControlName="months" matInput type="number">
          </mat-form-field>
        </ng-template>
        <mat-slide-toggle (change)="knownBirthdate=!knownBirthdate"
                          [checked]="!knownBirthdate" color="primary">I don't
          know an exact date of birth
        </mat-slide-toggle>
      </div>
    </div>
    <ng-container
      *ngIf="!isRegistration && (showInsured || showLastVac || showMicrochip)">
      <h2 class="title-header">Additional Information</h2></ng-container>
    <ng-container *ngIf="showInsured">
      <mat-form-field appearance="outline" class="full-width-form-item">
        <mat-label>Insured with</mat-label>
        <input formControlName="insurance" matInput>
      </mat-form-field>
    </ng-container>
    <ng-container *ngIf="showLastVac">
      <mat-form-field appearance="outline" class="full-width-form-item">
        <mat-label>Last vaccinated</mat-label>
        <input [matDatepicker]="vacDatepicker" formControlName="lastVac"
               matInput>
        <mat-datepicker-toggle [for]="vacDatepicker"
                               matSuffix></mat-datepicker-toggle>
        <mat-datepicker #vacDatepicker></mat-datepicker>
      </mat-form-field>
    </ng-container>
    <ng-container *ngIf="showMicrochip">
      <mat-form-field appearance="outline" class="full-width-form-item">
        <mat-label>Microchip number</mat-label>
        <input formControlName="microchip" matInput>
        <mat-error
          *ngFor="let validation of patientValidationMessages.microchip">
          <mat-error
            *ngIf="addPetForm.get('microchip').hasError(validation.type) && (addPetForm.get('microchip').dirty || addPetForm.get('microchip').touched)"
            class="error-message">{{validation.message}}</mat-error>
        </mat-error>
      </mat-form-field>
    </ng-container>
  </div>
  <ng-container *ngIf="isRegistration; else addBlock">
    <div fxLayout="row" fxLayoutAlign="space-between center"
         style="margin-top:10px">
      <button (click)="onCancel()" mat-stroked-button>back</button>
      <button (click)="save()" color="primary" mat-raised-button type="button">
        Next
      </button>
    </div>
  </ng-container>
  <ng-template #addBlock>
    <div fxLayout="row" fxLayoutAlign="end center">
      <button (click)="save()" [disabled]="!addPetForm.valid" color="primary"
              mat-raised-button
              type="button">{{editPatient ? 'Save pet details' : 'Add pet'}}</button>
    </div>
  </ng-template>
</form>
