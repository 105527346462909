import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {BehaviorSubject, Subject, Subscription, throwError} from 'rxjs';
import {AuthService} from '../../auth/auth.service';
import {LoginService} from '../../services/login-service.service';
import {environment} from '../../../environments/environment';
import {MaterialCssVarsService} from 'angular-material-css-vars';
import {DomSanitizer} from '@angular/platform-browser';
import {SignupService} from '../signup/signup.service';
import {MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig} from '@angular/material/legacy-dialog';
import {AlertDialogComponent} from '../../components/shared/alert-dialog/alert-dialog.component';
import {catchError} from 'rxjs/operators';
import {CookieService} from 'ngx-cookie-service';
import {APP_POPUP} from '../../../constants';
import {AppStoreDialogComponent} from '../../components/shared/app-store-dialog/app-store-dialog.component';
import {DeviceDetectorService} from 'ngx-device-detector';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';
import {ConfirmationDialogComponent} from '../../components/shared/confirmation-dialog/confirmation-dialog.component';
import {LoginClinicDetails} from '@appyvet/vetbooker-definitions/dist/clinic_details';
import {CreatedUserResponse} from '@appyvet/vetbooker-definitions/dist/register_user';
import {GoogleAnalyticsService} from "ngx-google-analytics";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  loginForm: UntypedFormGroup;
  resetForm: UntypedFormGroup;
  subscription: Subscription;
  landingLogo: string = environment.GROOM ? '/assets/petslogo@2x.png' : environment.VETS_4_PETS
    ? '/assets/v4p_logo_white.png'
    : '/assets/vetbooker_horizontal.png';
  clinicDetails: LoginClinicDetails;
  embedded = false;
  rightPanelActive$ = new BehaviorSubject<boolean>(false);
  goFullWidth$ = new BehaviorSubject<boolean>(false);
  loading$ = new BehaviorSubject<boolean>(true);
  loggingIn$ = new BehaviorSubject<boolean>(false);
  resetPasswordForm = false;
  passwordReset$ = new Subject<boolean>();
  verificationForm = false;
  accountValidationMessages = this.signupService.accountValidationMessages;
  private clinicCode: string;
  isVets4Pets = environment.VETS_4_PETS;
  private clearedPopup: string;
  private dialogSub: Subscription;
  showSocialSignIn = environment.VETS_4_PETS;
  private loginAttempts = 0;
  isGroomRoom = environment.GROOM;
  typeName = environment.GROOM ? 'Salon' : 'Clinic';
  signupTop: string;
  clinicDeactivated: boolean;
  isWhiteBackground: boolean;
  hideName = environment.CLINIC_CODE;

  constructor(public authService: AuthService, private router: Router, private loginService: LoginService,
              private activatedRoute: ActivatedRoute, private materialCssVarsService: MaterialCssVarsService,
              private sanitizer: DomSanitizer, public signupService: SignupService, private dialog: MatDialog,
              private cookieService: CookieService, private deviceService: DeviceDetectorService,
              private matSnackBar: MatSnackBar, private gaService: GoogleAnalyticsService) {
  }

  showVerification(error) {
    this.rightPanelActive$.next(true);
    const parsedUser: CreatedUserResponse = JSON.parse(error.message);
    this.signupService.configureVerification(parsedUser);
    this.signupService.goToVerification(parsedUser.smsVerification);
  }

  showCognitoVerification() {
    this.rightPanelActive$.next(true);
    this.signupService.configureVerification({
      code: 0,
      repeatVerification: false,
      success: '',
      email: this.loginForm.value.email, mobile: null
    });
    this.signupService.goToCognitoVerification();
  }

  showAdditionalMatching() {
    this.rightPanelActive$.next(true);
    this.signupService.goToAdditionalMatch();
  }

  showSmsVerification() {
    this.rightPanelActive$.next(true);
    this.signupService.goToVerification(true);
  }

  showErrorDialog(error: string) {
    const dialogOptions: MatDialogConfig = new MatDialogConfig();
    dialogOptions.data = {
      title: 'Login Error',
      message: error,
      confirmationText: 'Got it'
    };
    this.dialog.open(AlertDialogComponent, dialogOptions);
  }

  onSubmit() {
    if (environment.VETS_4_PETS) {
      this.authService.signInWithCognito(this.loginForm.value.email, this.loginForm.value.password,
        this.clinicDetails);
      this.authService.cognitoResult$
        .pipe(catchError(error => {
          if (error) {
            if (error.code === 'UserNotConfirmedException') {
              this.showCognitoVerification();
            } else {
              this.loginAttempts++;
              if (this.loginAttempts < 3) {
                this.showErrorDialog(error.message);
              } else {
                const dialogOptions: MatDialogConfig = new MatDialogConfig();
                dialogOptions.data = {
                  title: 'Forgotten your password?',
                  message: 'Unfortunately your email and password have not been recognised. Would you like to reset' +
                    ' your password? '
                };
                const dialogRef = this.dialog.open(ConfirmationDialogComponent, dialogOptions);
                dialogRef.afterClosed().subscribe(result => {
                  if (result) {
                    this.resetPasswordForm = true;
                    this.loginAttempts = 0;
                  }
                });
              }
            }
          }
          return throwError(error);
        })).subscribe(result => {
        if (result) {
          if (result.matched && result.verified) {
            this.authService.loginPageLoading$.next(true);
            this.router.navigateByUrl('landing');
          } else if (result.matched) {
            this.showSmsVerification();
          } else {
            this.showAdditionalMatching();
          }
        }
      });
    } else {
      this.authService.login(this.loginForm.value.email, this.loginForm.value.password).subscribe(loggedIn => {
        if (loggedIn) {
          this.authService.loginPageLoading$.next(true);
          this.router.navigateByUrl('landing');
          this.gaService.event(
            'Existing User Login',
            'Login',
            this.clinicDetails?.clinicName,
          );
        }
      }, error => {
        //Identify if error is JSON due to parsing from Parse library and error codes
        if (error.message && error.message.indexOf('{') !== -1) {
          const parsedError = JSON.parse(error.message);
          if (parsedError.repeatVerification) {
            this.showVerification(error);
          }
        } else {
          this.showErrorDialog(error.message);
        }
      });
    }
  }

  getBackgroundStyle() {
    if (!environment.GROOM) {
      return this.sanitizer.bypassSecurityTrustStyle(
        'url(' + this.landingLogo + ') center center/contain no-repeat');
    } else {
      return null;
    }
  }

  signInPressed() {
    this.rightPanelActive$.next(false);
    if (this.authService.screenWidth$.getValue() < 400) {
      document.querySelector('mat-sidenav-content').scrollTo({top: 0, behavior: 'smooth'});
    }
    this.gaService.event(
      'Login Selected',
      'Signup',
      this.clinicDetails?.clinicName,
    );
  }

  signUpPressed() {
    this.rightPanelActive$.next(true);
    if (this.authService.screenWidth$.getValue() < 400) {
      document.querySelector('mat-sidenav-content').scrollTo({top: 250, behavior: 'smooth'});
    }
    this.gaService.event(
      'Signup Selected',
      'Signup',
      this.clinicDetails?.clinicName,
    );
  }

  resetPassword() {
    this.loading$.next(true);
    this.authService.resetPassword(this.resetForm.get('email').value).subscribe(() => {
      this.loading$.next(false);
      this.passwordReset$.next(true);
      this.resetPasswordForm = false;
      const alertDialogConfig = new MatDialogConfig();
      alertDialogConfig.data = {
        confirmationText: 'Got it!',
        title: 'Customer Notice',
        message: "If the details entered match our records a password reset message will be sent to your email. Please click the link in that message to reset your password. If you do not receive the password reset message within 15 minutes, please check your details are correct, spam folder or other filtering tools."
      };
      this.dialog.open(AlertDialogComponent, alertDialogConfig);
      this.gaService.event(
        'Password Reset Requested',
          'Password Reminder',
        this.clinicDetails?.clinicName,
      );
    });
  }

  ngOnInit() {
    this.signupService.goFullWidth$.subscribe(goFullWith => {
      this.goFullWidth$.next(goFullWith);
    });
    this.loginForm = new UntypedFormGroup({
      email: new UntypedFormControl(null, [
        Validators.required,
        Validators.email
      ]),
      password: new UntypedFormControl(null, [Validators.required])
    });
    this.resetForm = new UntypedFormGroup({
      email: new UntypedFormControl(null, [
        Validators.required,
        Validators.email
      ]),
    });
    this.activatedRoute.data.subscribe((data) => {
      this.clinicDetails = data.clinicDetails;
      let whiteBackground =  environment.GROOM ? true : this.clinicDetails.themeSettings?.isWhiteBackground;
      this.isWhiteBackground =whiteBackground
      this.authService.setWhiteBackground(whiteBackground);
      this.authService.setCanRegister(this.clinicDetails.acceptingRegistrations);
      this.authService.setCanBook(this.clinicDetails.acceptingRegistrations);
      if (this.clinicDetails) {
        this.loading$.next(false);
        if (this.clinicDetails.themeSettings?.landingLogo && !this.clinicDetails.isGroomRoom) {
          this.landingLogo = this.clinicDetails?.themeSettings.landingLogo;
        }
        if (!this.clinicDetails.isGroomRoom && this.clinicDetails.themeSettings) {
          const primaryHex = '#3F51B5';
          const accentHex = '#E91E63';
          this.materialCssVarsService.setPrimaryColor(
            this.clinicDetails.themeSettings.primaryColor || primaryHex);
          this.materialCssVarsService.setAccentColor(
            this.clinicDetails.themeSettings.accentColor || accentHex);
        }
        if (this.clinicDetails.clinicDeactivated) {
          this.clinicDeactivated = true;
          this.dialog.open(AlertDialogComponent, {
            data: {
              html: '<h2 class="mat-h2">' + this.typeName + ' Closed</h2><p class="mat-body-1">This ' +
                this.typeName.toLowerCase() + ' has' +
                ' now closed. Please visit <a' +
                ' href="https://thegroomroom.co.uk/selector">https://thegroomroom.co.uk</a> and' +
                ' select an alternative ' + this.typeName.toLowerCase() + '. If you' +
                ' are already registered and are trying to log in, then you may do so in order to transfer to a' +
                ' different ' + this.typeName.toLowerCase() + '.</p>'
            }
          });
        }
      }
    });
    this.activatedRoute.queryParams.subscribe(params => {
      if (params.clinicCode) {
        this.clinicCode = params.clinicCode;
        this.authService.setOriginatingClinicCode(this.clinicCode);
      }
      if (params.errorCode === '410') {
        this.dialog.open(AlertDialogComponent,
          {data: {message: 'We have been unable to access your account, speak to your practice to find out how to gain access again. '}});
      }
      if (params.error_description) {
        let formattedError = params.error_description;
        // Error back from cognito comes as plain text prefaces with some words but ending in "error" so remove
        // for display to client
        if (params.error_description.split('error ').length > 1) {
          formattedError = params.error_description.split('error ')[1];
        }
        this.dialog.open(AlertDialogComponent, {data: {message: formattedError}});
      }
      if (params.embedded) {
        this.embedded = true;
      }
      if (params.unverified) {
        this.signupService.goToCognitoVerification();
      }
      if (params.additionalMatch) {
        this.signupService.goToAdditionalMatch();
      }
      if (params.signedOut) {
        this.signupService.resetLoginPage();
      }
    });
    if (this.isVets4Pets && !this.deviceService.isDesktop()) {
      this.clearedPopup = this.cookieService.get(APP_POPUP);
      if (!this.clearedPopup || this.clearedPopup !== 'true') {
        const dialogRef = this.dialog.open(AppStoreDialogComponent);
        this.dialogSub = dialogRef.afterClosed().subscribe((() => {
          this.cookieService.set(APP_POPUP, 'true');
        }));
      }
    }
    this.authService.screenWidth$.subscribe(width => {
      if (width < 450 && !environment.VETS_4_PETS) {
        this.signupTop = '-280px';
      }
    });
  }

  ngOnDestroy() {
    this.dialogSub?.unsubscribe();
    this.loginService.clinicDetails$?.unsubscribe();
  }

  changeClinic() {
    this.authService.resetClinicCode(true);
    this.gaService.event(
      'Change ' + this.isGroomRoom ? 'Salon' : 'Clinic',
      'Signup',
      this.clinicDetails?.clinicName,
    );
  }
}
