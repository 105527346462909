import {Component, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";

@Component({
  selector: 'app-groom-booking-terms-dialog',
  templateUrl: './groom-booking-terms-dialog.component.html',
  styleUrls: ['./groom-booking-terms-dialog.component.scss']
})
export class GroomBookingTermsDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<GroomBookingTermsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data) {
  }

  ngOnInit(): void {
  }

}
