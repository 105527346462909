<mat-card [routerLink]="['/pets/'+patient.patientNumber]" style="width:280px">
  <mat-card-title class="title-header">{{patient.name}}</mat-card-title>
  <mat-card-content>
    <div class="detail-container" fxLayout="column">
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <p class="title-row">Species:</p>
        <p
          class="title-content">{{patient.breedItem?.speciesItem.name ||
        'Unknown'}}</p>
      </div>
      <div fxLayout="row" fxLayoutAlign="space-between center"
           fxLayoutGap="10px">
        <p class="title-row">Breed:</p>
        <p class="title-content">{{patient.breedItem?.name ||
        'Unknown'}}</p>
      </div>
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <p class="title-row">Age:</p>
        <p class="title-content">{{age}}</p>
      </div>
      <ng-container *ngIf="patient.color">
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <p class="title-row">Colour:</p>
          <p class="title-content">{{patient.color}}</p>
        </div>
      </ng-container>
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <p class="title-row">Sex:</p>
        <p
          class="title-content">{{patient.sex}} {{patient.desexed ? 'Neutered' : 'Entire'}}</p>
      </div>
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <p class="title-row">Last Visit:</p>
        <p
          class="title-content">{{patient.extraInfo?.lastVisitDate ? (patient.extraInfo?.lastVisitDate | dfnsFormat : 'dd/MM/yyyy') : "Never"}}</p>
      </div>
      <ng-container *ngIf="!hideReminders">
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <p class="title-row">Overdue Reminders:</p>
          <p class="title-content">{{hasOverdueReminders(patient)}}</p>
        </div>
      </ng-container>
    </div>
  </mat-card-content>
</mat-card>
