import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PASSWORD} from '../../registration/additional-registration-info/additional-registration-info.component';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {PasswordMatchErrorMatcher} from '../../components/password-matching-validator';
import {environment} from '../../../environments/environment';
import {UpdatePasswordParams} from '@appyvet/vetbooker-definitions/dist/register_user';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  matcher = new PasswordMatchErrorMatcher();
  private passwordLength = 10;
  passwordValidationMessages = {
    password: [
      {type: 'required', message: 'Password is required'},
      {type: 'minlength', message: 'Password must be at least 10 characters long'},
      {type: 'mismatch', message: 'Passwords must match'}, {
        type: 'pattern',
        message: environment.VETS_4_PETS ? 'Min 10 characters with at least 1 capital letter and 1 number' : ''
      },
    ],
    confirmPassword: [
      {type: 'required', message: 'Confirmed Password is required'},
      {type: 'minlength', message: 'Password must be at least 10 characters long'}, {
        type: 'pattern',
        message: 'Min 10 characters with at least 1 capital letter and 1 number',
      },
    ],
  };
  private passwordRegexp: RegExp = new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{10,}$');
  PASSWORD = PASSWORD;
  passwordForm: UntypedFormGroup;
  @Input() loading: boolean;
  @Output() passwordSaved = new EventEmitter<UpdatePasswordParams>();

  constructor() {
  }

  checkPasswords(group: UntypedFormGroup) { // here we have the 'passwords' group
    const pass = group.get(PASSWORD).value;
    const confirmPass = group.get('confirmPassword').value;
    return pass === confirmPass ? null : {mismatch: true};
  }

  ngOnInit(): void {
    const passwordValidators = [Validators.required];
    passwordValidators.push(Validators.pattern(this.passwordRegexp));
    passwordValidators.push(Validators.minLength(this.passwordLength));
    this.passwordForm = new UntypedFormGroup({
        Password: new UntypedFormControl('', passwordValidators),
        confirmPassword: new UntypedFormControl('', passwordValidators),
        oldPassword: new UntypedFormControl('', [Validators.required, Validators.minLength(this.passwordLength)])
      },
      {validators: this.checkPasswords});
  }

  savePassword() {
    this.passwordSaved.emit(
      {oldPassword: this.passwordForm.get('oldPassword').value, newPassword: this.passwordForm.get('Password').value});
  }
}
