import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';

@Component({
  selector: 'splash-screen-dialog',
  templateUrl: './splash-screen-dialog.component.html',
  styleUrls: ['./splash-screen-dialog.component.scss']
})
export class SplashScreenDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<SplashScreenDialogComponent>,
              @Inject(MAT_DIALOG_DATA) data) {
  }

  @ViewChild('splashVideo', {static: true}) myVideoRef: ElementRef;

  ngAfterViewInit() {
    const myVideo: HTMLVideoElement = this.myVideoRef.nativeElement;
    myVideo.addEventListener('loadedmetadata', () => {
      myVideo.muted = true;
      myVideo.play();
    });
  }

  ngOnInit(): void {
  }

}
