import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {BehaviorSubject, Subscription} from 'rxjs';
import {SignupService} from '../signup.service';
import {ActivatedRoute} from '@angular/router';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {ParseErrorHandler} from '../../../parse-error-handler';
import {AlertDialogComponent} from '../../../components/shared/alert-dialog/alert-dialog.component';
import {TermsDialogComponent} from '../../../components/shared/terms-dialog/terms-dialog.component';
import {LoginClinicDetails} from '@appyvet/vetbooker-definitions/dist/clinic_details';
import {CreatedUserResponse} from '@appyvet/vetbooker-definitions/dist/register_user';

@Component({
  selector: 'app-additional-match',
  templateUrl: './additional-match.component.html',
  styleUrls: ['./additional-match.component.scss']
})
export class AdditionalMatchComponent implements OnInit, OnDestroy {

  matchingForm: UntypedFormGroup;
  accountValidationMessages = this.signupService.accountValidationMessages;
  isGroomRoom: boolean;
  clinicDetails$ = new BehaviorSubject<LoginClinicDetails>(null);
  currentUser$ = new BehaviorSubject<CreatedUserResponse>(null);
  loading$: BehaviorSubject<boolean>;
  error$ = new BehaviorSubject<string>(null);
  consent: boolean;
  private clinicCode: string;
  private clinicName: string;
  private screenWidth: number;
  private routeSub: Subscription;
  private matchUserErrorSub: Subscription;
  private signupSub: Subscription;

  constructor(private signupService: SignupService, private activatedRoute: ActivatedRoute, private dialog: MatDialog) {
  }

  ngOnDestroy() {
    this.matchUserErrorSub?.unsubscribe();
    this.routeSub?.unsubscribe();
    this.signupSub?.unsubscribe();
  }

  showTerms() {
    this.dialog.open(TermsDialogComponent);
  }

  ngOnInit(): void {
    this.screenWidth = window.innerWidth;
    window.onresize = () => {
      this.screenWidth = window.innerWidth;
    };
    this.loading$ = this.signupService.loading$;
    this.currentUser$ = this.signupService.foundUser$;
    this.matchingForm = new UntypedFormGroup({
      mobile: new UntypedFormControl('',
        [Validators.required, Validators.pattern('(\\+44\\s?7\\d{3}|\\(?07\\d{3}\\)?)\\s?\\d{3}\\s?\\d{3}$')]),
      postcode: new UntypedFormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(8),
                                     Validators.pattern('^[A-Za-z0-9 _\-]*[A-Za-z0-9\-][A-Za-z0-9 _\-]*$')])
    });
    this.routeSub = this.activatedRoute.data
      .subscribe((data: { clinicDetails: LoginClinicDetails }) => {
        this.clinicName = data.clinicDetails.clinicName;
        this.clinicCode = data.clinicDetails.clinicCode;
        this.clinicDetails$.next(data.clinicDetails);
      });
    this.matchUserErrorSub = this.signupService.matchUserError$.subscribe(error => {
      if (error) {
        const parsedError = ParseErrorHandler.handleParseError(error);
        this.dialog.open(AlertDialogComponent, {
          maxWidth: this.screenWidth > 400 ? '80vw' : '100vw',
          data: {
            title: 'Error Registering',
            message: parsedError.message,
            confirmationText: 'Got it'
          }
        });
        this.loading$.next(false);
      }
    });
  }

  match() {
    this.error$.next(null);
    this.signupService.cognitoMatchUser(this.matchingForm.get('mobile').value, this.matchingForm.get('postcode').value);
  }

  back() {
    this.signupService.logOut();
  }
}
