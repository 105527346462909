import {Component, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {Payment} from "../../models/payment.model";
import {PaymentService} from "../payments.service";

@Component({
  selector: 'app-payments-page',
  templateUrl: './payments-page.component.html',
  styleUrls: ['./payments-page.component.scss']
})
export class PaymentsPageComponent implements OnInit {
  payments$ = new BehaviorSubject<Payment[] | null>(null);

  constructor(private service: PaymentService) {}

  ngOnInit(): void {
    this.getPayments();
  }

  async getPayments(): Promise<void> {
    const payments = await this.service.getPaymentHistory();
    this.payments$.next(payments);
  }
}
