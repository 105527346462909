<div class="padding-10" fxLayout="column" fxLayoutGap="20px" fxLayoutGap.xs="10px">
  <!--  <div class="primary-background card-title" fxLayout="row">-->
  <h1 class="section-header  accent-text-color">
    My details
  </h1>
  <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center"
       fxLayoutAlign.xs="start start" fxLayoutGap.lt-xl="20px">
    <h3 class="title-header">Name</h3>
    <mat-card class="contact-card" fxLayout="row"
              fxLayoutAlign="space-between center">
      <p *ngIf="!nameUpdate" class="content-text">{{formattedName}}</p>
      <ng-container *ngIf="nameUpdate">
        <div *ngIf="!(saving$ | async); else loadingBlock" fxFlex="100">
          <app-name-entry (nameUpdateChange)="nameUpdate = !nameUpdate"
                          (onSave)="onSaveName($event)" *ngIf="nameUpdate"
                          [client]="clientService.client$ | async"
                          fxFlex></app-name-entry>
        </div>
      </ng-container>
      <ng-template #loadingBlock>
        <div fxLayout="row" fxLayoutAlign="center center">
          <mat-progress-spinner color="primary" diameter="40"
                                mode="indeterminate"></mat-progress-spinner>
          <p class="content-text" style="margin:20px">Saving</p></div>
      </ng-template>

      <div *ngIf="canEditClient && !nameUpdate">
        <button (click)="nameUpdate=true" color="primary" mat-stroked-button>
          Edit
        </button>
      </div>
    </mat-card>
  </div>
  <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center"
       fxLayoutAlign.xs="start start" fxLayoutGap.lt-xl="20px">
    <h3 class="title-header">Address</h3>
    <mat-card class="contact-card" fxLayout="column">
      <div *ngIf="!addressUpdate" fxLayout="row" u
           fxLayoutAlign="space-between center" fxLayoutGap="10px">
        <p class="content-text">{{formattedAddress}}</p>
        <div *ngIf="canEditClient">
          <button (click)="addressUpdate = true;" color="primary"
                  mat-stroked-button>Edit
          </button>
        </div>
      </div>
      <app-address-lookup (addressCancel)="onAddressCancel()"
                          (addressSave)="onSaveAddress($event)"
                          [showAddressForm]="showAddressForm"
                          [address]="currentAddress"
                          [countryCode]="country.code"
                          [requirements]="addressRequirements"
                          *ngIf="addressUpdate"
                          [clinicCode]="clinicCode"></app-address-lookup>
    </mat-card>
  </div>
  <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start" fxLayoutGap.lt-xl="20px">
    <h3 class="title-header">Contacts</h3>
    <app-contact-entries class="full-width-form-item"></app-contact-entries>
  </div>
</div>
