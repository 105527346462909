import {NgModule} from '@angular/core';
import { PaymentsPageComponent } from './payments-page/payments-page.component';
import { CommonModule } from '@angular/common';
import { CommonComponentsModule } from '../components/common-components/common-components.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import {MatLegacyCardModule as MatCardModule} from '@angular/material/legacy-card';
import { DepositStatusComponent } from './deposit-status/deposit-status.component';
import { PaymentHistoryListComponent } from './payment-history-list/payment-history-list.component';
import { MatIconModule } from '@angular/material/icon';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatTableModule} from '@angular/material/table';
import { PipesModule } from '../pipes/pipes.module';
import { DateFnsModule } from 'ngx-date-fns';
import {
  RefundDepositOnCancelDialogComponent
} from "./refund-deposit-on-cancel-dialog/refund-deposit-on-cancel-dialog.component";
import {MatDialogModule} from "@angular/material/dialog";
import {MatLegacyProgressSpinnerModule as MatProgressSpinnerModule} from '@angular/material/legacy-progress-spinner';
import {MatToolbarModule} from "@angular/material/toolbar";

@NgModule({
    declarations: [
        PaymentsPageComponent,
        DepositStatusComponent,
        PaymentHistoryListComponent,
        RefundDepositOnCancelDialogComponent,
    ],
    imports: [
        CommonModule,
        CommonComponentsModule,
        FlexLayoutModule,
        MatCardModule,
        MatIconModule,
        MatButtonModule,
        MatTableModule,
        PipesModule,
        DateFnsModule.forRoot(),
        MatDialogModule,
        MatProgressSpinnerModule,
        MatToolbarModule
    ]
})
export class PaymentsModule {
}
