import { Component, OnInit } from '@angular/core';
import { Payment } from '../../models/payment.model';
import { PaymentService } from '../payments.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
  selector: 'payment-history-list',
  templateUrl: './payment-history-list.component.html',
  styleUrls: ['./payment-history-list.component.scss']
})
export class PaymentHistoryListComponent implements OnInit {
  payments$ = new BehaviorSubject<Payment[] | null>(null);
  columns = ['date', 'amount', 'description'];

  constructor(private service: PaymentService) {}

  ngOnInit(): void {
    this.getPayments();
  }

  async getPayments(): Promise<void> {
    const payments = await this.service.getPaymentHistory();
    this.payments$.next(payments);
  }
}
