import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {LoginRoutingModule} from './login-routing.module';
import {SigninComponent} from './signin/signin.component';
import {MatchingComponent} from './signup/matching/matching.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatLegacyFormFieldModule as MatFormFieldModule} from '@angular/material/legacy-form-field';
import {FlexLayoutModule, FlexModule} from '@angular/flex-layout';
import {MatLegacyProgressSpinnerModule as MatProgressSpinnerModule} from '@angular/material/legacy-progress-spinner';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatLegacyInputModule as MatInputModule} from '@angular/material/legacy-input';
import {EmailLinkSignupComponent} from './signup/password/email-link-signup.component';
import {SignupComponent} from './signup/signup/signup.component';
import {SmsVerificationComponent} from './signup/sms-verification/sms-verification.component';
import {EmailVerificationComponent} from './signup/email-verification/email-verification.component';
import {LoginResolver} from './login-resolver';
import {MatLegacyCheckboxModule as MatCheckboxModule} from '@angular/material/legacy-checkbox';
import {EmailLinkVerificationComponent} from './email-link-verification/email-link-verification.component';
import {ResetPasswordComponent} from './reset-password/reset-password.component';
import {MatLegacyCardModule as MatCardModule} from '@angular/material/legacy-card';
import {CognitoVerificationComponent} from './signup/cognito-verification/cognito-verification.component';
import {AdditionalMatchComponent} from './signup/additional-match/additional-match.component';
import {SocialLoginComponent} from './social-login/social-login.component';
import {MatDividerModule} from '@angular/material/divider';
import {ClinicCodeMismatchDialogComponent} from './clinic-code-mismatch-dialog/clinic-code-mismatch-dialog.component';
import {MatLegacyDialogModule as MatDialogModule} from '@angular/material/legacy-dialog';
import {MatIconModule} from '@angular/material/icon';


@NgModule({
  declarations: [SigninComponent, MatchingComponent, EmailLinkSignupComponent, SmsVerificationComponent,
                 SignupComponent, SmsVerificationComponent, EmailVerificationComponent,
                 EmailLinkVerificationComponent, ResetPasswordComponent, CognitoVerificationComponent,
                 AdditionalMatchComponent, SocialLoginComponent, ClinicCodeMismatchDialogComponent],
  imports: [
    CommonModule,
    LoginRoutingModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    FlexModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatInputModule,
    FlexLayoutModule,
    MatCheckboxModule,
    FormsModule,
    MatCardModule,
    MatDividerModule,
    MatDialogModule,
    MatIconModule
  ],
  exports: [
    SocialLoginComponent
  ],
  providers: [LoginResolver]
})
export class LoginModule {
}
