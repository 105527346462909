<div class="padding-10 primary-background" fxFill fxLayout="row"
     fxLayoutAlign="space-between center" style="min-height: 65px!important">
  <h1 class="mat-h1" style="color:white">Grooming Guide</h1>
  <button [mat-dialog-close]="true" aria-label="close" mat-button>
    <mat-icon>close</mat-icon>
  </button>
</div>
<mat-dialog-content>
  <div fxLayout="column">
    <p class="mat-subheading-1 no-margin primary-background"
       style="padding-left:10px; color:white">
      If you’re unsure about your dog’s coat type
      then please check with your local salon before selecting your groom</p>
    <div fxLayout="row wrap" fxLayoutAlign="center center"
         style="overflow: hidden">
      <mat-card class="help-card" fxFlex="100" fxFlex.gt-xs="33.333">
        <mat-card-content class="no-padding">
          <div fxFill fxLayout="column">
            <mat-toolbar class="card-title-small" color="primary"
                         fxLayout="row">
              <h1 class="mat-h1 " style="margin-bottom: 0">
                Smooth/Short </h1>
            </mat-toolbar>
            <div flex-grow><img src="/assets/groomroom/short_smooth_breeds.png"
                                width="100%">
            </div>
            <div class="padding-10" fxLayout="row">
              <div fxLayout="column" fxLayoutAlign.xs="start start">
                <h2 class=" mat-h2">
                  We Recommend </h2>

                <ul>
                  <li>Full Groom for Smooth Haired Breeds every 8-10 weeks</li>
                  <li>Bath, Brush & Blow dry every 4-6 weeks</li>
                  <li>deShedding treatment</li>
                </ul>
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
      <mat-card class="help-card" fxFlex="100" fxFlex.gt-xs="33.333">
        <mat-card-content class="no-padding">
          <div flex fxLayout="column">
            <mat-toolbar class="card-title-small" color="primary" fxLayout="row"
                         style="border-right: black;">
              <h1 class="mat-h1 " style="margin-bottom: 0">
                Long/Silk/Wool </h1>
            </mat-toolbar>
            <div fxFlex><img src="assets/groomroom/long_silk_wool_breeds.png"
                             width="100%">
            </div>
            <div class="padding-10" fxLayout="row">
              <div fxLayout="column" fxLayoutAlign.xs="start start">
                <h2 class="mat-h2 ">
                  We Recommend </h2>

                <ul>
                  <li>Full Groom for Long Haired Breeds every 4-8 weeks</li>
                  <li>Bath, Brush & Blow Dry every 3-4 weeks</li>
                </ul>
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
      <mat-card class="help-card" fxFlex="100" fxFlex.gt-xs="33.333">
        <mat-card-content class="no-padding">
          <div flex fxLayout="column">
            <mat-toolbar class="card-title-small" color="primary"
                         fxLayout="row">
              <h1 class="mat-h1 " style="margin-bottom: 0">
                Wire/Double </h1>
            </mat-toolbar>
            <div flex-grow><img src="assets/groomroom/wire_double_breeds.png"
                                width="100%">
            </div>
            <div class="padding-10" fxLayout="row">
              <div fxLayout="column" fxLayoutAlign.xs="start start">
                <h2 class="mat-h2 ">
                  We Recommend </h2>

                <ul>
                  <li>Full Groom for Long Haired Breeds every 6-8 weeks</li>
                  <li>Bath, Brush & Blow Dry every 4-6 weeks</li>
                  <li>Handstripping where breed requires</li>
                  <li>deShedding treatment</li>
                </ul>
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <div fxFill fxLayout="row" fxLayoutAlign="center center">
      <h4 class="mat-h4 padding-10">Handstripping cannot be booked online;
        please
        contact the salon directly to book this
        service. <br>For salon contact details visit <a
          href="http://www.petsathome.com/groomroom">petsathome.com/groomroom</a>.
      </h4></div>
  </div>
</mat-dialog-content>
