<div class="contact-card" fxLayout="row wrap" fxLayoutAlign.xs="center center"
     fxLayout.xs="column">
  <ng-container *ngIf="contacts$ | async as contacts">
    <ng-container *ngFor="let contact of contacts;first as first;">
      <div class="card-spacer" *ngIf="!first"></div>
      <div *ngIf="!contact.originalData" fxFlex.gt-sm="45"
           [ngStyle.lt-md]="'width:100%'" style="margin-bottom:20px">
        <mat-card style="min-height:76px">
          <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px"
               fxLayoutGap.xs="10px">
            <div fxLayout="column" class="full-width-form-item">
              <div fxLayout="row" fxLayoutAlign="space-between center">
                <p class="contact-header">{{contact.displayName}}</p>
                <ng-container *ngIf="canSetPreferred">
                  <ng-container *ngIf="preferredLoading; else preferredBlock">
                    <mat-progress-spinner diameter="20" color="primary"
                                          mode="indeterminate"></mat-progress-spinner>
                  </ng-container>
                  <ng-template #preferredBlock>
                    <ng-container *ngIf="canEdit">
                      <ng-container *ngIf="contact.isPreferred">
                        <div fxLayout="row" fxLayoutAlign="center center"
                             style="margin-top:-5px">
                          <button class="preferred-button-icon" mat-icon-button>
                            <mat-icon color="accent">star</mat-icon>
                          </button>
                          <p style="text-transform: uppercase; margin:0; font-size:
                      20px">Primary</p></div>
                      </ng-container>
                      <button (click)="setPreferred(contact)"
                              *ngIf="!contact.isPreferred"
                              class="preferred-button-icon" mat-icon-button>
                        <mat-icon color="accent">star_outline
                        </mat-icon>
                      </button>
                    </ng-container>
                  </ng-template>
                </ng-container>
              </div>
              <p class="content-text">{{contact.number}}</p></div>
            <div fxLayout="column" fxLayout.xs="row"
                 fxLayoutAlign="space-between end">
              <button (click)="deleteContact(contact)"
                      *ngIf="canDeleteContact(contact)" color="warn"
                      class="full-width-xs" mat-stroked-button>
                delete
              </button>
              <div class="full-width-xs" fxLayout="row" fxLayoutAlign="end">
                <button (click)="startUpdatingContact(contact)" *ngIf="canEdit"
                        color="primary" mat-stroked-button>
                  edit
                </button>
              </div>
            </div>
          </div>
        </mat-card>
      </div>
      <div *ngIf="contact.originalData" fxFlex.gt-sm="45" class="full-width-xs">
        <mat-card>
          <ng-container *ngIf="contactUpdating; else formBlock">
            <div fxLayout="row" fxLayoutAlign="center center"
                 fxLayoutGap="20px">
              <mat-progress-spinner diameter="30" color="primary"
                                    mode="indeterminate"></mat-progress-spinner>
              <p class="content-text">Saving Contact</p>
            </div>
          </ng-container>
          <ng-template #formBlock>
            <form [formGroup]="updateContactForm">
              <div fxLayout="column" fxLayoutGap="10px" style="font-size: 18px"
                   class="full-width-form-item">
                <mat-form-field *ngIf="isEditableName(contact); else elseBlock"
                                appearance="outline">
                  <mat-label>Contact name</mat-label>
                  <input formControlName="name" matInput>
                  <mat-error
                    *ngFor="let validation of contactValidationMessages.name">
                    <mat-error
                      *ngIf="updateContactForm.get('name').hasError(validation.type) && (updateContactForm.get('name').dirty || updateContactForm.get('name').touched)"
                      class="error-message">{{validation.message}}</mat-error>
                  </mat-error>
                </mat-form-field>
                <ng-template #elseBlock>
                  <p class="contact-header"
                     style="margin-bottom: 10px">{{contact.displayName}}</p>
                </ng-template>
                <mat-form-field appearance="outline">
                  <mat-label>Contact entry</mat-label>
                  <input formControlName="number" matInput>
                  <mat-error
                    *ngFor="let validation of contactValidationMessages.number">
                    <mat-error
                      *ngIf="updateContactForm.get('number').hasError(validation.type) && (updateContactForm.get('number').dirty || updateContactForm.get('number').touched)"
                      class="error-message">{{validation.message}}</mat-error>
                  </mat-error>
                </mat-form-field>
                <ng-container *ngIf="reminderConsentEnabled">
                  <div fxLayout="row" fxLayoutAlign="start center"
                       fxLayoutGap="20px">
                    <mat-checkbox formControlName="reminder"></mat-checkbox>
                    <p class="content-text">{{reminderConsentText}}</p></div>
                </ng-container>
                <div fxLayout="row" fxLayoutAlign="space-between center">
                  <button (click)="cancelUpdatingContact(contact)"
                          mat-stroked-button type="button">
                    cancel
                  </button>
                  <button (click)="updateContact(contact)" color="primary"
                          [disabled]="!updateContactForm.valid ||
                          !updateContactForm.dirty" mat-stroked-button
                          type="button">
                    save
                  </button>
                </div>
              </div>
            </form>
          </ng-template>
        </mat-card>
      </div>
    </ng-container>
  </ng-container>
  <div fxFlex="50" fxFlex.xs="100" class="full-width-xs"
       style="align-self: center;text-align: center;" *ngIf="canAddContact">
    <ng-container *ngIf="addingContact">
      <app-add-contact (addingCompleted)="addingContact = false"
                       [mobileRegexp]="mobileRegexp" [phoneRegexp]="phoneRegexp"
                       [reminderConsentText]="reminderConsentText"
                       [reminderConsentEnabled]="reminderConsentEnabled"></app-add-contact>
    </ng-container>
    <div *ngIf="!addingContact" style="min-height: 100px"
         fxLayoutAlign="center center">
      <button (click)="startAddContact()" color="primary" mat-raised-button
              type="button">
        Add new contact
      </button>
    </div>
  </div>
  <p *ngIf="canDelete" class="note-label">Note: You cannot delete preferred
    or primary contacts.</p>
</div>
