import {Component, OnInit} from '@angular/core';
import {SessionService} from '../../services/session-service.service';
import {ClientService} from '../client.service';
import {ClientFormItem} from '../../components/name-entry/name-entry.component';
import {MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig} from '@angular/material/legacy-dialog';
import {ErrorDialogComponent} from '../../components/shared/error-dialog/error-dialog.component';
import {BehaviorSubject} from 'rxjs';
import {AddressFormItem} from '../../components/address-lookup/address-lookup.component';
import {Country} from '@appyvet/vetbooker-definitions/dist/countries';
import {AddressRequirements} from '@appyvet/vetbooker-definitions/dist/client_patient_details';
import {AddressObject} from '@appyvet/vetbooker-definitions/dist/register_user';
import {GoogleAnalyticsService} from "ngx-google-analytics";

@Component({
  selector: 'app-client-details',
  templateUrl: './client-details.component.html',
  styleUrls: ['./client-details.component.scss']
})
export class ClientDetailsComponent implements OnInit {

  formattedAddress: string;
  isGroomRoom: boolean;
  addressUpdate: boolean;
  canEditClient: boolean;
  nameUpdate: boolean;
  formattedName: string;
  clinicCode: string;
  saving$ = new BehaviorSubject<boolean>(false);
  addressRequirements: AddressRequirements;
  country: Country;
  currentAddress: AddressObject;
  private clinicName: string;
  showAddressForm: boolean;

  constructor(private sessionService: SessionService, public clientService: ClientService, private dialog: MatDialog,
              private gaService: GoogleAnalyticsService) {
  }

  ngOnInit(): void {
    this.sessionService.clientPatientDetails$.subscribe(clientPatientDetails => {
      this.country = clientPatientDetails.country;
      this.addressRequirements = clientPatientDetails.editSettings.addressRequirements;
      this.isGroomRoom = clientPatientDetails.isGroomRoom;
      this.canEditClient = clientPatientDetails.editSettings.editClient;
      this.clinicCode = clientPatientDetails.clinicCode;
      this.clinicName = clientPatientDetails.themeSettings.customName;
      this.showAddressForm = clientPatientDetails.editSettings.manualAddressEntry;
      this.currentAddress = {
        address1: clientPatientDetails.clientData.client.address1,
        address2: clientPatientDetails.clientData.client.address2,
        county: clientPatientDetails.clientData.client.state,
        postcode: clientPatientDetails.clientData.client.postcode,
        town: clientPatientDetails.clientData.client.address3
      };
    });
    this.clientService.client$.subscribe(client => {
      this.formattedAddress = client.address1;
      if (client.address2) {
        this.formattedAddress += ', ' + client.address2;
      }
      if (client.address3) {
        this.formattedAddress += ', ' + client.address3;
      }
      if (client.state) {
        this.formattedAddress += ', ' + client.state;
      }
      if (client.postcode) {
        this.formattedAddress += ', ' + client.postcode;
      }
      this.formattedName = '';
      if (client.title) {
        this.formattedName += client.title + ' ';
      }
      if (client.firstName) {
        this.formattedName += client.firstName + ' ';
      }
      if (client.surname) {
        this.formattedName += client.surname;
      }
    });
  }

  onSaveName(clientForm: ClientFormItem) {
    if (!clientForm.firstLoad) {
      this.saving$.next(true);
      this.clientService.updateName$(clientForm.client).subscribe(() => {
        this.gaService.event(
          'Edit Name',
          'Client Details',
          this.clinicName
        );
        this.nameUpdate = false;
        this.saving$.next(false);
      }, error => {
        this.saving$.next(false);
        const errorDialogConfig = new MatDialogConfig();
        errorDialogConfig.data = {
          title: 'Error saving name update',
          error
        };
        this.dialog.open(ErrorDialogComponent, errorDialogConfig);
      });
    }
  }

  onSaveAddress(addressForm: AddressFormItem) {
    if (!addressForm.firstLoad) {
      this.clientService.updateAddress$(addressForm.address).subscribe(result => {
        this.gaService.event(
          'Edit Address',
          'Client Details',
          this.clinicName
        );
        if (result) {
          this.addressUpdate = false;
        }
      }, error => console.log(error));
    }
  }

  onAddressCancel() {
    this.addressUpdate = false;
  }
}
