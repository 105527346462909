<form [formGroup]="emailForm">
  <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px">
    <mat-form-field appearance="outline" class="full-width-form-item">
      <mat-label>Email</mat-label>
      <input formControlName="email" minlength="5" maxlength="30" matInput required>
      <mat-error *ngFor="let validation of emailValidationMessages.email">
        <mat-error
          *ngIf="emailForm.get('email').hasError(validation.type) && (emailForm.get('email').dirty || emailForm.get('email').touched)"
          class="error-message">{{validation.message}}</mat-error>
      </mat-error>
    </mat-form-field>
    <div fxLayoutAlign="center center">
      <ng-container *ngIf="loading; else buttonBlock">
        <div fxLayout="row" fxLayoutGap="20px">
          <mat-progress-spinner *ngIf="loading" color="accent" diameter="40"
                                mode="indeterminate"></mat-progress-spinner>
          <p>Saving</p></div>
      </ng-container>
      <ng-template #buttonBlock>
        <button (click)="saveEmail()" color="primary" mat-raised-button
                [disabled]="!emailForm.valid">
          Save
        </button>
      </ng-template>
    </div>
  </div>
</form>
