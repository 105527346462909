<div class="policy-wrap">
  <h1>Privacy Policy</h1>
  <p class="subtitle">Last updated: 05/04/2023</p>

  <h2>1. Introduction</h2>
  <p>The Pets at Home Family respects your right to privacy. This Privacy Policy explains in detail the types of
    personal information we may collect about you when you interact with us. It also explains how we will store and
    handle that personal information, how we keep it safe, how you can exercise your privacy rights and how we combine
    data across the Pets at Home Family to build a picture of you.</p>
  <p>If you have any questions or concerns about our use of your personal information, then please contact us using the
    contact details provided in the section of this Privacy Policy headed “How to contact us” below.</p>

  <h2>2. What is the Pets at Home Family?</h2>
  <p>Pets at Home is the UK’s leading pet specialist, providing pet owners with all their needs; food, accessories, vet
    and grooming services. The terms "we", "us" or "our" in this Privacy Policy refers to Pets at Home Limited
    (comprising our retail stores, retail Website and Apps, the Groom Rooms and VIP Club) (“Pets at Home”). Pets at Home
    Group Plc is the ultimate parent company of Pets at Home.</p>
  <p>References to the “Pets at Home Family” means the Pets at Home group companies and affiliated joint venture
    entities, which includes:</p>
  <p>Pets at Home Limited (including our retail stores, retail website and apps, the Groom Room and VIP Club);</p>
  <p>Pets at Home Vet Group Limited (comprising Companion Care (Services) Limited, Companion Care Management Services
    Limited, Vets4Pets Limited and our affiliated joint venture first opinion veterinary practices branded either
    Companion Care or Vets4Pets);</p>
  <p>Pets at Home Group plc;</p>
  <p>Pet Advisory Services Limited (trading as The Vet Connection)</p>
  <p>The Pets at Home Foundation our affiliated charity, together "Pets at Home Family".</p>

  <h2>3. Quick Links</h2>
  <p>We recommend that you read this Privacy Policy in full to ensure you are fully informed. However, if you only want
    to access a particular section of this Privacy Policy, then you can click on the relevant link below to jump to that
    section.</p>
  <p><a href="/privacy-policy/#4">When do we collect personal information?</a></p>
  <p><a href="/privacy-policy/#5">What personal information do we collect and why?</a></p>
  <p><a href="/privacy-policy/#6">Who do we share your personal information with?</a></p>
  <p><a href="/privacy-policy/#7">Legal Basis for processing personal information</a></p>
  <p><a href="/privacy-policy/#8">Cookies and similar tracking technology</a></p>
  <p><a href="/privacy-policy/#9">How do we keep your personal information secure?</a></p>
  <p><a href="/privacy-policy/#10">International Data Transfer</a></p>
  <p><a href="/privacy-policy/#11">Data Retention</a></p>
  <p><a href="/privacy-policy/#12">Your data protection rights</a></p>
  <p><a href="/privacy-policy/#13">Contacting the regulator</a></p>
  <p><a href="/privacy-policy/#14">Updates to this Privacy Policy</a></p>
  <p><a href="/privacy-policy/#15">How to contact us</a></p>

  <a id="4"></a>
  <h2>4. When do we collect personal information?</h2>
  <p>We collect your personal information in a number of instances, including when you:</p>
  <ul>
    <li>Make an online purchase and check out as a guest (in which case we collect transaction-based data);</li>
    <li>Create an account with us;</li>
    <li>Join our VIP Club loyalty programme;</li>
    <li>Join one of our VIP Subscription Plans;</li>
    <li>Book any kind of appointment with us or book to attend an event, for example Nutrition Consultations, Flea and
      Worm Consultations, Puppy Classes, Super Breed Sundays or My Pet Pals Workshops;
    </li>
    <li>Visit any of our Websites or Apps, and use your account to buy products and services, or redeem vouchers from
      the Pets at Home Family on the phone, in a shop or online;
    </li>
    <li>Purchase a product or service in store or by phone but don’t have (or don’t use) an account;</li>
    <li>Register your details as a new client at one of our Companion Care or Vets4Pets surgeries (or update your
      details if they change), to register your pet on health plans or make appointments at the surgeries;
    </li>
    <li>Sign up to one of our health plans;</li>
    <li>Engage with us on social media;</li>
    <li>Download or install one of our Apps;</li>
    <li>Use our in-store WIFI</li>
    <li>Buy a pet from us;</li>
    <li>Contact us by any means with questions about a product or to raise a complaint;</li>
    <li>Ask one of our colleagues to email you information about a product or service;</li>
    <li>Enter prize draws or competitions;</li>
    <li>Use your VIP Club loyalty card;</li>
    <li>Comment on or review our products and services. Any individual may access personal information related to them,
      including opinions. So if your comment or review includes information about the colleague who provided that
      service, it may be passed on to them if requested;
    </li>
    <li>Fill in any forms. For example, if an accident happens in store or surgery, a colleague may collect your
      personal information;
    </li>
    <li>Have given a third party permission to share the personal information they hold about you with us;</li>
    <li>Phone us or we phone you, we may record phone conversations for record or training purposes; and/or</li>
    <li>Use our car parks, shops, or veterinary surgeries which usually have CCTV systems operated for the security of
      both customers and colleagues and for the prevention of crime. These systems may record your image during your
      visit.
    </li>
  </ul>
  <p>We also collect your personal information when our suppliers and partners – e.g. PetPlan Limited – share
    information with us about the product or service you have purchased.</p>

  <a id="5"></a>
  <h2>5. What personal information do we collect and why?</h2>
  <p>The personal information that we collect about you broadly falls into the following categories:</p>
  <h3>Information that you provide voluntarily</h3>
  <p>When you make a purchase in store or online, sign up to our VIP Club, book an appointment, attend an event in
    store, subscribe for products under one of our subscription or health plans, book a grooming appointment, register
    in surgery, apply for pet insurance or download one of our Apps or enter a prize draw/competition, you will be asked
    for some essential information including your title, first name, surname, postal address, email address, telephone,
    details of your pet and, if applicable, payment information. These are collected to help us:</p>
  <ul>
    <li>Process your online orders including sending order confirmation and delivery information via email or text
      message, or process payments due to us (including for treatments for your pet);
    </li>
    <li>Contact you about your pet, your order, account or credit status where required;</li>
    <li>Deliver safe and secure shopping by helping to prevent and detect fraud – to do this, we may need to disclose
      information to assist legal or debt recovery processes;
    </li>
    <li>Understand your needs and listen to your feedback via market research/shopping analysis, customer polls and
      surveys;
    </li>
    <li>Register your details as a new client at one of our Vets4Pets or Companion Care surgeries (or update your
      details if they change), to register your pet on health plans or make appointments at the surgeries;
    </li>
    <li>Manage and process your subscription and health plans;</li>
    <li>Enhance your Pets at Home experience by delivering personally tailored retail offers;</li>
    <li>Send you marketing and promotional offers and to manage your marketing preferences if you elect to receive
      marketing and promotional offers from us;
    </li>
    <li>To administer your membership of our VIP Club (if you choose to become a member) and to contact you with offers
      and promotions from our VIP Club (including from other members of the Pets at Home Family and selected third party
      partners);
    </li>
    <li>Speed up your form filling processes if you've registered online;</li>
    <li>Provide you with information about the fundraising activities of our affiliated charity, the Pets at Home
      Foundation;
    </li>
    <li>Respond to any issues or queries via the customer service adviser teams;</li>
    <li>Conduct analytics to understand how our services are used and to improve your customer experience; and/or</li>
    <li>Notify you about changes to our service.</li>
  </ul>
  <p>When you use our Websites as a registered or guest user, we also collect your email address and may contact you to
    let you know if you abandon a purchase part way through the process and have unpurchased items in your basket.</p>
  <p>Where you have made a donation to our affiliated charity, The Pets at Home Foundation, via the Just Giving, Virgin
    Money website or via Paypal and agree with Just Giving, Virgin Money or Paypal that they can pass your information
    to us (to use to send you thank you emails and news about our fundraising activities), this information will be
    processed in accordance with the The Pets at Home Foundation Privacy Policy which can be found here.</p>
  <h3>Information that we collect automatically</h3>
  <p>When you visit our Websites, Apps or use our in-store WIFI, we may collect certain information automatically from
    your device. In some countries, including countries in the European Economic Area, this information may be
    considered personal information under applicable data protection laws.</p>
  <p>Specifically, the information we collect automatically may include information like your IP address, device type,
    operating system, unique device identification numbers, browser-type, broad geographic location (e.g. country or
    city-level location) and other technical information. We may also collect information about how your device has
    interacted with our Websites, Apps and in-store WIFI, including the pages accessed and links clicked.</p>
  <p>Collecting this information enables us to better understand the visitors who come to and use our Websites, Apps and
    in-store WIFI. We may look at where they come from, what content interest to them, what they have viewed or looked
    at and in some instances what they have purchased. We use this information for internal analytics purposes, to
    improve the quality and relevance of our Websites, Apps and in-store WIFI to our visitors and users.</p>
  <p>We will deliver personally tailored retail offers (both on the website and through triggered email, SMS and direct
    mail (but only where you have agreed to these channels)).</p>
  <p>Some of this information may be collected using cookies, tracking pixels and similar tracking technology, as
    explained further under the heading “Cookies and similar tracking technology” below.</p>
  <p>Information that we obtain from third party sources</p>
  <p>From time to time, we may receive personal information about you from third party sources, but only where we have
    checked that these third parties either have your consent or are otherwise legally permitted or required to disclose
    your personal information to us.</p>
  <p>If you have any questions about the third parties we obtain personal information from, please contact us using the
    contact details provided under the “How to contact us” heading below.</p>
  <h3>Enhancing our data</h3>
  <p>Where we have obtained your personal information from various sources (including personal information we have
    lawfully obtained from third parties or from other members of the Pets at Home Family) we may combine this
    information in certain circumstances in order to enhance our understanding of your requirements and preferences in
    relation to our products and services. This is necessary for the purposes of our legitimate interests to ensure that
    we provide you the most appropriate offers for products and services and to personalise your Pets at Home Family
    experience.</p>
  <h3>Conducting analytics</h3>
  <p>We will use the personal information we hold about you (as well as pseudonymised or anonymised information
    generated from your personal information) to carry out analysis and research. We carry out all such analysis and
    research on the basis that it is necessary for the purposes of our legitimate interests in understanding our
    customers and ensuring that our products and services meet the needs of our customers. We undertake data analytics,
    whereby we combine information we hold on a large scale, in order to:</p>
  <ul>
    <li>Learn more about our customers and their preferences;</li>
    <li>To identify patterns and trends amongst our customers;</li>
    <li>Enhance user experience on our Websites and Apps;</li>
    <li>Provide information, content and offerings tailored to our customers' needs;</li>
    <li>For general research and statistical purposes;</li>
    <li>For aggregated reporting purposes within the Pets at Home Family;</li>
    <li>To help us develop new products and services;</li>
    <li>To monitor performance of our products and services;</li>
    <li>To be able to send you personalised marketing messages; and</li>
    <li>To display online advertisements to you.</li>
  </ul>
  <p>We will use your personal information (including by anonymising and aggregating it with other customers' personal
    information) for sales, supply chain and financial analysis purposes, to determine how we are performing and where
    improvements can be made and where necessary to report back to other members of the Pets at Home Family. This is
    necessary for the purposes of our legitimate interests in understanding how our business is performing, and
    considering how to improve our performance.</p>

  <a id="6"></a>
  <h2>6. Who do we share your personal information with?</h2>
  <p>We may disclose your personal information to the following categories of recipients:</p>
  <ul>
    <li>to other members of the Pets at Home Family, our third party service providers and business partners who provide
      data processing services to us, or who otherwise process personal information for purposes that are described in
      this Privacy Policy or notified to you when we collect your personal information. Examples of the kind of third
      parties we work with are:
    </li>
    <li>IT companies who support our Websites, Apps and other business systems;</li>
    <li>Operational companies such as delivery couriers;</li>
    <li>Direct marketing, loyalty reward companies or other third parties who help us manage electronic communications
      with you;
    </li>
    <li>Data insight and data analysis companies (to provide us with tools to analyse the data which we hold);</li>
    <li>Promotional partners (such as cashback or voucher sites);</li>
    <li>Customer review companies (so you can leave feedback and we can improve our service to you); and/or</li>
    <li>Competition or prize-draw partners.</li>
    <li>to any competent law enforcement body, regulatory, government agency, court or other third party where we
      believe disclosure is necessary (i) as a matter of applicable law or regulation, (ii) to exercise, establish or
      defend our legal rights or apply our Terms and Conditions, or (iii) to protect your vital interests or those of
      any other person;
    </li>
    <li>to a potential buyer (and its agents and advisers) in connection with any proposed purchase, merger or
      acquisition of any part of our business, provided that we inform the buyer it must use your personal information
      only for the purposes disclosed in this Privacy Policy;
    </li>
    <li>to any other person with your consent to the disclosure.</li>
  </ul>

  <p>Where we share your personal information with third party service providers and partners we apply a policy to
    ensure your personal information is safe and in order to protect your privacy. Our policy requires:</p>
  <ul>
    <li>We provide only the information they need to perform their specific services.</li>
    <li>They may only use your data for the purposes we specify in our contract with them.</li>
    <li>We work closely with them to ensure that your privacy is respected and protected at all times.</li>
    <li>If we stop using their services, any of your personal information held by them will either be deleted or
      rendered anonymous (subject to applicable law).
    </li>
  </ul>
  <p>If you have any questions about the third parties we share your personal information with, please contact us using
    the contact details provided under the “How to contact us” heading below.</p>
  <p>Third parties (such as <a href="https://policies.google.com/" target="_blank">Google</a>, Bing, Yahoo Facebook,
    Instagram, Twitter, Pinterest, Snapchat, Tumblr) may serve cookies on your computer or mobile device to serve
    advertising through our Websites. These companies may use information about your visits to this and other websites
    in order to provide relevant advertisements about goods and services that you may be interested in. They may also
    employ technology that is used to measure the effectiveness of advertisements. This can be accomplished by them
    using cookies or web beacons to collect information about your visits to this and other sites in order to provide
    relevant advertisements about goods and services of potential interest to you. The information collected through
    this process does not enable us or them to identify your name, contact details or other personally identifying
    details unless you choose to provide these (based on your marketing consent or acceptance of cookies). Please see
    “Cookies and similar tracking technology” below for more information.</p>

  <a id="7"></a>
  <h2>7. Legal basis for processing personal information</h2>
  <p>Our legal basis for collecting and using the personal information described above will depend on the personal
    information concerned and the specific context in which we collect it.</p>
  <p>However, we will normally collect personal information from you only (i) where we need the personal information to
    perform a contract with you, (ii) where the processing is in our legitimate interests and not overridden by your
    data protection interests or fundamental rights and freedoms, or (iii) where we have your consent to do so. In some
    cases, we may also have a legal obligation to collect personal information from you or may otherwise need the
    personal information to protect your vital interests or those of another person.</p>
  <p>If we ask you to provide personal information to comply with a legal requirement or to perform a contract with you,
    we will make this clear at the relevant time and advise you whether the provision of your personal information is
    mandatory or not (as well as of the possible consequences if you do not provide your personal information).</p>
  <p>Similarly, if we collect and use your personal information in reliance on our legitimate interests (or those of any
    third party), we will make clear to you at the relevant time what those legitimate interests are.</p>
  <p>If you have questions about or need further information concerning the legal basis on which we collect and use your
    personal information, please contact us using the contact details provided under the “How to contact us” heading
    below.</p>
  <a id="8"></a>
  <h2>8. Cookies and similar tracking technology</h2>
  <p>We use cookies and similar tracking technology (collectively, “Cookies”) to collect and use personal information
    about you. For further information about the types of Cookies we use, why, and how you can control Cookies, please
    see our Cookie Notice here.</p>

  <a id="9"></a>
  <h2>9. How do we keep your personal information secure</h2>
  <p>We use appropriate technical and organisational measures to protect the personal information that we collect and
    process about you. The measures we use are designed to provide a level of security appropriate to the risk of
    processing your personal information. Specific measures we use include:</p>
  <ul>
    <li>Encrypting your personal information. When you place an order or access your account information, your
      information will be sent through to us. A secure server encrypts all of the information you input before it is
      sent to us. This protects your confidential data, particularly your credit card details from unauthorised use;
    </li>
    <li>Keeping your information up-to-date and accurate. Please note that, to do this, we require you to tell us if any
      of your details such as your name or address change; and
    </li>
    <li>Having in place strict security procedures for the storage and disclosure of your information to prevent
      unauthorised access.
    </li>
  </ul>
  <p>Unfortunately, the transmission of information via the Internet is not completely secure. Although we will do our
    best to protect your personal information, we cannot guarantee the security of your data transmitted to and/or
    through our Websites and Apps; any transmission is at your own risk. Once we have received your information, we will
    use strict procedures and security features to try to prevent unauthorised access.</p>
  <p>To ensure that your credit, debit or charge card is not being used without your consent, we will validate name,
    address and other personal information supplied by you during the order process against appropriate third party
    databases. By accepting our Terms and Conditions you consent to these checks being made. In performing these checks
    personal information provided by you may be disclosed to a registered Credit Reference Agency which may keep a
    record of that information. You can rest assured that this is done only to confirm your identity, that a credit
    check is not performed and that your credit rating will be unaffected. All information provided by you will be
    treated securely and strictly in accordance with this Privacy Policy.</p>

  <a id="10"></a>
  <h2>10. International data transfers</h2>
  <p>Your personal information may be transferred to, and processed in, countries other than the country in which you
    are resident. These countries may have data protection laws that are different to the laws of the UK.</p>
  <p>Our Website servers and group companies are located in the UK (excluding our sourcing office which is based in Hong
    Kong), but some of our third party service providers and partners operate outside of the UK, such as in the EU, EEA,
    Australia and the United States. This means that when we collect your personal information we may process it in any
    of these countries.</p>
  <p>If we do this, we have procedures in place to ensure your personal information receives the same protection as if
    it were being processed inside the UK. For example, our contracts with third parties stipulate the standards they
    must follow at all times. If you wish for more information about these contracts please contact us using the contact
    details provided under the “How to contact us” heading below .</p>
  <p>Any transfer of your personal data will be compliant with UK data protection law and all personal data will be
    secure.</p>

  <a id="11"></a>
  <h2>11. Data retention</h2>
  <p>We retain personal information we collect from you where we have an ongoing legitimate business need to do so (for
    example, to provide you with a service you have requested or to comply with applicable legal, tax or accounting
    requirements).</p>
  <p>When we have no ongoing legitimate business need to process your personal information, we will either delete or
    anonymise it or, if this is not possible (for example, because your personal information has been stored in backup
    archives), then we will securely store your personal information and isolate it from any further processing until
    deletion is possible. For more information about the specific periods for which we retain your data, please contact
    us using the contact details provided under the “How to contact us” heading below.</p>

  <a id="12"></a>
  <h2>12. Your data protection rights</h2>
  <p>You have the following data protection rights:</p>
  <ul>
    <li>If you wish to access, correct, update or request deletion of your personal information, you can do so at any
      time by contacting us using the contact details provided under the “How to contact us” heading below.
    </li>
    <li>In addition, you can object to processing of your personal information, ask us to restrict processing of your
      personal information or request portability of your personal information. Again, you can exercise these rights by
      contacting us using the contact details provided under the “How to contact us” heading below.
    </li>
    <li>You have the right to opt-out of marketing communications we send you at any time. You can exercise this right
      by clicking on the “unsubscribe” or “opt-out” link in the marketing e-mails we send you. To opt-out of other forms
      of marketing (such as postal marketing or telemarketing), then please contact us using the contact details
      provided under the “How to contact us” heading below.
    </li>
    <li>Similarly, if we have collected and process your personal information with your consent, then you can withdraw
      your consent at any time. Withdrawing your consent will not affect the lawfulness of any processing we conducted
      prior to your withdrawal, nor will it affect processing of your personal information conducted in reliance on
      lawful processing grounds other than consent.
    </li>
    <li>You have the right to complain to a data protection authority about our collection and use of your personal
      information. For more information, see the section headed “contacting the regulator” below.
    </li>
  </ul>
  <p>We respond to all requests we receive from individuals wishing to exercise their data protection rights in
    accordance with applicable data protection laws.</p>

  <a id="13"></a>
  <h2>13. Contacting the regulator</h2>
  <p>If you feel that your personal information has not been handled correctly, or you are unhappy with our response to
    any requests you have made to us regarding the use of your personal information, you have the right to raise a
    complaint with the Information Commissioner’s Office. You can contact them by calling 0303 123 1113 or go online to
    www.ico.org.uk/concerns (opens in a new window; please note we cannot be responsible for the content of external
    websites).</p>

  <a id="14"></a>
  <h2>14. Updates to this Privacy Policy</h2>
  <p>We may update this Privacy Policy from time to time in response to changing legal, technical or business
    developments. When we update our Privacy Policy, we will take appropriate measures to inform you, consistent with
    the significance of the changes we make.</p>
  <p>You can see when this Privacy Policy was last updated by checking the “last updated” date displayed at the top of
    this Privacy Policy.</p>

  <a id="15"></a>
  <h2>15. How to contact us</h2>
  <p>If you have any questions or concerns about our use of your personal information, please contact our Data
    Protection Officer at the following address <a href="mailto: dataprotection@petsathome.co.uk"></a>dataprotection@petsathome.co.uk.
  </p>
  <p>The data controller of your personal information will be the Pets at Home Family entity that you are dealing with,
    and as such could be Pets at Home Limited (including where you are a member of our VIP Club), Companion Care
    (Services) Limited, Vets4Pets Limited, Pets at Home Vet Group Limited, Pet Advisory Services Limited (trading as The
    Vet Connection), our affiliated charity, The Pets at Home Foundation or one of our affiliated joint venture first
    opinion veterinary practices branded either Companion Care or Vets4Pets.</p>
</div>
