<ng-container *ngIf="(payments$ | async) === null">
    Loading...
</ng-container>
<ng-container *ngIf="(payments$ | async) !== null">
    <ng-container *ngIf="(payments$ | async).length === 0">
        No payments found
    </ng-container>
    <ng-container *ngIf="(payments$ | async).length > 0">
        <table mat-table [dataSource]="(payments$ | async)">
            <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef>Date</th>
                <td mat-cell *matCellDef="let element">{{element.date | dfnsFormat:'dd/MM/yyyy HH:mm'}}</td>
            </ng-container>
            <ng-container matColumnDef="amount">
                <th mat-header-cell *matHeaderCellDef>Amount</th>
                <td mat-cell *matCellDef="let element">{{element.amount | formatCurrency:element.currency }}</td>
            </ng-container>
            <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef>Description</th>
                <td mat-cell *matCellDef="let element">{{element.description}}</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="columns"></tr>
            <tr mat-row *matRowDef="let row; columns: columns;"></tr>
        </table>
    </ng-container>
</ng-container>