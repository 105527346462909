import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {MapSearchService} from '../map-search.service';
import {MatStepper} from '@angular/material/stepper';
import {AuthService} from '../../auth/auth.service';
import {BehaviorSubject, Subscription} from 'rxjs';
import {Router} from '@angular/router';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {AlertDialogComponent} from '../../components/shared/alert-dialog/alert-dialog.component';
import {environment} from '../../../environments/environment';
import {DateOptions} from '../../interfaces/date-options';
import {isSameDay} from 'date-fns';
import {BookingService} from '../../services/booking.service';
import {GroomBookingTypeGuideComponent} from '../../booker/groom-booking-type-guide/groom-booking-type-guide.component';
import {VbBookableItem, VbSlotResponse} from '@appyvet/vetbooker-definitions/dist/bookings';
import {MarkerItem, PositionItem} from '@appyvet/vetbooker-definitions/dist/map';
import {ClinicDetails} from '@appyvet/vetbooker-definitions/dist/clinic_details';
import {GroomAppointmentType} from '@appyvet/vetbooker-definitions/dist/appointment_type';
import {GoogleAnalyticsService} from "ngx-google-analytics";

@Component({
  selector: 'app-groom-selector',
  templateUrl: './groom-selector.component.html',
  styleUrls: ['./groom-selector.component.scss']
})
export class GroomSelectorComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('groomStepper') private myStepper: MatStepper;
  @ViewChild('times') private timesView: ElementRef;
  screenWidth: number;
  options: google.maps.MapOptions = {
    disableDoubleClickZoom: true,
    maxZoom: 15,
    minZoom: 6,
    mapTypeControl: false,
  };
  center: google.maps.LatLngLiteral = {
    lat: 54,
    lng: -3,
  };
  zoom = 6;
  markers: MarkerItem[] = [];
  clinics$ = new BehaviorSubject<ClinicDetails[]>(null);
  locationIcon = {
    url: '../assets/svg/map_icon.svg',
    scaledSize: new google.maps.Size(30, 30),
    optimized: false,
  };
  hasLocation: boolean;
  height$ = new BehaviorSubject<number>(0);
  loading = false;
  location: PositionItem;
  @Input() noMap: boolean;
  @Input() returnClinic: boolean;
  @Input() currentClinicCode: string;
  @Output() clinicSelected = new EventEmitter<ClinicDetails>();
  typeName = environment.GROOM ? 'salon' : 'clinic';
  isGroomRoom = environment.GROOM;
  selectedClinic: ClinicDetails;
  selectedGroom: GroomAppointmentType;
  private apptTypesSub: Subscription;
  apptTypes: GroomAppointmentType[];
  groomDateOptions: DateOptions = {maxDays: 84, satDisallowed: false, sunDisallowed: false, minDaysBeforeBooking: 0};
  selectedDate$ = new BehaviorSubject<Date>(new Date());
  clipboardSlots$ = new BehaviorSubject<VbBookableItem[]>(null);
  availabilityResults$ = new BehaviorSubject<VbSlotResponse[]>([]);
  selectedItem: VbBookableItem;
  today = new Date();

  constructor(public mapService: MapSearchService, public authService: AuthService, private router: Router,
              private dialog: MatDialog, private gaService: GoogleAnalyticsService, private bookingService: BookingService) {
    if (this.isGroomRoom) {
      this.apptTypesSub = this.mapService.groomTypes$.subscribe(groomTypes => {
        this.apptTypes = groomTypes;
      });
    }
  }

  ngOnDestroy() {
    this.apptTypesSub?.unsubscribe();
  }

  addMarker(clinic: ClinicDetails) {
    this.markers.push({
      position: {
        lat: clinic.latitude,
        lng: clinic.longitude,
      },
      label: {
        color: 'blue',
        // text: clinic.practiceName,
      },
      title: clinic.practiceName,
      clinic,
      isSelected: false,
      options: {
        icon: this.locationIcon
      },
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.height$.next(window.innerHeight - 200);
  }

  ngOnInit(): void {
    this.height$.next(window.innerHeight - 200);
  }

  ngAfterViewInit() {
    this.mapService.location$.subscribe(result => {
      if (result) {
        this.location = result;
        // Need this to override the completed step as doesn't seem to update in time for the next command otherwise
        this.myStepper.steps.first.completed = true;
        this.myStepper.next();
        this.center = result;
        this.zoom = 10;
      }
    });
    this.mapService.clinics$.subscribe(clinics => {
      if (clinics) {
        this.clinics$.next(clinics);
        this.markers = [];
        clinics.forEach(clinic => {
          this.addMarker(clinic);
        });
      }
    });
    this.mapService.loading$.subscribe(loading => {
      this.loading = loading;
    });
  }

  findClinics(searchPlace: google.maps.places.PlaceResult) {
    this.mapService.setLocation({lng: searchPlace.geometry.location.lng(), lat: searchPlace.geometry.location.lat()});
    this.mapService.searchClinicsForLocation(this.currentClinicCode);
    this.gaService.event(
      'Google autocomplete search',
      this.typeName + ' selector',
    );
  }

  getLocation() {
    this.gaService.event(
      'Use location',
      this.typeName + ' selector',
    );
    this.mapService.getLocation(this.currentClinicCode);
  }

  moreClinics() {
    this.mapService.getMoreClinics();
    this.gaService.event(
      'More ' + this.typeName.toLowerCase(),
      this.typeName + ' selector',
    );
  }

  selectMapMarker(marker: MarkerItem) {
    this.clinics$.getValue().forEach(clinic => {
      clinic.selected = clinic.clinicCode === marker.clinic.clinicCode;
      this.gaService.event(
        this.typeName + ' map selection',
        this.typeName + ' selector',
        clinic.practiceName
      );
    });
  }

  getAddress(clinic: ClinicDetails) {
    let returnString = clinic.address1;
    returnString += clinic.address2 ? ', ' + clinic.address2 : '';
    returnString += clinic.address3 ? ', ' + clinic.address3 : '';
    returnString += clinic.address4 ? ', ' + clinic.address4 : '';
    returnString += ', ' + clinic.postcode;
    return returnString;
  }

  callClinic(clinic: ClinicDetails) {
    window.open('tel:' + clinic.phoneNumber, '_self');
    this.gaService.event(
      this.typeName + ' call',
      this.typeName + ' selector',
      clinic.practiceName
    );
  }

  getDirections(clinic: ClinicDetails) {
    window.open(
      'https://www.google.com/maps/dir/?api=1&origin=' + this.location.lat + ',' + this.location.lng +
      '&destination=' + clinic.latitude + ',' + clinic.longitude,
      '_blank');
    this.gaService.event(
      this.typeName + ' directions',
      this.typeName + ' selector',
      clinic.practiceName
    );
  }

  selectClinic(clinic: ClinicDetails, fromAvailabilityChecker: boolean) {
    let actionName = this.typeName + ' selected';
    if (fromAvailabilityChecker) {
      actionName += ' availability';
    }
    this.gaService.event(
      actionName,
      this.typeName + ' selector',
      clinic.practiceName
    );
    if (this.returnClinic) {
      this.clinicSelected.emit(clinic);
    } else {
      this.router.navigate(['/login'], {queryParams: {clinicCode: clinic.clinicCode}});
    }
  }

  openClosedMessage(clinic: ClinicDetails) {
    this.gaService.event(
      this.typeName + ' list expanded',
      this.typeName + ' selector',
      clinic.practiceName
    );
    if (clinic.tempClosure) {
      this.gaService.event(
        this.typeName + ' closed message',
        this.typeName + ' selector',
        clinic.practiceName
      );
      this.dialog.open(AlertDialogComponent, {data: {html: clinic.tempClosureMessage, confirmationText: 'Got it'}});
    }
  }

  viewAvailability(clinic: ClinicDetails) {
    this.selectedClinic = clinic;
    this.gaService.event(
      this.typeName + ' availability selected',
      this.typeName + ' selector',
      clinic.practiceName
    );
    this.myStepper.steps.toArray()[1].completed = true;
    this.myStepper.next();
    this.scrollToBottom();
  }

  selectGroomType(apptType: GroomAppointmentType) {
    this.selectedGroom = apptType;
    this.gaService.event(
      this.typeName + ' availability groom type selected',
      this.typeName + ' selector',
    );
    this.selectedItem = null;
    this.clipboardSlots$.next([]);
    this.myStepper.steps.toArray()[2].completed = true;
    this.myStepper.next();
    this.mapService.getGroomAvailability(this.selectedClinic, this.selectedGroom, this.selectedDate$.getValue())
      .subscribe(results => {
        this.availabilityResults$.next(results);
        this.dateSelected(this.selectedDate$.getValue());
        this.scrollToBottom();
      });
  }

  changeMonth(newMonthDate: Date) {
    this.selectedItem = null;
    this.gaService.event(
      this.typeName + ' availability month changed',
      this.typeName + ' selector',
    );
    this.clipboardSlots$.next([]);
    this.mapService.getGroomAvailability(this.selectedClinic, this.selectedGroom, newMonthDate).subscribe(results => {
      this.availabilityResults$.next(results);
      this.dateSelected(this.selectedDate$.getValue());
    });
    this.selectedDate$.next(newMonthDate);
  }

  scrollToBottom() {
    if (this.authService.screenWidth$.getValue() < 400) {
      setTimeout(() => {
        const scroller = document.querySelector('mat-sidenav-content');
        scroller.scrollTo({behavior: 'smooth', top: scroller.scrollHeight});
      }, 200);
    }
  }

  dateSelected(selectedDate: Date) {
    this.selectedDate$.next(selectedDate);
    this.availabilityResults$.getValue().forEach(slot => {
      if (isSameDay(slot.date, selectedDate)) {
        this.clipboardSlots$.next(slot.clipboardResults[0].clipboardItems);
        this.scrollToBottom();
      }
    });
  }

  setSelectedTime(item: VbBookableItem) {
    this.selectedItem = item;
    this.gaService.event(
      this.typeName + ' availability slot selected',
      this.typeName + ' selector',
      this.selectedClinic.practiceName,
    );
    this.scrollToBottom();
  }

  navigateFromBooking() {
    this.bookingService.storeAvailabilityParams(this.selectedItem, this.selectedGroom);
    this.selectClinic(this.selectedClinic, true);
  }

  showBookerTypeHelper() {
    this.dialog.open(GroomBookingTypeGuideComponent,
      {panelClass: 'no-padding-dialog', maxWidth: this.screenWidth > 400 ? '80vw' : '100vw'});
  }
}
