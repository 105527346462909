<div fxLayout="row" style="margin-bottom: 30px">
  <ng-container *ngIf="authService.screenWidth$ | async as screenWidth">
    <div fxLayout="column" style="min-height:100%; background-color:white;" fxFlex.xs="100" fxFlex.gt-xs="50">
      <mat-stepper #groomStepper orientation="vertical" [fxFlex]="!noMap && screenWidth >=
    700 ? '50' : '100'" [linear]="true" color="accent">
        <mat-step [completed]="!!location">
          <div fxLayout="column" style="padding-right: 10px;">
            <ng-template matStepLabel>Enter address</ng-template>
            <app-google-autocomplete countryCode="gb" searchTypes="(regions)"
                                     [hideManualAddress]="true"
                                     (setAddress)="findClinics($event)"></app-google-autocomplete>
            <div fxLayout="row" fxLayoutAlign="end center">
              <button mat-stroked-button color="primary"
                      (click)="getLocation()">
                <mat-icon class="accent-text-color" style="margin-right:10px">gps_fixed
                </mat-icon>
                Around you
              </button>
            </div>
          </div>
        </mat-step>
        <mat-step [completed]="!!selectedClinic">
          <ng-template matStepLabel>Select your {{typeName}}</ng-template>
          <ng-container *ngIf="clinics$ | async as clinics; else emptyBlock">
            <ng-container *ngFor="let clinic of clinics">
              <mat-expansion-panel style="margin: 0 10px 10px 0;"
                                   [ngStyle]="{'background-color': clinic.selected ? '#FFFAF5' : '#ffffff'}"
                                   [disabled]="clinic.tempClosure"
                                   [hideToggle]="clinic.tempClosure"
                                   (click)="openClosedMessage(clinic)"
                                   [expanded]="clinic.selected || clinic.tempClosure">
                <mat-expansion-panel-header>
                  <mat-panel-title [ngStyle]="{'color' : !clinic.optedIn ?
                'rgba(0,0,0,0.42)': null}">
                    {{clinic.practiceName}}
                  </mat-panel-title>
                  <mat-panel-description style="justify-content: flex-end">
                    {{clinic.distanceInMiles?.substring(0, 4)}} miles
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <ng-container *ngIf="clinic.tempClosure; else openBlock">
                  <div fxLayout="row" fxLayoutAlign="start center"
                       fxLayoutGap="20px" class="accent-text-color">
                    <mat-icon>info</mat-icon>
                    <p class="content-text no-margin">Temporarily closed</p>
                  </div>
                </ng-container>
                <ng-template #openBlock>
                  <div fxLayout="column">
                    <p class="content-text">{{getAddress(clinic)}}</p>
                    <button mat-button color="accent"
                            (click)="callClinic(clinic)">
                      <div fxLayout="row" fxLayoutAlign="start center">
                        <mat-icon>
                          call
                        </mat-icon>
                        <p style="margin: 0 0 0 10px;
                font-size:18px">{{clinic.phoneNumber}}</p></div>
                    </button>
                    <button mat-button color="accent"
                            (click)="getDirections(clinic)">
                      <div fxLayout="row" fxLayoutAlign="start center">
                        <mat-icon>
                          location_on
                        </mat-icon>
                        <p
                          style="margin: 0 0 0 10px;font-size:18px; text-transform: capitalize">
                          Get Directions</p></div>
                    </button>
                    <ng-container *ngIf="clinic.optedIn; else disabledBlock">
<!--                      <ng-container *ngIf="isGroomRoom">-->
<!--                        <ng-container *ngIf="clinic | isBeforeEmergencyDate; else-->
<!--                        bannerBlock">-->
<!--                          <button mat-raised-button color="primary"-->
<!--                                  style="margin-bottom:10px"-->
<!--                                  (click)="viewAvailability(clinic)">Check-->
<!--                            Availability-->
<!--                          </button>-->
<!--                        </ng-container>-->
<!--                        <ng-template #bannerBlock>-->
<!--                          <app-groom-welfare-message style="margin-bottom:10px"-->
<!--                                                     [clinicDetails]="clinic"></app-groom-welfare-message>-->
<!--                        </ng-template>-->
<!--                      </ng-container>-->
                      <button mat-stroked-button color="accent"
                              (click)="selectClinic(clinic, false)">Select
                        this {{typeName.toLowerCase()}}
                      </button>
                    </ng-container>
                    <ng-template #disabledBlock>
                      <p class="content-text"
                         style="padding:10px 10px 0 10px">{{typeName}} not
                        available for online booking</p>
                    </ng-template>
                  </div>
                </ng-template>
              </mat-expansion-panel>
            </ng-container>
            <div fxLayout="row" fxLayoutAlign="space-between center"
                 style="margin:10px 10px 10px 0">
              <button matStepperPrevious mat-stroked-button>Back</button>
              <button mat-raised-button color="primary" (click)="moreClinics()">
                More
                {{typeName}}s
              </button>
            </div>
          </ng-container>
          <ng-template #emptyBlock>
            <ng-container *ngIf="loading; else noClinicBlock">
              <div fxLayout="row" fxLayoutGap="20px"
                   fxLayoutAlign="center center">
                <mat-progress-spinner mode="indeterminate" diameter="30"
                                      color="primary"></mat-progress-spinner>
                <p class="content-text">Retrieving nearby {{typeName}}s</p>
              </div>
            </ng-container>
            <ng-template #noClinicBlock>
              <p class="content-text">No {{typeName}}s found, please try a
                different
                area</p>
            </ng-template>
          </ng-template>

        </mat-step>
<!--        <mat-step [completed]="!!selectedGroom">-->
<!--          <ng-template matStepLabel>Choose your groom</ng-template>-->
<!--          <div (click)="showBookerTypeHelper()" *ngIf="isGroomRoom"-->
<!--               style="cursor: pointer" fxLayout="column"-->
<!--               fxLayoutAlign="start start">-->
<!--            <div fxLayout="row" fxLayoutAlign="start start"><h4-->
<!--              class="groom-room-text">Which groom is right-->
<!--              for my dog</h4>-->
<!--              <mat-icon class="groom-room-text clickable"-->
<!--                        style="margin-left:10px;">-->
<!--                help-->
<!--              </mat-icon>-->
<!--            </div>-->
<!--            <p class="no-margin">The type of groom your pet needs can depend-->
<!--              upon-->
<!--              their coat type. <span class="groom-room-text clickable"-->
<!--                                     fxHide.gt-xs="">Touch here</span><span-->
<!--                class="clickable groom-room-text" fxHide.xs>Click here</span> to-->
<!--              see-->
<!--              our grooming guide.</p>-->
<!--          </div>-->
<!--          <app-appt-type-selector [types]="apptTypes"-->
<!--                                  [selectedType]="selectedGroom"-->
<!--                                  (selectedTypeChange)="selectGroomType($event)"></app-appt-type-selector>-->
<!--        </mat-step>-->
<!--        <mat-step>-->
<!--          <ng-template matStepLabel>Check availability</ng-template>-->
<!--          <div fxLayout="column" style="padding-bottom: 20px">-->
<!--            <div fxLayout="row" fxLayoutAlign="center center"-->
<!--                 class="full-width-form-item" style="margin-bottom:20px">-->
<!--              <app-new-calendar [searchResults]="availabilityResults$ | async "-->
<!--                                [loading]="mapService.availabilityLoading$ | async"-->
<!--                                (monthChange)="changeMonth($event)"-->
<!--                                [isRequestType]="false"-->
<!--                                [selectedDate]="selectedDate$ | async"-->
<!--                                (setSelectedDate)="dateSelected($event)"-->
<!--                                [dateOptions]="groomDateOptions"></app-new-calendar>-->

<!--            </div>-->
<!--            <ng-container *ngIf="clipboardSlots$ | async as  clipboardSlots">-->
<!--              <div fxLayout="row wrap" fxLayoutAlign="center center" #times>-->
<!--                <div *ngFor="let item of clipboardSlots">-->
<!--                  <ng-container *ngIf="item === selectedItem; else-->
<!--                unselectedButtonBlock">-->
<!--                    <button mat-raised-button color="accent" style="margin:10px"-->
<!--                            fxLayout="column"-->
<!--                            fxLayoutAlign="center center">{{item.time}}</button>-->
<!--                  </ng-container>-->
<!--                  <ng-template #unselectedButtonBlock>-->
<!--                    <button mat-stroked-button color="accent"-->
<!--                            style="margin:10px" (click)="setSelectedTime(item)"-->
<!--                            fxLayout="column"-->
<!--                            fxLayoutAlign="center center">{{item.time}}</button>-->
<!--                  </ng-template>-->
<!--                </div>-->
<!--              </div>-->
<!--              <ng-container *ngIf="clipboardSlots.length>0">-->
<!--                <div fxLayout="row" fxLayoutAlign="center center"-->
<!--                     style="padding-top:20px">-->
<!--                  <button mat-raised-button (click)="navigateFromBooking()"-->
<!--                          [disabled]="!selectedItem" color="accent">Continue-->
<!--                  </button>-->
<!--                </div>-->
<!--              </ng-container>-->
<!--            </ng-container>-->
<!--          </div>-->
<!--        </mat-step>-->
      </mat-stepper>
    </div>
    <ng-container *ngIf="!noMap && screenWidth >= 700">
      <google-map [center]="center" [options]="options" [zoom]="zoom"
                  [width]="(screenWidth/2)-10" [height]="(height$ | async)">
        <map-marker (mapClick)="selectMapMarker(marker)"
                    *ngFor="let marker of markers" [options]="marker.options"
                    [position]="marker.position"
                    [title]="marker.title"></map-marker>
      </google-map>
    </ng-container>
  </ng-container>
</div>
