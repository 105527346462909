<mat-toolbar color="primary" fxLayout="row"
             fxLayoutAlign="space-between center">
  <h3 class="mat-h3">Appointment deposit</h3>
  <button [mat-dialog-close]="false" aria-label="close" mat-button
          type="button">
    <mat-icon>close</mat-icon>
  </button>
</mat-toolbar>
<mat-dialog-content>
  <div class="booking-deposit-dialog">
    <ng-container *ngIf="loading$ | async">
      <div class="loading">
        <mat-progress-spinner color="accent" diameter="40" mode="indeterminate"></mat-progress-spinner>
      </div>
    </ng-container>
    <ng-container *ngIf="paymentIntentData">
      <div class="mat-body-1 payment-descr">We do require a {{ amount | currency : 'GBP' : 'symbol-narrow' }} deposit to secure your booking.  You can choose how to pay here.</div>
      <ngx-stripe-payment-request-button
        [paymentOptions]="paymentRequestOptions"
        (paymentMethod)="onPaymentMethod($event)"
        (notavailable)="onNotAvailable()"
        containerClass="stripe-button"
      ></ngx-stripe-payment-request-button>
      <div class="or-card-message"><span>Or pay with card</span></div>
      <div class="card-form">
        <ngx-stripe-card
          #card
          [options]="cardOptions"
          [elementsOptions]="elementsOptions"
          containerClass="stripe-card"
        ></ngx-stripe-card>
      </div>
    </ng-container>
    <div class="button-row">
      <ng-container *ngIf="error$ | async">
        <div class="mat-body-1 error">
          <p>{{error$ | async}}</p>
        </div>
      </ng-container>
      <ng-container *ngIf="!(error$ | async)"><div></div></ng-container>

      <ng-container *ngIf="paymentIntentData">
        <button color="primary" mat-raised-button type="button" (click)="submitPayment()">
          <span class="desktop">Pay {{ amount | currency : 'GBP' : 'symbol-narrow' }} & Confirm Booking</span>
          <span class="mobile">Pay {{ amount | currency : 'GBP' : 'symbol-narrow' }} & Book</span>
        </button>
      </ng-container>
    </div>
  </div>
</mat-dialog-content>
