import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MaterialCssVarsService} from 'angular-material-css-vars';
import {RegistrationService} from '../registration.service';
import {ActivatedRoute, Router} from '@angular/router';
import {UntypedFormGroup} from '@angular/forms';
import {ClientFormItem, FIRST_NAME, SURNAME, TITLE} from '../../components/name-entry/name-entry.component';
import {MatStepper} from '@angular/material/stepper';
import {PetFormItem} from '../../patients/add-patient-page/add-patient-page.component';
import {SignupService} from '../../login/signup/signup.service';
import {
  ContactFormItem,
  EMAIL,
  EMAIL_OPT_IN,
  HOME,
  MOBILE,
  MOBILE_OPT_IN
} from '../registration-contact-entries/registration-contact-entries.component';
import {
  ADDRESS_1,
  ADDRESS_2,
  AddressFormItem,
  COUNTY,
  POSTCODE,
  TOWN
} from '../../components/address-lookup/address-lookup.component';
import {environment} from '../../../environments/environment';
import {DomSanitizer} from '@angular/platform-browser';
import {
  AdditionalFormItem,
  CLINIC,
  HOW_FOUND,
  NOTES,
  PASSWORD
} from '../additional-registration-info/additional-registration-info.component';
import {
  RegistrationSummaryCategories,
  RegistrationSummaryItem
} from '../registration-summary/registration-summary.component';
import {format, parse} from 'date-fns';
import {BehaviorSubject, Subscription} from 'rxjs';
import {MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig} from '@angular/material/legacy-dialog';
import {ErrorDialogComponent} from '../../components/shared/error-dialog/error-dialog.component';
import {PassedUser} from '@appyvet/vetbooker-definitions/dist/register_user';
import {BreedItem, ColorItem, SpeciesItem, VbExtendedPatient} from '@appyvet/vetbooker-definitions/dist/patient';
import {RegistrationParams} from '@appyvet/vetbooker-definitions/dist/client';
import {Country} from '@appyvet/vetbooker-definitions/dist/countries';
import {RegistrationRequirements, RegistrationSettings} from '@appyvet/vetbooker-definitions/dist/registrations';
import {RegistrationResource} from '@appyvet/vetbooker-definitions/dist/resource';
import {AddressRequirements} from '@appyvet/vetbooker-definitions/dist/client_patient_details';
import {MarketingContact, MarketingResponse} from '@appyvet/vetbooker-definitions/dist/marketing';
import {GoogleAnalyticsService} from "ngx-google-analytics";


@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit, AfterViewInit, OnDestroy {

  embedded = false;
  landingLogo: string = environment.GROOM ? '/assets/petslogo@2x.png' : environment.VETS_4_PETS
                                                                        ? '/assets/v4p_logo_white.png'
                                                                        : '/assets/logo@2x.png';
  nameFormGroup = new UntypedFormGroup({});
  addressFormGroup = new UntypedFormGroup({});
  contactFormGroup = new UntypedFormGroup({});
  pets: PetFormItem[] = [{patient: null, form: new UntypedFormGroup({}), id: '1'}];
  species: SpeciesItem[];
  regex: string;
  colors: ColorItem[];
  breeds: BreedItem[];
  @ViewChild('stepper') private myStepper: MatStepper;
  clinicCode: string;
  mobileRegex: string;
  homeRegex: string;
  requirements: RegistrationRequirements;
  passedUser: PassedUser;
  addressRequirements: AddressRequirements;
  reminderConsentIntro: string;
  reminderConsentEnabled: boolean;
  clinics: RegistrationResource[];
  additionalFormGroup = new UntypedFormGroup({});
  marketingFormGroup = new UntypedFormGroup({});
  additionalTermsUrl: string;
  marketingChannels: MarketingContact[];
  marketingQuestions: MarketingResponse[];
  communicationChannelIntro: string;
  marketingEnabled: boolean;
  marketingContactsDisabled: boolean;
  marketingQuestionsEnabled: boolean;
  loading$ = new BehaviorSubject<boolean>(false);
  success$ = new BehaviorSubject<boolean>(false);
  registerError$ = new BehaviorSubject<string>(null);
  isGroomRoom: boolean;
  clinicName: string;
  registerUrl: string;
  returnUrl: string;
  country: Country;
  noAdditional = environment.GROOM || environment.VETS_4_PETS;
  private selectionSub: Subscription;
  manualAddressEntry: boolean;


  constructor(private registrationService: RegistrationService, private  materialCssVarsService: MaterialCssVarsService,
              private activatedRoute: ActivatedRoute, private signupService: SignupService,
              private sanitizer: DomSanitizer, private dialog: MatDialog, private router: Router,
              private gaService: GoogleAnalyticsService) {
  }

  getBackgroundStyle() {
    if (!environment.GROOM) {
      if (environment.VETS_4_PETS) {
        return this.sanitizer.bypassSecurityTrustStyle('url(' + this.landingLogo + ') center center/contain no-repeat');
      }
      return this.sanitizer.bypassSecurityTrustStyle('url(' + this.landingLogo + ') center center/cover no-repeat');
    } else {
      return null;
    }
  }

  ngOnDestroy() {
    this.selectionSub?.unsubscribe();
  }

  ngOnInit(): void {
    this.passedUser = this.signupService.unmatchedClient;
    this.activatedRoute.data.subscribe((data: { registrationSettings: RegistrationSettings }) => {
      if (!this.passedUser) {
        this.noAdditional = false;
        if (environment.VETS_4_PETS) {
          // Need to come with prefilled cognito details in V4P so return to login if entered form directly (e.g.
          // refresh). Could have added a route guard, but simpler
          // to put logic here as may change.
          this.router.navigateByUrl('login', /* Removed unsupported properties by Angular migration: queryParams. */
            {});
        }
      }
      this.country = data.registrationSettings.country;
      if (!environment.GROOM && !environment.VETS_4_PETS) {
        const primaryHex = '#3F51B5';
        const accentHex = '#E91E63';
        this.materialCssVarsService.setPrimaryColor(data.registrationSettings.themeSettings.primaryColor || primaryHex);
        this.materialCssVarsService.setAccentColor(data.registrationSettings.themeSettings?.accentColor || accentHex);
        this.materialCssVarsService.setWarnColor('#b4004e');
        if (data.registrationSettings.themeSettings && !data.registrationSettings.isGroomRoom) {
          this.landingLogo = data.registrationSettings.themeSettings?.landingLogo || this.landingLogo;
        }
      }
      this.clinicCode = data.registrationSettings.clinicCode;
      this.manualAddressEntry = data.registrationSettings.manualAddressEntry;
      this.clinicName = data.registrationSettings.themeSettings.customName;
      this.species = data.registrationSettings.species;
      this.breeds = data.registrationSettings.breeds;
      this.colors = data.registrationSettings.colors;
      this.clinics = data.registrationSettings.resources;
      this.regex = data.registrationSettings.country.alphanumericRegex;
      this.homeRegex = data.registrationSettings.country.phoneRegex;
      this.mobileRegex = data.registrationSettings.country.mobileRegex;
      this.requirements = data.registrationSettings.requirements;
      this.reminderConsentEnabled = data.registrationSettings.reminderConsentEnabled;
      this.reminderConsentIntro = data.registrationSettings.reminderConsentIntro;
      this.addressRequirements = {
        address2: data.registrationSettings.requirements?.address2 || true,
        town: data.registrationSettings.requirements?.address3 || false,
        county: data.registrationSettings.requirements?.state || true,
        postcode: data.registrationSettings.requirements?.postcode || true,
      };
      this.additionalTermsUrl = data.registrationSettings.additionalTermsUrl;
      this.marketingQuestions = data.registrationSettings.marketingQuestions;
      this.marketingChannels = data.registrationSettings.marketingChannels;
      this.communicationChannelIntro = data.registrationSettings.communicationChannelIntro;
      this.marketingContactsDisabled = data.registrationSettings.marketingContactsDisabled;
      this.marketingEnabled = data.registrationSettings.marketingEnabled;
      this.marketingQuestionsEnabled = data.registrationSettings.marketingQuestionsEnabled;
      this.isGroomRoom = data.registrationSettings.isGroomRoom;
      this.gaService.event(
        'Registration Started',
        'Registration',
        this.clinicName,
      );
      if (data.registrationSettings.clinicDeactivated) {
        this.router.navigateByUrl('/selector');
      }
    }, e => {
      console.log('error = ', e);
    });
  }

  ngAfterViewInit() {
    this.selectionSub = this.myStepper.selectionChange.subscribe(changedStep => {
      // If navigate off pets step, validate all pets so that the form shows required fields
      if (changedStep?.previouslySelectedStep?.label?.indexOf('pet') !== -1) {
        changedStep.previouslySelectedStep?.stepControl.markAllAsTouched();
      }
    });
  }

  removePet(index: number) {
    if (this.pets.length > 0) {
      this.pets.splice(index, 1);
    }
  }

  clientNext(clientForm: ClientFormItem) {
    this.nameFormGroup = clientForm.form;
    if (!clientForm.firstLoad) {
      this.myStepper.next();
    }
    this.gaService.event(
      'Next on Name Form',
      'Registration',
      this.clinicName,
    );
  }

  onContactNext(submittedForm: ContactFormItem) {
    this.contactFormGroup = submittedForm.form;
    if (!submittedForm.firstLoad) {
      this.myStepper.next();
    }
  }

  savePatient(submittedPetForm: PetFormItem, index: number) {
    this.pets[index] = submittedPetForm;
    this.myStepper.next();
    this.gaService.event(
      'Next on Pet Form',
      'Registration',
      this.clinicName,
    );
  }

  onSaveAddress(addressForm: AddressFormItem) {
    this.addressFormGroup = addressForm.form;
    if (!addressForm.firstLoad) {
      this.myStepper.next();
    }
    this.gaService.event(
      'Next on Address Form',
      'Registration',
      this.clinicName,
    );
  }


  onPatientFormChanged(submittedPetForm: PetFormItem, index: number) {
    this.pets[index].form = submittedPetForm.form;
    if (!submittedPetForm.onlyUpdateForm) {
      this.pets[index].patient = submittedPetForm.patient;
    }
  }

  onSaveAdditional(additionalFormItem: AdditionalFormItem) {
    this.additionalFormGroup = additionalFormItem.form;
    if (!additionalFormItem.firstLoad) {
      this.onRegister();
    }
  }

  makeRandom(length): string {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  onRegister() {
    this.loading$.next(true);
    this.registerError$.next(null);
    const patientsArray: VbExtendedPatient[] = [];
    for (const pet of this.pets) {
      patientsArray.push(pet.patient);
    }
    let resourceId = this.clinics ? this.clinics[0].objectId : null;
    if (this.clinics?.length > 1 && this.additionalFormGroup.get(CLINIC)) {
      resourceId = this.additionalFormGroup.get(CLINIC).value.objectId;
    }
    const registrationItem: RegistrationParams = {
      device: 'web',
      password: environment.VETS_4_PETS ? this.makeRandom(8)
                                        : this.passedUser?.password || this.additionalFormGroup.get(
        PASSWORD)?.value,
      patients: patientsArray,
      clientDetails: {
        surname: this.nameFormGroup.get(SURNAME).value,
        firstName: this.nameFormGroup.get(FIRST_NAME).value,
        title: this.nameFormGroup.get(TITLE).value,
        address1: this.addressFormGroup.get(ADDRESS_1).value,
        address2: this.addressFormGroup.get(ADDRESS_2).value,
        address3: this.addressFormGroup.get(TOWN).value,
        state: this.addressFormGroup.get(COUNTY).value,
        postcode: this.addressFormGroup.get(POSTCODE).value,
        emailAddress: this.passedUser?.email || this.passedUser?.username || this.contactFormGroup.get(EMAIL).value,
        emailOptIn: this.passedUser?.email || this.passedUser?.username ? false : (this.contactFormGroup.get(
          EMAIL_OPT_IN)?.value ?? false),
        mobileOptIn: this.passedUser?.mobile ? false : (this.contactFormGroup.get(MOBILE_OPT_IN)?.value ?? false),
        mobileNumber: this.passedUser?.mobile || (this.contactFormGroup.get(MOBILE)?.value ?? null),
        phoneNumber: this.passedUser ? null : (this.contactFormGroup.get(HOME)?.value ?? null),
        howFound: this.additionalFormGroup.get(HOW_FOUND)?.value,
        notes: this.additionalFormGroup.get(NOTES)?.value,
        marketingChannels: this.marketingChannels,
        marketingQuestions: this.marketingQuestions,
        // TODO - could pass selected code here but not necessary as retrieve later during signup
        clinicId: '0'
      },
      resourceId,
      clinicCode: this.clinicCode
    };
    this.gaService.event(
      'Complete Registration Started',
      'Registration',
      this.clinicName,
    );
    this.registrationService.registerAtClinic$(registrationItem).subscribe(result => {
      this.registerUrl = result.registerUrl;
      this.returnUrl = result.returnUrl;
      this.loading$.next(false);
      this.success$.next(true);
      this.gaService.event(
        'Complete Registration Success',
        'Registration',
        this.clinicName,
      );
    }, error => {
      this.loading$.next(false);
      const errorOptions = new MatDialogConfig();
      errorOptions.data = {
        error,
        title: 'Error during registration'
      };
      this.dialog.open(ErrorDialogComponent, errorOptions);
      this.registerError$.next(error);
      this.gaService.event(
        'Complete Registration Error',
        'Registration',
        this.clinicName,
      );
    });
  }

  getRegistrationFormSummary(): RegistrationSummaryCategories[] {
    const summaryItems: RegistrationSummaryCategories[] = [];
    const nameCategoryItems: RegistrationSummaryItem[] = [];
    const addressCategoryItems: RegistrationSummaryItem[] = [];
    const marketingItems: RegistrationSummaryItem[] = [];
    const marketingQuestions: RegistrationSummaryItem[] = [];
    Object.keys(this.nameFormGroup.controls).forEach(key => {
      nameCategoryItems.push({name: key, value: this.nameFormGroup.get(key)?.value || '(none)'});
    });
    summaryItems.push({name: 'Name', items: nameCategoryItems});
    Object.keys(this.addressFormGroup.controls).forEach(key => {
      addressCategoryItems.push({name: key, value: this.addressFormGroup.get(key)?.value || '(none)'});
    });
    summaryItems.push({name: 'Address', items: addressCategoryItems});
    for (const pet of this.pets) {
      if (pet.patient) {
        const petItems: RegistrationSummaryItem[] = [];
        petItems.push({name: 'Name', value: pet.patient.name || '(none)'});
        petItems.push({name: 'Species', value: pet.patient.breedItem?.speciesItem?.name || '(none)'});
        petItems.push({name: 'Breed', value: pet.patient.breedItem?.name || '(none)'});
        petItems.push({name: 'Sex', value: pet.patient.sex + (pet.patient.desexed ? ' Neutered' : ' Entire')});
        if (this.requirements.showColor) {
          petItems.push({name: 'Color', value: pet.patient.color || '(none)'});
        }
        petItems.push({
          name: 'Date of birth',
          value: format(parse(pet.patient.dateOfBirth, 'yyyy-MM-dd', new Date(pet.patient.dateOfBirth)), 'dd/MM/yyyy')
        });
        if (this.requirements.showMicrochip) {
          petItems.push({name: 'Microchip', value: pet.patient.microchip || '(none)'});
        }
        if (this.requirements.showInsured) {
          petItems.push({name: 'Insurance', value: pet.patient.insurer || '(none)'});
        }
        if (this.requirements.showLastVac) {
          petItems.push({
            name: 'Last Vaccination',
            value: pet.patient.lastVaccinated ? format(pet.patient.lastVaccinated, 'dd/MM/yyyy') : '(none)'
          });
        }
        summaryItems.push({name: pet.patient.name || 'Pet', items: petItems});
      }
    }
    if (this.marketingEnabled) {
      if (!this.marketingContactsDisabled) {
        for (const marketingChannel of this.marketingChannels) {
          if (marketingChannel.allowed) {
            marketingItems.push({name: marketingChannel.type, value: null});
          }
        }
        summaryItems.push({name: 'Allowed marketing channels', items: marketingItems});
      }
      if (this.marketingQuestionsEnabled) {
        for (const marketingQuestion of this.marketingQuestions) {
          if (marketingQuestion.allowed) {
            marketingQuestions.push({name: marketingQuestion.questionText, value: null});
          }
        }
        summaryItems.push({name: 'Agreed to:', items: marketingQuestions});
      }
    }
    return summaryItems;
  }

  formCompleted() {
    let allPetsValid = true;
    for (const pet of this.pets) {
      if (!pet.form.valid) {
        allPetsValid = false;
      }
    }
    return this.additionalFormGroup.valid && this.nameFormGroup.valid &&
      this.additionalFormGroup.valid && (this.passedUser || this.contactFormGroup.valid) && allPetsValid;
  }

  petAdded($event: number) {
    this.gaService.event(
      'Pet Added',
      'Registration',
      this.clinicName,
    );
    this.myStepper.selectedIndex = this.myStepper.selectedIndex +1;
    // Need timeout here or can't navigate to the newly added pet.
    setTimeout(() => this.selectPet($event), 300);
  }

  selectPet($event: number) {
    this.myStepper.steps.forEach(step => {
      if (step.label === 'pet' + $event) {
        this.myStepper.selected = step;
      }
    });
  }

  handelFinalStepBack() {
    this.myStepper.previous();
  }
}
