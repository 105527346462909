import {Injectable} from '@angular/core';
import {SessionService} from './session-service.service';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import * as Parse from 'parse';
import {addMinutes, format, isAfter, parse, parseISO} from 'date-fns';
import {AuthService} from '../auth/auth.service';
import {environment} from '../../environments/environment';
import {ParseErrorHandler} from '../parse-error-handler';
import {CookieService} from 'ngx-cookie-service';
import {AVAILABILITY_COOKIE} from '../../constants';
import {YearlyHistoryItem} from '../interfaces/local-interfaces';
import {
  BookingItem, CancellationAppointmentItem,
  GetBookingSlotsParams, MultiAppointmentItem,
  VbBookableItem
} from '@appyvet/vetbooker-definitions/dist/bookings';
import {
  AppointmentHistoryItem,
  FirstAvailableParams,
  FirstAvailableResult, PatientRemindersSlotResult,
  PurePatient, SelectedPatient, Weight
} from '@appyvet/vetbooker-definitions/dist/appointments';
import {AppointmentType, GroomAppointmentType} from '@appyvet/vetbooker-definitions/dist/appointment_type';
import {Resource} from '@appyvet/vetbooker-definitions/dist/resource';
import {VbClient} from '@appyvet/vetbooker-definitions/dist/client';
import {BreedItem, VbExtendedPatient} from '@appyvet/vetbooker-definitions/dist/patient';
import {AppointmentSettings, ClientPatientDetails} from '@appyvet/vetbooker-definitions/dist/client_patient_details';
import {GetMonthSlotsResult} from '@appyvet/vetbooker-definitions/definitions/interfaces/bookings';
import {GoogleAnalyticsService} from "ngx-google-analytics";

export const EMERGENCY_APPT_TYPE = 'Emergency Appointment';
const LONG_DATE_TIME_FORMAT = 'eeee do MMMM y \'at\' HH:mm';
const SHORT_DATE_TIME_FORMAT = 'eeee do MMMM y';

export interface AvailabilityParams {
  item: VbBookableItem;
  groom: GroomAppointmentType;
  setTime: Date;
}

@Injectable({
  providedIn: 'root'
})


export class BookingService {

  upcomingAppointments$ = new BehaviorSubject<AppointmentHistoryItem[]>([]);
  unconfirmedAppointments$ = new BehaviorSubject<AppointmentHistoryItem[]>([]);
  previousAppointments$ = new BehaviorSubject<YearlyHistoryItem[]>([]);
  isEdit: boolean;
  editingAppointment$ = new BehaviorSubject<MultiAppointmentItem>(null);
  appointmentUpdateDone$ = new BehaviorSubject<boolean>(false);
  sendingRequest$ = new BehaviorSubject<boolean>(false);
  appointmentSettings$ = new BehaviorSubject<AppointmentSettings>(null);
  appointmentTypes$ = new BehaviorSubject<AppointmentType[]>([]);
  resources$ = new BehaviorSubject<Resource[]>([]);
  error$ = new BehaviorSubject<string>('');
  private clientPatientDetails: ClientPatientDetails;
  private appointmentHistory: MultiAppointmentItem[] = [];
  isReferral: boolean;
  isGroomRoom: boolean;
  isGroomRoom$ = new BehaviorSubject<boolean>(null);
  client: VbClient;
  clientHasDeposit$ = new BehaviorSubject<boolean>(false);
  nextApptAnalysis: string;
  clinicName: string;
  isRequestClinic: boolean;
  private availabilityType: GroomAppointmentType;
  private availabilityItem: VbBookableItem;
  private availabilitySetTime: Date;
  emergencyEndDate: Date;
  clinicTel: string;

  constructor(private sessionService: SessionService, private authService: AuthService,
              private gaService: GoogleAnalyticsService, private cookieService: CookieService) {
    this.isEdit = false;
    this.sessionService.clientPatientDetails$.subscribe(clientPatientDetails => {
      if (clientPatientDetails) {
        this.clientPatientDetails = clientPatientDetails;
        this.emergencyEndDate = clientPatientDetails.clinicDetails?.emergencyEndDate ? new Date(
          clientPatientDetails.clinicDetails?.emergencyEndDate) : null;
        this.client = clientPatientDetails.clientData.client;
        this.clientHasDeposit$.next(clientPatientDetails.clientHasDeposit);
        if (clientPatientDetails.appointmentHistory) {
          this.appointmentHistory = clientPatientDetails.appointmentHistory;
          this.loadAppointmentData();
        }
        this.appointmentSettings$.next(clientPatientDetails.appointmentSettings);
        this.isReferral = clientPatientDetails.isReferral;
        this.isGroomRoom$.next(clientPatientDetails.isGroomRoom);
        this.isGroomRoom = clientPatientDetails.isGroomRoom;
        const resources: Resource[] = [];
        for (let i = 0; i < clientPatientDetails.resources.length; i++) {
          if (clientPatientDetails.resources[i].isBookable || clientPatientDetails.isGroomRoom) {
            if (this.clientPatientDetails.resources[i].listType) {
              this.isRequestClinic = true;
            }
            resources.push(this.clientPatientDetails.resources[i]);
          }
        }
        this.resources$.next(resources);
        const apptsArray = clientPatientDetails.appointmentTypes;
        if (environment.VETS_4_PETS) {
          apptsArray.push({displayName: EMERGENCY_APPT_TYPE});
        }
        this.appointmentTypes$.next(apptsArray);
        this.nextApptAnalysis = clientPatientDetails.nextApptAnalysis;
        this.clinicName = clientPatientDetails.themeSettings?.customName || 'VetBooker';
        this.clinicTel = clientPatientDetails.clinicDetails?.phoneNumber;
      }
    });
  }

  convertExtendPatientToPurePatient(patient: VbExtendedPatient): PurePatient {
    return {
      patientNumber: patient.patientNumber,
      patientName: patient.name,
      patientBreed: patient.breedItem,
      dateOfBirth: patient.dateOfBirth ? new Date(patient.dateOfBirth) : undefined
    };
  }

  setEditingAppointmentById(apptId: string) {
    const editingAppt = this.appointmentHistory.find(appt => appt.objectId === apptId);
    this.editingAppointment$.next(editingAppt);
  }

  dateSortAppointments(appointments: AppointmentHistoryItem[]): YearlyHistoryItem[] {
    const returnArray: YearlyHistoryItem[] = [];
    appointments.forEach(appt => {
      let yearMatch = false;
      const apptMonth = format(appt.date, 'MMM');
      returnArray.forEach(existingYear => {
        if (existingYear.year === appt.date.getFullYear()) {
          yearMatch = true;
          let monthMatch = false;
          existingYear.months.forEach(existingMonth => {
            if (existingMonth.month === apptMonth) {
              monthMatch = true;
              existingMonth.appointments.push(appt);
            }
          });
          if (!monthMatch) {
            existingYear.months.push({appointments: [appt], month: apptMonth});
          }
        }
      });
      if (!yearMatch) {
        returnArray.push({
          months: [{appointments: [appt], month: apptMonth}],
          year: appt.date.getFullYear()
        });
      }
    });
    return returnArray;
  }

  loadAppointmentData() {
    const upcomingAppointments = new Array<AppointmentHistoryItem>();
    const unconfirmedAppointments = new Array<AppointmentHistoryItem>();
    const previousAppointments = new Array<AppointmentHistoryItem>();
    this.appointmentHistory.forEach(appointmentItem => {
      const parsedAppointment = this.parseAppointment(appointmentItem);
      if (!appointmentItem.confirmed) {
        unconfirmedAppointments.push(parsedAppointment);
      } else {
        if (isAfter(parsedAppointment.date, new Date())) {
          upcomingAppointments.push(parsedAppointment);
        } else {
          previousAppointments.push(parsedAppointment);
        }
      }
    });
    previousAppointments.sort((a, b) => b.date.valueOf() - a.date.valueOf());
    upcomingAppointments.sort((a, b) => a.date.valueOf() - b.date.valueOf());
    const dateSortedUpcomingAppointments = this.dateSortAppointments(previousAppointments);
    const dateSortedPreviousAppointments = this.dateSortAppointments(previousAppointments);

    this.upcomingAppointments$.next(upcomingAppointments);
    this.unconfirmedAppointments$.next(unconfirmedAppointments);
    this.previousAppointments$.next(dateSortedPreviousAppointments);
  }

  getFormattedDate(appointmentItem: MultiAppointmentItem) {
    if (appointmentItem.confirmed) {
      return format(parseISO(appointmentItem.clinicDateTime), 'eeee do MMMM y');
    } else {
      return format(parseISO(appointmentItem.clinicDateTime), 'eeee do MMMM y [at] H:mm a');
    }
  }

  parseAppointment(appointmentItem: MultiAppointmentItem): AppointmentHistoryItem {
    let mapLink = null;
    let apptResource = null;
    let appointmentDetails = '';
    // The date received is not a true date, it has no time zone, but is rather "local time". If left with the 'Z'
    // this becomes problematic during DST. Trim off the Z. Cannot do on backend or breaks V4P app
    // appointmentItem.date = appointmentItem.utcDate;
    // appointmentItem.clinicDateTime = appointmentItem.clinicDateTime ? appointmentItem.date
    //                                                                 : appointmentItem.clinicDateTime.split('Z')[0];
    this.clientPatientDetails.resources.forEach(resource => {
      if (resource.objectId === appointmentItem.resourceId) {
        apptResource = resource;
        if (resource.mapUrl) {
          mapLink = resource.mapUrl;
        } else if (resource.postcode) {
          let clinicLocation;
          if (this.clientPatientDetails.currency === 'aud') {
            clinicLocation = 'Australia';
          }
          if (this.clientPatientDetails.currency === 'usd') {
            clinicLocation = 'United States';
          }
          if (this.clientPatientDetails.currency === 'gbp') {
            clinicLocation = 'UK';
          }
          mapLink = 'https://maps.google.com?daddr=' + resource.postcode + ', ' + clinicLocation;
        }
      }
    });
    let hasNotes = false;
    for (let i = 0; i < appointmentItem.appointments.length; i++) {
      const appt = appointmentItem.appointments[i];
      if (appt.notes && appt.notes.length > 0) {
        hasNotes = true;
      }
      this.clientPatientDetails.clientData.patients.forEach(patient => {
        //Occasionally only receive name not patient ID (e.g. Robovet), so try match on both name or number
        if (patient.patientNumber === appt.patientNumber || patient.name === appt.patientNumber) {
          appointmentItem.appointments[i].patient = patient;
          appointmentDetails += patient.name + (appt.apptType ? ' for a ' + appt.apptType : '');
          if (appointmentItem.appointments.length > 1 && i === appointmentItem.appointments.length - 2) {
            appointmentDetails += ' and ';
          } else if (i < appointmentItem.appointments.length - 2) {
            appointmentDetails += ', ';
          }
        }
      });
    }
    //Note, created date is empty is PMS appt as don't always know when it was made.
    let createdDate = null;
    if (appointmentItem.created) {
      createdDate = format(parseISO(appointmentItem.created), 'dd/MM/yyyy HH:mm');
    }
    const clipboard = appointmentItem.appointments[0].clipboard;
    return {
      appointmentItem,
      date: new Date(appointmentItem.clinicDateTime),
      mapLink,
      dateString: this.getFormattedDate(appointmentItem),
      fullDetails: this.getFullDetailsString(apptResource, appointmentDetails, clipboard),
      createdDate,
      resourceName: apptResource?.name,
      patientDetails: appointmentDetails,
      clipboardName: clipboard,
      hasNotes,
    };
  }

  getFullDetailsString(resource: Resource, appointmentDetails: string, clipboard: string) {
    return resource ? resource.name + ' for ' + appointmentDetails + (clipboard ?
                                                                      ' with ' + clipboard : '') : appointmentDetails;
  }

  indefiniteArticle(phrase) {

// Getting the first word
    const match = /\w+/.exec(phrase);
    let word;
    if (match) {
      word = match[0];
    } else {
      return 'an';
    }

    const lWord = word.toLowerCase();
// Specific start of words that should be preceeded by 'an'
    const altCases = ['honest', 'hour', 'hono'];
    for (const i in altCases) {
      if (lWord.indexOf(altCases[i]) === 0) {
        return 'an';
      }
    }

// Single letter word which should be preceeded by 'an'
    if (lWord.length === 1) {
      if ('aedhilmnorsx'.indexOf(lWord) >= 0) {
        return 'an';
      } else {
        return 'a';
      }
    }

// Capital words which should likely be preceeded by 'an'
    if (word.match(
      /(?!FJO|[HLMNS]Y.|RY[EO]|SQU|(F[LR]?|[HL]|MN?|N|RH?|S[CHKLMNPTVW]?|X(YL)?)[AEIOU])[FHLMNRSX][A-Z]/)) {
      return 'an';
    }

// Special cases where a word that begins with a vowel should be preceeded by 'a'
    const regexes = [/^e[uw]/, /^onc?e\b/, /^uni([^nmd]|mo)/, /^u[bcfhjkqrst][aeiou]/];
    for (const i in regexes) {
      if (lWord.match(regexes[i])) {
        return 'a';
      }
    }

// Special capital words (UK, UN)
    if (word.match(/^U[NK][AIEO]/)) {
      return 'a';
    } else if (word === word.toUpperCase()) {
      if ('aedhilmnorsx'.indexOf(lWord[0]) >= 0) {
        return 'an';
      } else {
        return 'a';
      }
    }

// Basic method of words that begin with a vowel being preceeded by 'an'
    if ('aeiou'.indexOf(lWord[0]) >= 0) {
      return 'an';
    }

// Instances where y follwed by specific letters is preceeded by 'an'
    if (lWord.match(/^y(b[lor]|cl[ea]|fere|gg|p[ios]|rou|tt)/)) {
      return 'an';
    }

    return 'a';
  }

  getCleanPatients(selectedPatients: SelectedPatient[]):SelectedPatient[] {
    return selectedPatients.map(selectedPatient => {
      return {
        reason: selectedPatient.reason,
        selectedAppointmentType: {
          objectId: selectedPatient.selectedAppointmentType.objectId,
          displayName: selectedPatient.selectedAppointmentType.displayName,
          minAge: selectedPatient.selectedAppointmentType.minAge,
          maxAge: selectedPatient.selectedAppointmentType.maxAge,
        },
        patient: {
          patientName: selectedPatient.patient.patientName,
          patientNumber: selectedPatient.patient.patientNumber,
          patientBreed: selectedPatient.patient.patientBreed,
          dateOfBirth: selectedPatient.patient.dateOfBirth
        },
        notes: selectedPatient.notes
      };
    });
  }

  getPatientBookingText(selectedPatients: SelectedPatient[]) {
    let returnText = '';
    for (let i = 0; i < selectedPatients.length; i++) {
      const patient = selectedPatients[i];
      returnText += patient.patient.patientName + ' for a ' + patient.selectedAppointmentType?.displayName;
      if (i < selectedPatients.length - 2) {
        returnText += ', ';
      } else if (i === selectedPatients.length - 2) {
        returnText += ' and ';
      }
    }
    return returnText;
  }

  getBookingText(bookingItem: BookingItem, selectedResource: Resource): string {
    const formattedDate = format(parseISO(bookingItem.item.clinicDateTime.split('Z')[0]), 'eeee do MMMM y');
    const patients = bookingItem.selectedPatients;

    let text = `This is to confirm that you are booking an appointment for
      ${patients[0].patient.patientName} for a ${patients[0].selectedAppointmentType?.displayName}
      ${selectedResource.listType ? `on the ${bookingItem.item.time} of ` : ` at ${bookingItem.item.time}`} on ${formattedDate}.`;

    if (patients.length > 1) {
      const extraPatientStrings: string[] = [];
      patients.forEach((patient, i) => {
        if (i > 0) {
          extraPatientStrings.push(`${patients[i].patient.patientName} for a ${patients[i].selectedAppointmentType?.displayName}`);
        }
      });

      text += ' You are also booking an appointment for ';
      text += extraPatientStrings.join(', ').replace(/, ([^,]*)$/, ' and $1');

      text += `. They will be booked on to the next available appointment${patients.length > 2 ? 's' : ''} after ${bookingItem.selectedPatients[0].patient.patientName}'s. Please check Upcoming Appointments on your online account for the exact details of your bookings.`;
    }

    text += ` The appointment${patients.length > 1 ? 's are' : ' is'} being made at ${selectedResource.name}.`

    return text;
  }

  setEditingAppointment(appointment: AppointmentHistoryItem) {
    this.editingAppointment$.next(appointment.appointmentItem);
  }


  getPatientsAppointmentTextFromRequest(appt: MultiAppointmentItem) {
    let patientText = '';
    let patients = '';
    for (let i = 0; i < appt.appointments.length; i++) {
      const appointment = appt.appointments[i];
      const prefix = this.indefiniteArticle(appointment.apptType);
      patientText += appointment.patient.name + ' to get ' + prefix + ' ' + appointment.apptType;
      patients += appointment.patient.name;
      if (i < appt.appointments.length - 2) {
        patients += ', ';
        patientText += ', ';
      } else if (i === appt.appointments.length - 2) {
        patients += ' and ';
        patientText += ' and ';
      }
    }
    return {patientText, patients};
  }

  getPatientsAppointmentTextFromItem(appt: BookingItem) {
    let patientText = '';
    let patients = '';
// Format of appts is different if multi pet whether has already been parsed and is an array of appointments, or if is a
// new appt and is an array of selected patients
    for (let i = 0; i < appt.selectedPatients?.length; i++) {
      const appointment = appt.selectedPatients[i];
      const prefix = this.indefiniteArticle(appointment.selectedAppointmentType.displayName);
      patientText += appointment.patient.patientName + ' to get ' + prefix + ' ' + appointment.selectedAppointmentType.displayName;
      patients += appointment.patient.patientName;
      if (i < appt.selectedPatients.length - 2) {
        patients += ', ';
        patientText += ', ';
      } else if (i === appt.selectedPatients.length - 2) {
        patients += ' and ';
        patientText += ' and ';
      }
    }
    return {patientText, patients};
  }

  getAmendApptText(appointment: BookingItem, resource: Resource) {
    const originalAppointment = this.editingAppointment$.getValue();
    const originalPatientText = this.getPatientsAppointmentTextFromRequest(originalAppointment);
    const newPatientText = this.getPatientsAppointmentTextFromItem(appointment);
    let returnText = 'This is to confirm that you are changing your existing appointment for ' + originalPatientText.patientText
      + ' on ' + format(parseISO(originalAppointment.clinicDateTime.split('Z')[0]), LONG_DATE_TIME_FORMAT)
      + ' at ' + originalAppointment.resourceName + ' to ';
    let patientMatch = 0;
    appointment.selectedPatients.forEach(booking => {
      originalAppointment.appointments.forEach(originalAppt => {
        if (originalAppt.patient.name === booking.patient.patientName) {
          patientMatch++;
        }
      });
    });
    if (patientMatch === 0 || patientMatch !== appointment.selectedPatients.length) {
      returnText += 'an appointment for ' + newPatientText.patientText + ' at ';
    }
    returnText += format(parseISO(appointment.item.clinicDateTime.split('Z')[0]),
      SHORT_DATE_TIME_FORMAT) + ' at ' + appointment.item.time + '.';
    if (resource.name !== originalAppointment.resourceName) {
      returnText += ' The appointment has also been changed to ' + resource.name + '.';
    }
    // if (appointment.selectedPatients[0].selectedAppointmentType.displayName !== originalAppointment.apptType) {
    //   returnText += 'a ' + appointment.selectedPatients[0].selectedAppointmentType.displayName;
    // }
    return returnText;
  }

  addSingleAppointmentLocally(appointment: MultiAppointmentItem) {
    this.appointmentHistory.push(appointment);
    this.loadAppointmentData();
  }

  deleteSingleAppointmentLocally(appointment: MultiAppointmentItem) {
    let matchedParts = 0;
    this.appointmentHistory.forEach((appointmentToCheck, i) => {
      appointmentToCheck.appointments.forEach((apptPartToCheck) => {
        appointment.appointments.forEach(appointmentToDelete => {
          //Part of the multi part appointment matches, so remove the whole thing
          if (apptPartToCheck.apptId === appointmentToDelete.apptId) {
            matchedParts++
          }
        });
      });
      if(matchedParts === appointmentToCheck.appointments.length){
        this.appointmentHistory.splice(i, 1);
      }
    });
    this.loadAppointmentData();
  }

  deleteAppointmentsLocally(appointmentsToDelete: MultiAppointmentItem[]) {
    for (let j = 0; j < appointmentsToDelete.length; j++) {
      for (let i = 0; i < this.appointmentHistory.length; i++) {
        const appointmentToCheck = this.appointmentHistory[i];
        for (let k = 0; k < appointmentToCheck.appointments.length; k++) {
          if (appointmentsToDelete[j].apptId === appointmentToCheck.appointments[k].apptId
            || appointmentsToDelete[j].apptId === appointmentToCheck.appointments[k].apptId2) {
            appointmentToCheck.appointments.splice(k, 1);
          }
        }
      }
    }
    this.loadAppointmentData();
  }

  deleteAppointmentsLocallyById(appointmentsToDelete: string[]) {
    appointmentsToDelete.forEach((appointmentToDelete) => {
      this.appointmentHistory.forEach((appointmentToCheck) => {
        for (let k = 0; k < appointmentToCheck.appointments.length; k++) {
          if (appointmentToDelete === appointmentToCheck.appointments[k].apptId
            || appointmentToDelete === appointmentToCheck.appointments[k].apptId2) {
            appointmentToCheck.appointments.splice(k, 1);
          }
        }
      });
    });
    this.loadAppointmentData();
  }

  cancelAppointment(appointment: CancellationAppointmentItem): Observable<boolean> {
    const cancelAppointmentDone$ = new BehaviorSubject<boolean>(false);

    Parse.Cloud.run('deleteBooking', appointment, {sessionToken: this.authService.getSessionToken()}).then(() => {
      this.gaService.event(
        'Cancel Appointment Completed',
        'Appointment History',
        this.clinicName
      );
      this.deleteSingleAppointmentLocally(appointment.appointment);
      cancelAppointmentDone$.next(true);
    }, error => {
      this.gaService.event(
        'Cancel Appointment Error',
        'Appointment History',
        this.clinicName
      );
      cancelAppointmentDone$.error(error);
    });
    return cancelAppointmentDone$;
  }

  resendAppointmentEmail(appointment: MultiAppointmentItem): Observable<boolean> {
    this.error$.next('');
    const resendAppointmentEmailDone$ = new BehaviorSubject<boolean>(false);
    Parse.Cloud.run('resendAppointmentEmail', {appointment},
      {sessionToken: this.authService.getSessionToken()}).then(() => {
      resendAppointmentEmailDone$.next(true);
    }, ((error) => {
      resendAppointmentEmailDone$.error(error);
      this.error$.next(error.message);
    }));
    return resendAppointmentEmailDone$;
  }

  checkin(appointment: MultiAppointmentItem): Observable<boolean> {
    this.error$.next('');
    const checkinDone$ = new BehaviorSubject<boolean>(false);
    Parse.Cloud.run('checkinAppointment', {appointment},
      {sessionToken: this.authService.getSessionToken()}).then(() => {
      appointment.checkedIn = true;
      checkinDone$.next(true);
    }, ((error) => {
      checkinDone$.error(ParseErrorHandler.handleParseError(error));
      this.error$.next(error.message);
    }));
    return checkinDone$;
  }

  getFirstAvailableAppointment$(params: FirstAvailableParams): Observable<FirstAvailableResult> {
    const firstAvailableResult$ = new Subject<FirstAvailableResult>();
    Parse.Cloud.run('getFirstAvailableSlot', params, {sessionToken: this.authService.getSessionToken()})
      .then(result => {
        const slotsResult = result as FirstAvailableResult;
        firstAvailableResult$.next(slotsResult);
      }, ((error) => {
        firstAvailableResult$.error(error);
        this.error$.next(error.message);
      }));
    return firstAvailableResult$;
  }

  getVaccineSlotsForClientPatients(): Observable<PatientRemindersSlotResult[]> {
    const vaccineSlotRequest$ = new Subject<PatientRemindersSlotResult[]>();
    Parse.Cloud.run('getVaccineSlotsForClientPatients', null, {sessionToken: this.authService.getSessionToken()})
      .then((result: PatientRemindersSlotResult[]) => {
        vaccineSlotRequest$.next(result);
      }, ((error) => {
        vaccineSlotRequest$.error(error);
        this.error$.next(error.message);
      }));
    return vaccineSlotRequest$;
  }

  getSlots(bookingItem: GetBookingSlotsParams): Observable<GetMonthSlotsResult> {
    this.error$.next('');
    const clipboardSlotResult$ = new Subject<GetMonthSlotsResult>();
    const parsedBooking = Object.assign(bookingItem, {});
    parsedBooking.patients = this.getCleanPatients(parsedBooking.patients);
    Parse.Cloud.run('getSlotsForMonth', bookingItem, {sessionToken: this.authService.getSessionToken()})
      .then(result => {
        const slotsResult = result as GetMonthSlotsResult;
        clipboardSlotResult$.next(slotsResult);
      }, error => {
        const parsedError = ParseErrorHandler.handleParseError(error);
        clipboardSlotResult$.error(parsedError.message);
      });
    return clipboardSlotResult$.asObservable();
  }

  bookAppt(booking: BookingItem): Observable<boolean> {
    const parsedBooking = Object.assign(booking, {});
    const appointmentUpdateDone$ = new BehaviorSubject<boolean>(false);
    const aptSettings = this.appointmentSettings$.getValue();
    this.clientHasDeposit$.next(true);
    this.sendingRequest$.next(true);
    parsedBooking.selectedPatients = this.getCleanPatients(parsedBooking.selectedPatients);
    Parse.Cloud.run('addClipboardSlotBooker', booking, {sessionToken: this.authService.getSessionToken()})
      .then(result => {
        this.addSingleAppointmentLocally(result);
        appointmentUpdateDone$.next(true);
        this.gaService.event(
          'Book New Appointment',
          'Appointment Booker',
          aptSettings?.takeDeposit && aptSettings?.depositAmount ? `${this.clinicName} - £${aptSettings.depositAmount} deposit` : `${this.clinicName} - No deposit`
        );
      }, error => {
        this.gaService.event(
          'Book New Appointment Error',
          'Appointment Booker',
          this.clinicName
        );
        appointmentUpdateDone$.error(error);
        this.appointmentUpdateDone$.error(error);
      }).finally(() => {
      this.appointmentUpdateDone$.next(true);
      this.sendingRequest$.next(false);
    });
    return appointmentUpdateDone$;
  }

  amendAppt(booking: BookingItem): Observable<boolean> {
    this.sendingRequest$.next(true);
    const originalAppt = Object.assign({}, this.editingAppointment$.getValue());
    const parsedBooking = Object.assign(booking, {});
    const appointmentUpdateDone$ = new BehaviorSubject<boolean>(false);
    parsedBooking.selectedPatients = this.getCleanPatients(parsedBooking.selectedPatients);
    const parsedOriginalBooking: CancellationAppointmentItem = {
      objectId: originalAppt.objectId,
      reasonId: 2,
      reasonText: 'Amend',
      appointment: originalAppt
    };
    Parse.Cloud.run('amendBooking', {
      bookerObject: parsedBooking,
      deleteObject: parsedOriginalBooking
    }, {sessionToken: this.authService.getSessionToken()}).then(result => {
       this.gaService.event(
        'Book Amend Appointment',
        'Appointment Booker',
        this.clinicName
      );
      this.deleteSingleAppointmentLocally(originalAppt);
      this.addSingleAppointmentLocally(result);
    }, error => {
       this.gaService.event(
        'Book Amend Appointment Error',
        'Appointment Booker',
        this.clinicName
      );
      this.appointmentUpdateDone$.error(error);
    }).finally(() => {
      this.appointmentUpdateDone$.next(true);
      this.sendingRequest$.next(false);
    });
    return appointmentUpdateDone$;
  }

  getAvailabilityParams(): AvailabilityParams {
    if (this.cookieService.check(AVAILABILITY_COOKIE)) {
      return JSON.parse(this.cookieService.get('grob_availabilityItem'));
    } else {
      return null;
    }
  }

  storeAvailabilityParams(selectedItem: VbBookableItem, selectedGroom: GroomAppointmentType) {
    this.availabilitySetTime = new Date();
    this.availabilityItem = selectedItem;
    this.availabilityType = selectedGroom;
    this.cookieService.set('grob_availabilityItem',
      JSON.stringify({item: selectedItem, groom: selectedGroom, setTime: this.availabilitySetTime}),
      addMinutes(this.availabilitySetTime, 15));
  }

  getGroomBreedData(patient: PurePatient): BreedItem {
    return this.clientPatientDetails.breeds.find(breed => breed.id === patient.patientBreed.id);
  }

  getBookingDepositPaymentIntent(): Observable<{ pi_secret: string, pi_id: string }> {
    const paymentIntent$ = new Subject<{ pi_secret: string, pi_id: string }>();
    Parse.Cloud.run('getBookingDepositPaymentIntent', {}, {sessionToken: this.authService.getSessionToken()})
      .then(result => {
        paymentIntent$.next(result);
      }, ((error) => {
        paymentIntent$.error(error);
        this.error$.next(error.message);
      }));
    return paymentIntent$;
  }
}
