import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {BehaviorSubject} from 'rxjs';
import PlaceResult = google.maps.places.PlaceResult;
import {AddressObject} from '@appyvet/vetbooker-definitions/dist/register_user';
import {AddressRequirements} from '@appyvet/vetbooker-definitions/dist/client_patient_details';

export interface AddressFormItem {
  address: AddressObject;
  form: UntypedFormGroup;
  firstLoad?: boolean;
}

export const ADDRESS_1 = 'Address Line 1';
export const ADDRESS_2 = 'Address Line 2';
export const TOWN = 'Town';
export const COUNTY = 'County/State';
export const POSTCODE = 'Postcode';


@Component({
  selector: 'app-address-lookup',
  templateUrl: './address-lookup.component.html',
  styleUrls: ['./address-lookup.component.scss']
})
export class AddressLookupComponent implements OnInit {
  addressSearchForm: UntypedFormGroup;
  alphanumericRegexp = RegExp('^[A-Za-z0-9 _-]*[A-Za-z0-9-][A-Za-z0-9 _-]*$');
  addressForm: UntypedFormGroup;

  @Input() showAddressForm: boolean;
  @Input() countryCode: string;
  @Input() address: AddressObject;
  @Input() isNotClientDetailsPage: boolean;
  @Input() clinicCode: string;
  @Input() initialPostcode: string;
  @Input() requirements: AddressRequirements;
  @Input() addressChangeText: string;

  addressValidationMessages = {
    address1: [
      {type: 'required', message: 'You must enter the first line of your address'},
      {type: 'pattern', message: 'You cannot include special characters, only numbers, letters and spaces'},
    ],
    town: [
      {type: 'required', message: 'You must enter a town'},
    ],
    county: [
      {type: 'required', message: 'You must enter a county'},
    ],
    postcode: [
      {type: 'required', message: 'You must enter a postcode'},
      {type: 'pattern', message: `Oops, looks like we didn't recognize that as a postcode`},
      {type: 'maxlength', message: 'Postcode must be less than 8 characters'},
      {type: 'minlength', message: 'Postcode must be at least 6 characters'},
    ],
  };

  @Output() addressCancel = new EventEmitter<boolean>();
  @Output() addressSave = new EventEmitter<AddressFormItem>();
  loadingAddress$ = new BehaviorSubject<boolean>(false);
  selectedAddress: AddressObject;
  ADDRESS_1 = ADDRESS_1;
  ADDRESS_2 = ADDRESS_2;
  TOWN = TOWN;
  COUNTY = COUNTY;
  POSTCODE = POSTCODE;


  constructor() {
  }

  ngOnInit(): void {
    if (!this.addressChangeText) {
      this.addressChangeText = 'Are you sure you need to change your address? Remember, altering your details here' +
        ' will update your customer record.';
    }
    this.addressSearchForm = new UntypedFormGroup({
      address1: new UntypedFormControl(''),
      postcode: new UntypedFormControl(this.initialPostcode || '', [Validators.required])
    });
    const validationArray = [Validators.required, Validators.pattern(this.alphanumericRegexp)];
    const postcodeValidationArray = [Validators.required, Validators.pattern(this.alphanumericRegexp)];
    if (this.countryCode === 'gb') {
      postcodeValidationArray.push(Validators.minLength(6), Validators.maxLength(8));
    }
    this.addressForm = new UntypedFormGroup({
      'Address Line 1': new UntypedFormControl(this.address?.address1, validationArray),
      'Address Line 2': new UntypedFormControl(this.address?.address2, this.requirements?.town ? validationArray : null),
      Town: new UntypedFormControl(this.address?.town, this.requirements?.town ? validationArray : null),
      'County/State': new UntypedFormControl(this.address?.county, this.requirements?.county ? validationArray : null),
      Postcode: new UntypedFormControl(this.address?.postcode, this.requirements?.postcode ? postcodeValidationArray : null),
    });
    this.addressSave.emit({address: null, form: this.addressForm, firstLoad: true});
  }

  addressSelected(address: PlaceResult) {
    this.showAddressForm = true;
    address.address_components.forEach(component => {
      component.types.forEach(type => {
        if (type.indexOf('number') !== -1) {
          this.addressForm.get(ADDRESS_1).setValue(component.long_name);
        }
        if (type.indexOf('route') !== -1) {
          this.addressForm.get(ADDRESS_2).setValue(component.long_name);
        }
        if (type.indexOf('town') !== -1 || type.indexOf('locality') !== -1) {
          this.addressForm.get(TOWN).setValue(component.long_name);
        }
        if (this.countryCode === 'au') {
          if (type.indexOf('administrative_area_level_1') !== -1) {
            this.addressForm.get(COUNTY).setValue(component.long_name);
          }
        } else {
          if (type.indexOf('administrative_area_level_2') !== -1) {
            this.addressForm.get(COUNTY).setValue(component.long_name);
          }
        }
        if (type.indexOf('postal_code') !== -1 || type.indexOf('zip') !== -1) {
          this.addressForm.get(POSTCODE).setValue(component.long_name);
        }
      });
    });
  }

  saveAddress() {
    const updateAddress: AddressObject = {
      address1: this.addressForm.get(ADDRESS_1).value,
      address2: this.addressForm.get(ADDRESS_2).value,
      town: this.addressForm.get(TOWN).value,
      county: this.addressForm.get(COUNTY).value,
      postcode: this.addressForm.get(POSTCODE).value,
    };
    this.addressSave.emit({address: updateAddress, form: this.addressForm});
  }

  cancelUpdate() {
    this.addressCancel.emit(false);
  }
}
