import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ClientDetailsComponent} from './client-details/client-details.component';
import {MatLegacyCardModule as MatCardModule} from '@angular/material/legacy-card';
import {ExtendedModule, FlexModule} from '@angular/flex-layout';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatDividerModule} from '@angular/material/divider';
import {MatLegacyRadioModule as MatRadioModule} from '@angular/material/legacy-radio';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatLegacyListModule as MatListModule} from '@angular/material/legacy-list';
import {MatIconModule} from '@angular/material/icon';
import {ContactEntriesComponent} from './contact-entries/contact-entries.component';
import {MatLegacyFormFieldModule as MatFormFieldModule} from '@angular/material/legacy-form-field';
import {MatLegacyInputModule as MatInputModule} from '@angular/material/legacy-input';
import {MatLegacyOptionModule as MatOptionModule} from '@angular/material/legacy-core';
import {MatLegacySelectModule as MatSelectModule} from '@angular/material/legacy-select';
import {MatLegacyProgressSpinnerModule as MatProgressSpinnerModule} from '@angular/material/legacy-progress-spinner';
import {NameEntryComponent} from '../components/name-entry/name-entry.component';
import {MatLegacyCheckboxModule as MatCheckboxModule} from '@angular/material/legacy-checkbox';
import {MatLegacyAutocompleteModule as MatAutocompleteModule} from '@angular/material/legacy-autocomplete';
import {CommonComponentsModule} from '../components/common-components/common-components.module';
import {AddContactComponent} from './contact-entries/add-contact/add-contact.component';
import {EditContactComponent} from './contact-entries/edit-contact/edit-contact.component';


@NgModule({
  declarations: [ClientDetailsComponent, ContactEntriesComponent, NameEntryComponent, AddContactComponent,
                 EditContactComponent],
  exports: [
    NameEntryComponent,
    ClientDetailsComponent
  ],
  imports: [
    CommonModule,
    MatCardModule,
    FlexModule,
    MatButtonModule,
    MatDividerModule,
    MatRadioModule,
    FormsModule,
    MatListModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatOptionModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    ExtendedModule,
    MatCheckboxModule,
    MatRadioModule,
    MatAutocompleteModule,
    CommonComponentsModule
  ]
})
export class ClientDetailsModule {

}
