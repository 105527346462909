import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-pet-bio',
  templateUrl: './pet-bio.component.html',
  styleUrls: ['./pet-bio.component.scss']
})
export class PetBioComponent implements OnInit {

  @Input() bio: string;
  @Output() saveBio = new EventEmitter<string>();
  @Input() loading: boolean;
  editBio: boolean;
  bioForm: UntypedFormGroup;

  constructor() {
  }

  ngOnInit(): void {
    this.bioForm = new UntypedFormGroup({bio: new UntypedFormControl(this.bio, [Validators.required, Validators.maxLength(250)])});
  }

  onEdit() {
    this.editBio = true;
    this.bioForm.get('bio').setValue(this.bio);
  }

  onSaveBio() {
    this.saveBio.emit(this.bioForm.get('bio').value);
    this.editBio = false;
  }
}
