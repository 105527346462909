<div class="row">
    <div class="left">
        <ng-container *ngIf="depositStatus === null">
            Loading...
        </ng-container>
        <ng-container *ngIf="depositStatus && depositStatus.depositOnFile === true">
            <div class="deposit-status">
                <mat-icon aria-hidden="false" aria-label="Tick" fontIcon="check" class="check"></mat-icon> {{ depositStatus.depositAmount | currency : 'GBP' : 'symbol-narrow' }} deposit on file
            </div>
        </ng-container>
        <ng-container *ngIf="depositStatus && depositStatus.depositOnFile === false">
            <div class="deposit-status no-deposit">
                <mat-icon aria-hidden="false" aria-label="Cross" fontIcon="close" class="cross"></mat-icon> No deposit on file
            </div>
        </ng-container>
    </div>
    <div class="right">
      <ng-container *ngIf="loading$ | async">
        <div style="padding:10px;">
          <mat-progress-spinner color="primary" diameter="20" mode="indeterminate"></mat-progress-spinner>
        </div>
      </ng-container>
      <ng-container *ngIf="!(loading$ | async)">
        <ng-container *ngIf="depositStatus && depositStatus.depositOnFile === true">
            <button (click)="refundDeposit()" color="primary" mat-raised-button>
                Refund
            </button>
        </ng-container>
        <ng-container *ngIf="message$ | async">
            <div class="mat-body-1 accent-text-color error">{{ message$ | async }}</div>
        </ng-container>
      </ng-container>
    </div>
</div>
