import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {formatISO} from 'date-fns';
import {BookingService} from '../../../services/booking.service';
import {BehaviorSubject, Subscription} from 'rxjs';
import {Router} from '@angular/router';
import {environment} from '../../../../environments/environment';
import {ConfirmBookingDialogComponent} from '../../../booker/confirm-booking-dialog/confirm-booking-dialog.component';
import {MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig} from '@angular/material/legacy-dialog';
import {FirstAvailableSlot, PurePatient, SelectedPatient} from '@appyvet/vetbooker-definitions/dist/appointments';
import {AppointmentType} from '@appyvet/vetbooker-definitions/dist/appointment_type';
import {BookingItem} from '@appyvet/vetbooker-definitions/dist/bookings';
import {Resource} from '@appyvet/vetbooker-definitions/dist/resource';


@Component({
  selector: 'app-quick-book-card',
  templateUrl: './quick-book-card.component.html',
  styleUrls: ['./quick-book-card.component.scss']
})
export class QuickBookCardComponent implements OnInit, OnDestroy {
  @Input() slot: FirstAvailableSlot;
  @Input() resource: Resource;
  @Input() clientNumber: string;
  @Input() patients: PurePatient[];
  @Input() apptType: AppointmentType;
  @Output() onSelected: EventEmitter<any> = new EventEmitter();
  loading$ = new BehaviorSubject<boolean>(false);
  error$ = new BehaviorSubject<string>(null);
  private dialogSubscription: Subscription;
  @Input() buttonText: string;
  today = new Date();

  constructor(private bookingService: BookingService, private router: Router, private dialog: MatDialog) {
  }

  ngOnInit(): void {
    if (this.patients?.length === 1) {
      this.slot.selectedPatient = this.patients[0];
    }
  }

  bookFirstAvailable(patient: PurePatient) {
    const selectedPatient: SelectedPatient = {
      patient,
      selectedAppointmentType: this.apptType,
    };
    console.log(this.slot);
    const booking: BookingItem = {
      notes: '',
      clientNumber: this.clientNumber,
      date: formatISO(this.slot.utcDateTime),
      item: {
        time: this.slot.time,
        clipboardId: this.slot.objectId,
        utcDateTime: formatISO(this.slot.utcDateTime),
        clinicDateTime: this.slot.clinicDateTime,
        additionalId: this.slot.additionalId
      },
      resourceId: this.resource.objectId,
      selectedPatients: [selectedPatient]
    };
    if (!environment.VETS_4_PETS) {
      this.loading$.next(true);
      booking.selectedPatients[0].notes = 'Quick book';
      booking.notes = 'Quick book online appointment';
      this.bookingService.bookAppt(booking).subscribe(result => {
        if (result) {
          this.loading$.next(false);
          this.router.navigateByUrl('/history');
        }
      }, e => {
        this.error$.next(e);
        this.loading$.next(false);
      });
    } else {
      const bookerDialogConfig = new MatDialogConfig();
      bookerDialogConfig.data = {
        booking,
        selectedResource: this.resource,
        // message: 'Please return to the form and ensure all sections are complete. You are currently missing the ' +
        // missingAreas
      };
      bookerDialogConfig.panelClass = 'no-padding-dialog';
      bookerDialogConfig.maxWidth = '90vw';
      const dialogRef = this.dialog.open(ConfirmBookingDialogComponent, bookerDialogConfig);
      this.dialogSubscription = dialogRef.afterClosed().subscribe(result => {
        this.loading$.next(false);
        if (result) {
          this.loading$.next(true);
          this.router.navigateByUrl('history');
        }
      });
    }
  }

  getPhoto() {
    return {background: 'url(' + this.slot.photo + ')'};
  }

  ngOnDestroy(): void {
    this.dialogSubscription?.unsubscribe();
  }
}
