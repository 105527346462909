import {Component, Inject} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'no-match-alert-dialog',
  templateUrl: './no-match-dialog.component.html',
  styleUrls: ['./no-match-dialog.component.scss']
})

export class NoMatchDialogComponent {
  clinicName: string;
  postcode: string;
  clinicCode: string;
  email: string;
  mobile: string;
  brandName = environment.VETS_4_PETS ? 'Vets4Pets' : environment.GROOM ? 'Pets Grooming' : 'the clinic';
  isGroomRoom = environment.GROOM;
  canRegister: boolean;

  constructor(
    public dialogRef: MatDialogRef<NoMatchDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data) {
    this.clinicName = data.clinicName;
    this.clinicCode = data.clinicCode;
    this.email = data.email;
    this.mobile = data.mobile;
    this.postcode = data.postcode;
    this.canRegister = data.canRegister;
  }
}

