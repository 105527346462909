import {Component, OnDestroy, OnInit} from '@angular/core';
import {SessionService} from '../services/session-service.service';
import {Subscription} from 'rxjs';
import {AuthService} from '../auth/auth.service';
import {BookingService} from '../services/booking.service';
import {environment} from '../../environments/environment';
import {AppointmentHistoryItem} from '@appyvet/vetbooker-definitions/dist/appointments';
import {MarketingContact, MarketingQuestion, MarketingResponse} from '@appyvet/vetbooker-definitions/dist/marketing';
import {GoogleAnalyticsService} from "ngx-google-analytics";

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})


export class LandingComponent implements OnInit, OnDestroy {
  disableQuickBook: boolean;
  isGroomRoom = environment.GROOM;
  private sessionSubscription: Subscription;
  showMarketingConsent: boolean;
  marketingPreferences: MarketingResponse[];
  marketingQuestions: MarketingQuestion[];
  marketingChannels: MarketingContact[];
  private marketingSub: any;
  upcomingAppointment: AppointmentHistoryItem;
  private appointmentsSub: Subscription;
  hideReminders: boolean;
  private clinicName: string;
  canBook: boolean;

  constructor(private sessionService: SessionService, private authService: AuthService,
              private bookingService: BookingService, private gaService: GoogleAnalyticsService) {
  }

  ngOnInit() {
    this.sessionSubscription = this.sessionService.clientPatientDetails$.subscribe(clientPatientDetails => {
      if (clientPatientDetails) {
        this.clinicName = clientPatientDetails.themeSettings?.customName;
        this.hideReminders = clientPatientDetails.patientViewSettings.hideReminders;
        this.disableQuickBook = clientPatientDetails.appointmentSettings.quickBookDisabled ||
          clientPatientDetails.clientData.patients?.length === 0 || false;
        this.canBook = clientPatientDetails.acceptingBookings;
      }
    });
    this.marketingSub = this.authService.isNewRegistration$.subscribe(showMarketing => {
      this.showMarketingConsent = showMarketing;
    });
    this.appointmentsSub = this.bookingService.upcomingAppointments$.subscribe(appointments => {
      if (appointments) {
        this.upcomingAppointment = appointments[0];
      } else {
        this.upcomingAppointment = null;
      }
    });
  }

  ngOnDestroy(): void {
    this.sessionSubscription?.unsubscribe();
    this.marketingSub?.unsubscribe();
    this.appointmentsSub?.unsubscribe();
  }

  viewAppointmentsClicked() {
    this.gaService.event(
      'View Appointments',
      'Landing',
      this.clinicName,
    );
  }

  viewAllPetsSelected() {
    this.gaService.event(
      'All Pets Selected',
      'Landing',
      this.clinicName,
    );
  }
}
