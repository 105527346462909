<div class="login-background" fxLayout="column" [ngClass.gt-xs]="'padding-10'"
     fxLayoutAlign="start center" fxFlexFill>
  <div *ngIf="!embedded" [style.background]="getBackgroundStyle()"
       class="main-logo" fxLayout fxLayoutAlign="center center"></div>
  <h2 class="clinic-title">{{clinicName}}</h2>
  <div style="background-color: white; min-width: 80vw">
    <h1 class="section-header" style="padding:10px;"
        [ngStyle.xs]="'font-size:26px'">New
      {{isGroomRoom ?
        'Customer' : 'Client'}} Registration</h1>
    <ng-container *ngIf="loading$ | async">
      <div fxLayout="column" fxLayoutAlign="center center"
           style="padding:10px;">
        <mat-progress-spinner color="primary" diameter="40"
                              mode="indeterminate"></mat-progress-spinner>
        <p class="mat-body-2" style="margin-top:10px">Registering on the
          server, this could take
          up to
          2 minutes. Please do not refresh this
          page</p>
      </div>
    </ng-container>
    <ng-container *ngIf="success$ | async;">
      <div style=" padding:10px">
        <p *ngIf="!isGroomRoom && !registerUrl" class="content-text">Your
          account has been successfully created
          at {{clinicName}}. You should shortly receive an email which contains
          a link to verify your email address.
          Once you have done that, you will be able to log in.</p>
        <p *ngIf="isGroomRoom" class="content-text">Your account has been
          successfully created for Pets Grooming. You
          should shortly receive an email which contains a link to verify your
          email address. Once you have
          done that, you will be able to log in.</p>
        <div *ngIf="!isGroomRoom && registerUrl" class="content-text">
          <p class="content-text">Your account has been successfully created.
            You have been sent an email link to verify your email address.
            Please follow this link in order to complete sign up</p>
          <!--          <a [href]="registerUrl" aria-label="website" color="primary"-->
          <!--             mat-stroked-button>-->
          <!--            Click here to finalise registration with VetBooker-->
          <!--          </a>-->
        </div>
        <ng-container *ngIf="returnUrl">
          <div fxLayout="row" fxLayoutAlign="center center">
            <a [href]="returnUrl" aria-label="website" mat-stroked-button>
              Back to website </a></div>
        </ng-container>
      </div>
    </ng-container>
    <mat-stepper  orientation="vertical" #stepper
                          [hidden]="(loading$ | async) || (success$ | async)"
                          [linear]="false" style="padding-right: 10px" [ngStyle.xs]="'margin-bottom: 30px'">
      <mat-step [stepControl]="nameFormGroup">
        <ng-template matStepLabel><p class="content-text no-margin">Fill out
          your name</p>
        </ng-template>
        <form [formGroup]="nameFormGroup">
          <app-name-entry (onSave)="clientNext($event)"
                          [firstNameRequired]="requirements?.firstName"
                          [hideCancel]="true" [isRegForm]="true"
                          [regex]="regex"></app-name-entry>
        </form>
      </mat-step>
      <mat-step [stepControl]="addressFormGroup">
        <form [formGroup]="addressFormGroup">
          <ng-template matStepLabel><p class="content-text no-margin">Fill out
            your address</p>
          </ng-template>
          <app-address-lookup (addressCancel)="stepper.previous()"
                              (addressSave)="onSaveAddress($event)"
                              [clinicCode]="clinicCode"
                              [showAddressForm]="manualAddressEntry"
                              [countryCode]="country.code"
                              [initialPostcode]="passedUser?.postcode"
                              [isNotClientDetailsPage]="true"
                              [requirements]="addressRequirements"></app-address-lookup>
        </form>
      </mat-step>
      <ng-container *ngIf="!passedUser">
        <mat-step [stepControl]="contactFormGroup">
          <form [formGroup]="contactFormGroup">
            <ng-template matStepLabel><p class="content-text no-margin">Fill
              out
              your contact details</p>
            </ng-template>
            <app-registration-contact-entries (cancel)="stepper.previous()"
                                              (save)="onContactNext($event)"
                                              [homeRegex]="homeRegex"
                                              [mobileRegex]="mobileRegex"
                                              [homeRequired]="requirements?.homePhone"
                                              [mobileRequired]="requirements?.mobile"
                                              [passedUser]="passedUser"
                                              [reminderConsentEnabled]="reminderConsentEnabled"
                                              [reminderConsentIntro]="reminderConsentIntro"></app-registration-contact-entries>
          </form>
        </mat-step>
      </ng-container>
      <mat-step *ngFor="let pet of pets; let i = index" [label]="'pet'+i"
                [stepControl]="pet.form">
        <ng-template matStepLabel><p class="content-text no-margin">Fill out
          details for pet {{(i + 1)}}</p>
        </ng-template>
        <form [formGroup]="pet.form">
          <app-add-patient (cancel)="stepper.previous()"
                           (formChanged)="onPatientFormChanged($event, i)"
                           (savePatient)="savePatient($event, i)"
                           [isNewPet]="true" [breeds]="breeds" [colors]="colors"
                           [editPatient]="pet.patient" [isRegistration]="true"
                           [regex]="regex"
                           [showColors]="requirements?.showColor"
                           [showInsured]="requirements?.showInsured"
                           [showLastVac]="requirements?.showLastVac"
                           [showMicrochip]="requirements?.showMicrochip"
                           [species]="species"></app-add-patient>
        </form>
      </mat-step>
      <mat-step [label]="'manage'">
        <ng-template matStepLabel><p class="content-text no-margin">Manage
          pets</p>
        </ng-template>
        <app-manage-registration-patients (petUpdate)="petAdded($event)"
                                          [stepper]="stepper"
                                          (selectPet)="selectPet($event)"
                                          [(pets)]="pets"></app-manage-registration-patients>
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <button mat-stroked-button matStepperPrevious>Back</button>
          <ng-container *ngIf="noAdditional; else nextBlock">
            <button mat-raised-button color="primary" (click)="onRegister()"
                    style="width:140px" [disabled]="!formCompleted()">Register
            </button>
          </ng-container>
          <ng-template #nextBlock>
            <button color="primary" mat-raised-button matStepperNext
                    style="width:140px">Next
            </button>
          </ng-template>
        </div>
      </mat-step>
      <mat-step *ngIf="marketingEnabled" [stepControl]="marketingFormGroup">
        <ng-template matStepLabel><p class="content-text no-margin">Marketing
          Consent</p>
        </ng-template>
        <div fxLayout="column" fxLayoutGap="20px">
          <form [formGroup]="marketingFormGroup">
            <div fxLayout="column" fxLayoutGap="20px">
              <app-client-marketing *ngIf="marketingQuestionsEnabled &&
              marketingQuestions?.length>0" embedded="true"
                                    [(preferences)]="marketingQuestions"></app-client-marketing>
              <app-client-marketing-channels *ngIf="!marketingContactsDisabled"
                                             embedded="true"
                                             [(channels)]="marketingChannels"
                                             [communicationChannelIntro]="communicationChannelIntro"></app-client-marketing-channels>
            </div>
          </form>
          <div fxLayout="row">
            <button mat-stroked-button matStepperPrevious type="button">Back
            </button>
            <button color="primary" mat-stroked-button matStepperNext
                    type="button">Next
            </button>
          </div>
        </div>
      </mat-step>
      <ng-container *ngIf="!noAdditional">
        <mat-step [stepControl]="additionalFormGroup" [label]="'additional'">
          <ng-template matStepLabel><p class="content-text no-margin">
            Additional
            Information</p>
          </ng-template>
          <form [formGroup]="addressFormGroup">
            <app-additional-registration-info
              (onSave)="onSaveAdditional($event)"
              (onCancel)="handelFinalStepBack()"
              [additionalTermsUrl]="additionalTermsUrl" [clinics]="clinics"
              [canSave]="formCompleted()"
              [passedPassword]="passedUser?.password"
              [requireHowFound]="requirements?.requireHowFound"
              [showHowFound]="requirements?.showHowFound"
              [showNotes]="requirements?.showNotes"></app-additional-registration-info>
          </form>
        </mat-step>
      </ng-container>
      <!--        <mat-step>-->
      <!--          <ng-template matStepLabel><p class="content-text no-margin">Summary</p>-->
      <!--          </ng-template>-->

      <!--          <app-registration-summary-->
      <!--            [registrationSummaryCategories]="getRegistrationFormSummary()"></app-registration-summary>-->
      <!--          <div fxLayout="row">-->
      <!--            <button mat-stroked-button matStepperPrevious type="button">Back</button>-->
      <!--            <button (click)="onRegister()"-->
      <!--                    [disabled]="!formCompleted()"-->
      <!--                    color="primary" mat-stroked-button type="button">Register-->
      <!--            </button>-->
      <!--          </div>-->
      <!--        </mat-step>-->
    </mat-stepper>
  </div>
</div>
