<div fxLayout="column" style="min-width: 200px; max-width:600px; background-color: #FFFAF5">
  <div fxLayout="row" fxLayoutAlign="end center">
    <button mat-icon-button color="primary" mat-dialog-close="true">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div fxLayout="column" fxLayoutAlign="center center" mat-dialog-content class="splash-container">
    <p class="mat-body-1 splash-title" >We're one big <span style="color: #00AA28; display:inline-block">family.</span></p>
    <p  class="mat-body-1 splash-body" >We've always been part of the same family. Now we’ve refreshed our branding to reflect it. <span style="color: #00AA28">We’re all for Pets.</span></p>
  </div>
  <video #splashVideo muted autoplay="true" playsinline loop >
    <source src="/assets/groomroom/logosanimation.webm" type="video/webm">
  </video>
</div>
