import {Component, OnDestroy, OnInit} from '@angular/core';
import {SettingsService} from '../settings.service';
import {BehaviorSubject, Subscription} from 'rxjs';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {ConfirmationDialogComponent} from '../../components/shared/confirmation-dialog/confirmation-dialog.component';
import {environment} from '../../../environments/environment';
import {ClosedDialogComponent} from '../../components/shared/closed-dialog/closed-dialog.component';
import {AuthService} from '../../auth/auth.service';
import {MarketingContact, MarketingQuestion, MarketingResponse} from '@appyvet/vetbooker-definitions/dist/marketing';
import {UpdatePasswordParams} from '@appyvet/vetbooker-definitions/dist/register_user';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit, OnDestroy {
  emailLoading$ = new BehaviorSubject<boolean>(false);
  passwordLoading$ = new BehaviorSubject<boolean>(false);
  preferencesLoading$ = new BehaviorSubject<boolean>(false);
  contactsLoading$ = new BehaviorSubject<boolean>(false);
  marketingChannels: MarketingContact[];
  marketingPreferences: MarketingResponse[];
  marketingQuestions: MarketingQuestion[];
  isGroomRoom: boolean;
  isMarketingDisabled: boolean;
  isMarketingChannelsDisabled: boolean;
  marketingPreferencesIntro: string;
  marketingChannelsIntro: string;
  contactsSub: Subscription;
  questionsSub: Subscription;
  clinicName: string;
  email: string;
  isVets4Pets = environment.VETS_4_PETS;
  clinicDeactivated: boolean;

  constructor(private settingsService: SettingsService, private snackBar: MatSnackBar, private matDialog: MatDialog,
              private authService: AuthService) {
  }

  ngOnInit(): void {
    this.clinicDeactivated = this.settingsService.clinicDeactivated;
    this.contactsSub = this.settingsService.marketingContacts.subscribe(marketingContacts => {
      this.marketingChannels = marketingContacts;
    });
    this.questionsSub = this.settingsService.marketingPreferences.subscribe(marketingPreferences => {
      this.marketingPreferences = marketingPreferences;
    });
    this.questionsSub = this.settingsService.marketingQuestions.subscribe(marketingQuestions => {
      this.marketingQuestions = marketingQuestions;
    });
    this.isGroomRoom = this.settingsService.isGroomRoom;
    this.isMarketingDisabled = this.settingsService.isMarketingDisabled;
    this.isMarketingChannelsDisabled = this.settingsService.isMarketingChannelsDisabled;
    this.marketingPreferencesIntro = this.settingsService.marketingPreferencesIntro;
    this.marketingChannelsIntro = this.settingsService.marketingChannelsIntro;
    this.clinicName = this.settingsService.clinicName;
    this.email = this.settingsService.email;
    if (this.clinicDeactivated) {
      this.matDialog.open(ClosedDialogComponent);
    }
  }

  ngOnDestroy(): void {
    this.contactsSub?.unsubscribe();
    this.questionsSub?.unsubscribe();
  }

  updateMarketingChannels(updatedChannels: MarketingContact[]) {
    this.contactsLoading$.next(true);
    this.settingsService.updateClientMarketingChannels(updatedChannels).subscribe(result => {
      if (result) {
        this.contactsLoading$.next(false);
        this.snackBar.open('Marketing channels updated successfully', null, {duration: 5000});
      }
    }, error => {
      this.contactsLoading$.next(false);
      this.snackBar.open(error, null, {duration: 3000});
    });
  }

  updateMarketingPreferences(updatedPreferences: MarketingResponse[]) {
    this.preferencesLoading$.next(true);
    this.settingsService.updateClientMarketingInfo(updatedPreferences).subscribe(result => {
      if (result) {
        this.preferencesLoading$.next(false);
        this.snackBar.open('Marketing preferences updated successfully', null, {duration: 5000});
      }
    }, error => {
      this.preferencesLoading$.next(false);
      this.snackBar.open(error, null, {duration: 3000});
    });
  }

  startEmailUpdate(newEmail: string) {
    this.emailLoading$.next(true);
    this.settingsService.updateEmail(newEmail);
    this.settingsService.updateEmail$.subscribe(updateResult => {
      if (updateResult) {
        this.emailLoading$.next(false);
        this.snackBar.open('Email updated successfully', null, {duration: 5000});
        this.authService.logout();
      }
    }, error => {
      this.emailLoading$.next(false);
      this.snackBar.open(error, null, {duration: 3000});
    });
  }

  saveEmail(newEmail: string) {
    if (environment.VETS_4_PETS) {
      this.startEmailUpdate(newEmail);
    } else {
      const dialogRef = this.matDialog.open(ConfirmationDialogComponent, {
        data: {
          message: 'Are you sure you want to change your email address to ' + newEmail +
            '? Note, until this new address is verified, you can still log in using your old address in case of a mistake.',
          title: 'Change account email address',
          confirmTest: 'Yes, Change my email address'
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.startEmailUpdate(newEmail);
        }
      });
    }
  }

  savePassword(password: UpdatePasswordParams) {
    this.passwordLoading$.next(true);
    this.settingsService.updatePassword(password.oldPassword, password.newPassword);
    this.settingsService.updatePassword$.subscribe(result => {
      if (result) {
        this.passwordLoading$.next(false);
        this.snackBar.open('Password updated successfully', null, {duration: 5000});
      }
    }, error => {
      this.passwordLoading$.next(false);
      this.snackBar.open(error, null, {duration: 5000});
    });
  }
}
