import {Injectable} from '@angular/core';
import * as Parse from 'parse';
import { AuthService } from '../auth/auth.service';
import { Payment } from '../models/payment.model';
import { PaymentDto } from '../interfaces/dto/payment.dto';
import { paymentFactory } from '../factories/payment.factory';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  constructor(private authService: AuthService) {}

  async getDepositStatus(): Promise<{ depositOnFile: boolean, depositId: string | null, depositAmount: number } | null> {
    const response = await Parse.Cloud.run('getDepositStatus', {}, {sessionToken: this.authService.getSessionToken()});
    return response;
  }

  async refundDeposit(): Promise<boolean> {
    try {
      return await Parse.Cloud.run('refundDeposit', {}, {sessionToken: this.authService.getSessionToken()});
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  async getUpcomingAppointmentStatus(): Promise<boolean> {
    return await Parse.Cloud.run('getUpcomingAppointmentStatus', {}, {sessionToken: this.authService.getSessionToken()});
  }

  async getPaymentHistory(): Promise<Payment[]> {
    try {
      const response: PaymentDto[] = await Parse.Cloud.run('getPaymentHistory', {}, {sessionToken: this.authService.getSessionToken()});
      return response.map(payment => paymentFactory(payment));
    } catch (e) {
      console.log(e);
      return [];
    }
  }
}
