import {Component, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {environment} from '../../../environments/environment';
import {PetDeactivationReason} from '@appyvet/vetbooker-definitions/dist/client_patient_details';
import {VbExtendedPatient} from '@appyvet/vetbooker-definitions/dist/patient';

@Component({
  selector: 'app-remove-pet-dialog',
  templateUrl: './remove-pet-dialog.component.html',
  styleUrls: ['./remove-pet-dialog.component.scss']
})
export class RemovePetDialogComponent implements OnInit {
  patient: VbExtendedPatient;
  reasons: PetDeactivationReason[];
  isGroomRoom = environment.GROOM;

  constructor(public dialogRef: MatDialogRef<RemovePetDialogComponent>,
              @Inject(MAT_DIALOG_DATA) data) {
    this.patient = data.patient;
    this.reasons = data.reasons;
  }

  ngOnInit(): void {
  }

}
