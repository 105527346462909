<div class="margin-top-10 no-padding">
  <h1 class="section-header  accent-text-color">
    Upcoming appointments </h1>
  <ng-container *ngIf="bookingService.sendingRequest$ | async; else upcomingBock">
    <div fxLayout="row">
      <div class="empty-timeline-gap"></div>
      <mat-card fxLayout="row" fxLayoutAlign="center center"
                style="min-height: 60px;width:100%">
          <p style="margin: 0 20px 0 0">Updating</p>
        <mat-progress-spinner mode="indeterminate" color="primary"
                              diameter="30"></mat-progress-spinner>
      </mat-card>
    </div>
  </ng-container>
    <ng-template #upcomingBock>
      <ng-container
        *ngIf="(bookingService.upcomingAppointments$ | async)?.length>0; else noUpcomingBlock">
        <ng-container *ngFor="let appointmentHistoryItem of
      bookingService.upcomingAppointments$ | async; let $first">
          <app-appointment-card
            [appointmentHistoryItem]="appointmentHistoryItem"></app-appointment-card>
        </ng-container>
      </ng-container>
      <ng-template #noUpcomingBlock>
        <div fxLayout="row">
          <div class="empty-timeline-gap"></div>
          <mat-card fxLayout="row" fxLayoutAlign="center center"
                    style="min-height: 60px;width:100%">
            <div [routerLink]="'/booking'" fxLayout="row" fxLayout.xs="column"
                 fxLayoutAlign="center center">
              <p class="no-margin">No <span *ngIf="isRequestClinic">confirmed</span>
                upcoming
                appointments.&nbsp;</p>
              <ng-container *ngIf="isRequestClinic; else bookBlock">
                <p class="no-margin" fxHide.gt-xs>Touch to make a request</p>
                <p class="no-margin" fxHide.xs>Click to make a request</p>
              </ng-container>
              <ng-template #bookBlock>
                <p class="no-margin" fxHide.gt-xs>Touch to book</p>
                <p class="no-margin" fxHide.xs fxLayout>Click to book</p>
              </ng-template>
            </div>
          </mat-card>
        </div>
      </ng-template>
    </ng-template>
</div>
<ng-container *ngIf="isRequestClinic">
  <div class="margin-top-10 no-padding">
    <h1 class="section-header  accent-text-color">
      Unconfirmed appointments </h1>
    <ng-container *ngIf="(bookingService.unconfirmedAppointments$ | async)?.length>0; else
      noUpcomingBlock">
      <ng-container *ngFor="let appointmentHistoryItem of
      bookingService.unconfirmedAppointments$ | async; let $first">
        <app-appointment-card [appointmentHistoryItem]="appointmentHistoryItem"
                              style="margin-bottom:
        10px; padding:0; width:100%"></app-appointment-card>
      </ng-container>
    </ng-container>
    <ng-template #noUpcomingBlock>
      <div fxLayout="row">
        <div class="empty-timeline-gap"></div>
        <mat-card fxLayout="row" fxLayoutAlign="center center"
                  style="min-height: 60px;width:100%">
          <div [routerLink]="'/booking'" fxLayout="row" fxLayout.xs="column"
               fxLayoutAlign="center center">
            <p class="no-margin">No pending appointments
              .&nbsp;</p>
            <p class="no-margin" fxHide.gt-xs>Touch to make a request</p>
            <p class="no-margin" fxHide.xs>Click to make a request</p>
          </div>
        </mat-card>
      </div>
    </ng-template>
  </div>
</ng-container>
<div class="margin-top-10 no-padding">
  <h1 class="section-header accent-text-color">
    Previous appointments </h1>
  <div>
    <ng-container
      *ngIf="(bookingService.previousAppointments$ | async)?.length>0; else noPreviousBlock">
      <div
        *ngFor="let yearlyHistoryItem of bookingService.previousAppointments$ | async;"
        fxFill fxLayout="column">
        <div
          *ngFor="let monthlyHistoryItem of yearlyHistoryItem.months;first as isFirstMonthInYear"
          fxFill fxLayout="column">
          <div
            *ngFor="let appointmentHistoryItem of monthlyHistoryItem.appointments; first as isFirstApptInMonth"
            fxFill fxLayout="row">
            <div style="min-width:60px;max-width: 60px;">
              <h2 *ngIf="isFirstMonthInYear && isFirstApptInMonth"
                  class="date-section accent-text-color">{{yearlyHistoryItem.year}}</h2>
              <h2 *ngIf="isFirstApptInMonth"
                  class="month-section accent-text-color">{{monthlyHistoryItem.month}}</h2>
            </div>
            <div class="timeline">
              <div class="container"></div>
            </div>
            <mat-card style="margin-bottom: 10px; padding:0; width:100%">
              <mat-card-content>
                <div fxLayout="row" fxLayout.xs="column"
                     fxLayoutAlign.xs="center center">
                  <div class="card-header-text" fxLayout="column"
                       fxLayout.xs="row" fxLayoutAlign="space-between start"
                       fxLayoutAlign.xs="space-between center">
                    <span
                      class="date-section">{{appointmentHistoryItem.date | dfnsFormat:'EEE'}} {{appointmentHistoryItem.date |dfnsFormat:'do'}}</span>
                    <span
                      class="time-section"> {{appointmentHistoryItem.date | dfnsFormat: 'HH:mm'}}</span>
                  </div>
                  <div
                    [fxLayout]="appointmentHistoryItem.appointmentItem.appointments ? 'column' : 'row'"
                    fxLayout.xs="column">
                    <div
                      *ngIf="appointmentHistoryItem.appointmentItem.appointments"
                      fxLayout="row" fxLayoutAlign="start center">
                      <ng-container
                        *ngFor="let appt of appointmentHistoryItem.appointmentItem.appointments">
                        <div *ngIf="appt.patient && appt.patient.photo"
                             [ngStyle]="{'background' : appt.patient.photo?.url() | backImg}"
                             class="pet-photo  background-image"
                             fxLayout="column"
                             style="margin:10px; align-self: center;"></div>
                      </ng-container>
                    </div>
                    <ng-container
                      *ngIf="appointmentHistoryItem.appointmentItem.patient as patient">
                      <div *ngIf="patient.photo"
                           [ngStyle]="{'background' : patient.photo?.url() | backImg}"
                           class="pet-photo  background-image" fxLayout="column"
                           style="margin:10px; align-self: center;"></div>
                      <div *ngIf="!patient.photo"
                           class="pet-photo  background-image" fxLayout="row"
                           fxLayoutAlign="center center"
                           style="margin:10px; align-self: center;">
                        <mat-icon style="margin:0">
                          pets
                        </mat-icon>
                      </div>
                    </ng-container>
                    <div fxLayout="column"
                         style="padding:10px; align-self: center;">
                      <div fxLayout="row" fxLayoutAlign="start center">
                        <p class="card-item-title">Where</p>
                        <p>{{appointmentHistoryItem.resourceName}}</p>
                      </div>
                      <div fxLayout="row" fxLayoutAlign="start center">
                        <p class="card-item-title">Who</p>
                        <p>{{appointmentHistoryItem.patientDetails}}</p>
                      </div>
                      <div *ngIf="appointmentHistoryItem.clipboardName"
                           fxLayout="row" fxLayoutAlign="start center">
                        <p class="card-item-title">With</p>
                        <p>{{appointmentHistoryItem.clipboardName}}</p>
                      </div>
                      <div
                        *ngIf="!isGroomRoom && appointmentHistoryItem.appointmentItem.notes && appointmentHistoryItem.appointmentItem.notes.length>0"
                        fxLayout="row" fxLayoutAlign="start center">
                        <p class="card-item-title">Notes</p>
                        <p> {{appointmentHistoryItem.appointmentItem.notes}}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-template #noPreviousBlock>
      <div fxLayout="row">
        <div class="empty-timeline-gap"></div>
        <mat-card fxLayout="row" fxLayoutAlign="center center"
                  style="min-height: 60px; width:100%">
          <p>No previously booked appointments.&nbsp;</p></mat-card>
      </div>
    </ng-template>
  </div>
</div>
