import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {ClientTransferRoutingModule} from './client-transfer-routing.module';
import {ClientTransferComponent} from './client-transfer/client-transfer.component';
import {ClinicSelectorModule} from '../clinic-selector/clinic-selector.module';
import {FlexModule} from '@angular/flex-layout';
import {CommonComponentsModule} from '../components/common-components/common-components.module';
import {TransferSummaryComponent} from './transfer-summary/transfer-summary.component';
import {MatLegacyProgressSpinnerModule as MatProgressSpinnerModule} from '@angular/material/legacy-progress-spinner';
import {TransferContactsComponent} from './transfer-contacts/transfer-contacts.component';
import {TransferPetsComponent} from './transfer-pets/transfer-pets.component';
import {MatLegacyCheckboxModule as MatCheckboxModule} from '@angular/material/legacy-checkbox';
import {FormsModule} from '@angular/forms';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';


@NgModule({
  declarations: [ClientTransferComponent, TransferSummaryComponent, TransferContactsComponent, TransferPetsComponent],
  exports: [
    ClientTransferComponent
  ],
  imports: [
    CommonModule,
    ClientTransferRoutingModule,
    ClinicSelectorModule,
    FlexModule,
    CommonComponentsModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    FormsModule,
    MatButtonModule
  ]
})
export class ClientTransferModule {
}
