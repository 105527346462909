<div fxLayout="row" style="overflow-y: hidden; overflow-x: auto">
  <ng-container *ngFor="let patient of patients">
    <div fxLayout="column" fxLayoutAlign="end center" style="margin-bottom:
    -30px" [routerLink]="'/pets/'+patient.patientNumber"
         (click)="petSelected()">
      <ng-container *ngIf="patient.extraInfo?.photoBase64 as photoBase64">
        <div [style.background-image]="photoBase64 |
            safeResourceUrl" class="pet-container background-image"></div>
      </ng-container>
      <ng-container *ngIf="patient.photo as photo">
        <div [ngStyle]="{'background' : patient.photo?.url() | backImg}"
             class="pet-container background-image"></div>
      </ng-container>
      <ng-container *ngIf="!patient.photo?.url() &&
      !patient.extraInfo?.photoBase64">
        <div class="pet-container"></div>
      </ng-container>
      <div fxLayout="row" class="pet-overlay" [ngClass]="isGroomRoom ? 'accent-background' : 'primary-background'"
           fxLayoutAlign="space-between center" style="opacity: 75%;">
        <p class="patient-text">{{patient.name}}</p>
        <mat-icon style="color: white">chevron_right</mat-icon>
      </div>
    </div>
  </ng-container>
</div>
