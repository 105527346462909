import {NgModule} from '@angular/core';
import { FormatCurrencyPipe } from './format-currency.pipe';

@NgModule({
    declarations: [
        FormatCurrencyPipe
    ],
    imports: [
    ],
    exports: [
        FormatCurrencyPipe
    ]
})
export class PipesModule {
}
