import {Pipe, PipeTransform} from '@angular/core';
import { Currency } from '../enums/currency.enum';
import { getCurrencySymbol } from '../helpers/get-currency-symbol.helper';

@Pipe({
  name: 'formatCurrency'
})
export class FormatCurrencyPipe implements PipeTransform {

  constructor() {
  }

  transform(amount: number, currency: Currency): string {
    let prefix = '';

    if (amount < 0) {
      prefix = '-';
      amount = 0 - amount; // make positive for display purposes
    }

    return prefix + `${getCurrencySymbol(currency)}${(amount).toFixed(2)}`;
  }
}
