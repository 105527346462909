import {Component, OnInit} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {SignupService} from '../signup/signup.service';
import {VetBookerError} from '../../interfaces/vberror';
import {ParseErrorHandler} from '../../parse-error-handler';
import {GoogleAnalyticsService} from "ngx-google-analytics";

@Component({
  selector: 'app-email-link-verification',
  templateUrl: './email-link-verification.component.html',
  styleUrls: ['./email-link-verification.component.scss']
})
export class EmailLinkVerificationComponent implements OnInit {
  loading$ = new BehaviorSubject<boolean>(true);
  error$ = this.signupService.error$;

  constructor(private activatedRoute: ActivatedRoute, private signupService: SignupService,
              private gaService: GoogleAnalyticsService) {
  }

  ngOnInit(): void {
    this.loading$.next(true);
    this.activatedRoute.queryParams.subscribe(params => {
      if (params.clientNumber) {
      } else if (params.verificationCode) {
        this.signupService.setEmail(params.email);
        this.signupService.verifyEmailWithCode(params.verificationCode);
        this.gaService.event(
          'Register From Email Link',
          'New User Registration',
          params.clinicName || 'VetBooker',
        );
      }
    });
  }

}
